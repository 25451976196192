import rafSchedule from 'raf-schd';
import React, { CSSProperties, useLayoutEffect, useState } from 'react';

const ProgressBar: React.FC = () => {
  const [scale, setScale] = useState(0);

  useLayoutEffect(() => {
    const schedule = rafSchedule(() => {
      if (window.innerWidth >= 1024) {
        const { clientHeight, scrollHeight } = document.documentElement;
        const scrollProportion =
          window.scrollY / (scrollHeight - clientHeight) || 0;
        setScale(Math.round(scrollProportion * 1000) / 1000);
      }
    });

    schedule();

    window.addEventListener('scroll', schedule);

    return () => {
      window.removeEventListener('scroll', schedule);
    };
  }, []);

  return (
    <div className='bg-lightBlue lg:block fixed bottom-0 left-0 hidden right-0 z-30'>
      <div
        className='bg-blue h-4 origin-left transform w-full'
        style={{ '--tw-scale-x': scale } as CSSProperties}
      />
    </div>
  );
};

export default ProgressBar;
