import * as Yup from 'yup';
import { TextField } from '../../../types/FlexibleForm';
import handleConditionalLogicValidationSchema from './handleConditionalLogicValidationSchema';

function createSchema(field: TextField) {
  let schema = Yup.string();

  if (field.required) {
    schema = schema.required(
      field.validation_message || 'Please enter a value'
    );
  }

  if (field.max_length) {
    schema = schema.max(
      field.max_length,
      `Value cannot exceed ${field.max_length} characters`
    );
  }

  return schema;
}

export default function createValidationSchemaTextField(
  field: TextField
): Yup.StringSchema<any> {
  if (!field.conditional_logic) {
    return createSchema(field);
  }

  // Otherwise, create schema only when field is visible.
  const { rules } = field.conditional_logic;
  const ruleFieldNames = rules.map((rule) => rule.fieldName);
  return Yup.string().when(ruleFieldNames, {
    is: (...values: any[]) => {
      // If a field value is an empty string, we're returned 'undefined'
      // instead of the expected empty string. Normalise our values so logical
      // comparisons work correctly.
      const normalizedValues = values.map((value) => value || '');

      return handleConditionalLogicValidationSchema(
        field.conditional_logic,
        normalizedValues
      );
    },
    then: createSchema(field),
  });
}
