import React from 'react';
import Modal from 'react-responsive-modal';
import Button from '../../button';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onAccept: () => void;
}
const CorrespondenceUpdateConfirmModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  onAccept,
}) => {
  return (
    <Modal
      classNames={{ modal: 'modal' }}
      center={true}
      open={isOpen}
      onClose={() => {}}
      showCloseIcon={false}
    >
      <div className='flex flex-col'>
        <h1 className='mb-4 px-4'>Warning!</h1>
        <div className='h2 px-4'>
          Updating the correspondence address will cause an update for everyone
          in your household.
        </div>
        <div className='flex justify-center'>
          <Button
            onClick={() => {
              setIsOpen(false);
              onAccept();
            }}
            className='btn-primary mb-0 mx-2'
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            className='btn-secondary mb-0 mx-2'
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CorrespondenceUpdateConfirmModal;
