import React, { Fragment } from 'react';
import settings from '../../config/settings';

interface Props {}
const DemoBanner: React.FC<Props> = ({}) => {
  if (settings.configuration === 'demo') {
    return (
      <div className='sticky top-0 z-50 bg-red w-full px-10 py-3'>
        <p className='text-center uppercase font-semibold text-xl text-white'>
          {settings.demoText}
        </p>
      </div>
    );
  } else {
    return <Fragment />;
  }
};

export default DemoBanner;
