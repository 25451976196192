import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router';
import { FlexibleCard as Props } from '../../../types/FlexibleComponents';
import FlexibleCardContent from './FlexibleCardContent';
import ViewVideoModal from '../../modals/ViewVideoModal';
import FlexibleIcon from '../FlexibleIcon/FlexibleIcon';
import classNames from 'classnames';
import Tooltip from '../../tooltip/Tooltip';

const FlexibleCard: React.FC<Props> = ({
  color,
  title,
  icon,
  link_type,
  file,
  internal_link,
  external_link,
  content,
  embedded_content,
  tooltip_title,
  tooltip_body,
}) => {
  const { push } = useHistory();
  const [isEmbeddedContentOpen, setIsEmbeddedContentOpen] = useState(false);
  const getBackgroundColor = () => {
    switch (color) {
      case 'white':
        return 'bg-white';
      case 'blue':
        return 'bg-blue';
      case 'green':
        return 'bg-green';
      case 'pink':
        return 'bg-pink';
    }
  };
  const CardJSX = (
    <div className={`rounded-lg p-4 ${getBackgroundColor()}`}>
      {icon === 'none' ? (
        <Fragment>
          <h2 className={`h2 ${color === 'blue' && 'text-white'} mb-4`}>
            {title}
            {tooltip_title && (
              <Tooltip title={tooltip_title} body={tooltip_body} />
            )}
          </h2>
          <FlexibleCardContent
            content={content}
            color={color === 'blue' ? 'white' : undefined}
          />
        </Fragment>
      ) : (
        <div>
          <div className='flex flex-row'>
            <div
              className={classNames('flex-1', {
                'flex items-center': content.length === 0,
              })}
            >
              <h2 className={`h2 ${color === 'blue' && 'text-white'}`}>
                {title}
                {tooltip_title && (
                  <Tooltip title={tooltip_title} body={tooltip_body} />
                )}
              </h2>
            </div>
            <div className='flex-none ml-4 '>
              <FlexibleIcon icon={icon} />
            </div>
          </div>
          <FlexibleCardContent
            content={content}
            color={color === 'blue' ? 'white' : undefined}
          />
        </div>
      )}
    </div>
  );

  const renderFlexibleCard = () => {
    switch (link_type) {
      case 'none':
        return CardJSX;
      case 'download':
        return (
          file && (
            <a target='_blank' className='cursor-pointer w-full' href={file}>
              {CardJSX}
            </a>
          )
        );
      case 'internal_link':
        return (
          internal_link && (
            <div
              className='cursor-pointer w-full'
              onClick={() => push(internal_link.url)}
            >
              {CardJSX}
            </div>
          )
        );
      case 'external_link':
        return (
          external_link && (
            <a
              className='cursor-pointer w-full'
              target='_blank'
              href={external_link}
            >
              {CardJSX}
            </a>
          )
        );

      case 'embedded_content':
        return (
          embedded_content && (
            <Fragment>
              <ViewVideoModal
                isOpen={isEmbeddedContentOpen}
                setIsModalOpen={setIsEmbeddedContentOpen}
                src={embedded_content}
                title={title}
              />
              <div
                className='cursor-pointer w-full'
                onClick={() => setIsEmbeddedContentOpen(true)}
              >
                {CardJSX}
              </div>
            </Fragment>
          )
        );
    }
  };

  return <Fragment>{renderFlexibleCard()}</Fragment>;
};

export default FlexibleCard;
