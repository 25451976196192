import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';
import VerifyUserActions from '../types/VerifyUserActions';

function verifyUser(
  email: string,
  rentAccountNumber: number,
  firstname: string,
  surname: string
): Promise<AxiosResponse<VerifyUserActions>> {
  const data = {
    email,
    accountReference: rentAccountNumber,
    forename: firstname,
    surname,
  };
  return axios.post(endpoints.user.verify, data);
}

export default verifyUser;
