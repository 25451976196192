import React from 'react';
import { FlexibleCardContent as TFlexibleCardContent } from '../../../types/FlexibleComponents';
import FlexibleButton from '../FlexibleButton';
import FlexibleColumns from '../FlexibleColumns';
import IconWithText from '../IconWithText/IconWithText';
import TextBlock from '../TextBlock';

interface Props {
  content: TFlexibleCardContent;
  color?: 'white';
}

const FlexibleCardContent: React.FC<Props> = ({ content, color }) => {
  return (
    <div>
      {content.map((component, i) => {
        switch (component.acf_fc_layout) {
          case 'button':
            return <FlexibleButton {...component} color={color} />;
          case 'text_block':
            return <TextBlock {...component} color={color} />;
          case 'icon_with_text':
            return (
              <div key={i} className='mb-4'>
                <IconWithText {...component} color={color} />
              </div>
            );
          case 'flexible_columns':
            return (
              <div key={i} className='mb-4'>
                <FlexibleColumns {...component} color={color} />
              </div>
            );
        }
      })}
    </div>
  );
};

export default FlexibleCardContent;
