import classNames from 'classnames';
import React, { Fragment } from 'react';
import Pagination from '../../../components/pagination';
import TableHeader from '../../../components/table/components/TableHeader';
import TableRow from '../../../components/table/components/TableRow';
import Table from '../../../components/table/Table';
import usePagination from '../../../hooks/usePagination';
import { Table as TableType } from '../../../types/FlexibleComponents';

const RentStatementTable: React.FC<TableType> = ({ body, head }) => {
  const { setCurrentPage, paginate, currentPage, numberOfPages } =
    usePagination(body, 10);
  const showBottomRow = currentPage < numberOfPages;
  return (
    <div className='mb-4 w-full'>
      <Table tableClassName='lg:table-fixed'>
        <col className='w-2/12' />
        <col className='w-1/3' />
        <col className='w-1/3' />
        <col className='w-1/12' />
        <col className='w-1/12' />
        <col className='w-1/12' />

        {head && <TableHeader cells={head} />}
        {body && body.length > 0 ? (
          <Fragment>
            {paginate(body).map((row, rowIndex) => {
              return (
                <TableRow
                  key={rowIndex}
                  className={classNames({
                    'border-t-0': rowIndex === 0,
                    'border-b-0':
                      rowIndex === body.length - 1 && !showBottomRow,
                  })}
                >
                  {row.map((cell, i) => {
                    const getCellColor = (cell: string | null) => {
                      if (!cell || cell[0] !== '£' || i !== 4) {
                        return 0;
                      }
                      const value = cell.split('£')[1];

                      return isNaN(Number(value)) ? 0 : Number(value);
                    };

                    return (
                      <td
                        key={i}
                        className={classNames(
                          'px-4 py-3 text-blue text-sm min-w-28',
                          {
                            'text-red': getCellColor(cell) > 0,
                          }
                        )}
                      >
                        {cell}
                      </td>
                    );
                  })}
                </TableRow>
              );
            })}
            {showBottomRow && (
              <tr>
                <td className='py-3'></td>
              </tr>
            )}
          </Fragment>
        ) : (
          <TableRow>
            <td
              colSpan={100}
              className={classNames('px-4 py-3 text-blue text-sm min-w-28')}
            >
              {'No data available. Please widen your search term.'}
            </td>
          </TableRow>
        )}
      </Table>

      {numberOfPages > 1 && (
        <div className='mt-4'>
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
          />
        </div>
      )}
    </div>
  );
};

export default RentStatementTable;
