import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import api from '../../api';
import AccordionWithTabs from '../../components/flexible/AccordionWithTabs';
import EmbeddedContent from '../../components/flexible/EmbeddedContent';
import FlexibleButton from '../../components/flexible/FlexibleButton/FlexibleButton';
import FlexibleCard from '../../components/flexible/FlexibleCard/FlexibleCard';
import FlexibleCarousel from '../../components/flexible/FlexibleCarousel';
import CardColumns from '../../components/flexible/FlexibleColumns';
import FlexibleTable from '../../components/flexible/FlexibleTable';
import TextBlock from '../../components/flexible/TextBlock';
import {
  FlexibleContentMain,
  FlexibleContentSidebar,
} from '../../types/FlexibleComponents';
import LoadingPage from '../LoadingPage';

type PageData = {
  id: number;
  title: string;
  content_main: FlexibleContentMain | false;
  content_sidebar: FlexibleContentSidebar | false;
  date_published: string;
  date_modified: string;
};

interface Props {
  pageId: string;
}

const FlexiblePage: React.FC<Props> = ({ pageId }) => {
  const [data, setData] = useState<PageData>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isComponentMounted = true;
    setIsLoading(true);
    (async function () {
      try {
        const response = await api.getPageContent(pageId);

        if (isComponentMounted) {
          setData(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, [pageId]);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <Fragment>
      <h1 className='mb-4'>{data?.title}</h1>
      <div className='flex flex-col lg:flex-row'>
        {data?.content_main && (
          <div
            className={classNames({
              'lg:w-2/3 lg:mr-4':
                data.content_sidebar && data.content_sidebar.length > 0,
              'lg:w-full':
                !data.content_sidebar || data.content_sidebar.length === 0,
            })}
          >
            {data.content_main.map((component, i) => {
              switch (component.acf_fc_layout) {
                case 'accordion_with_tabs':
                  return <AccordionWithTabs key={i} {...component} />;
                case 'button':
                  return (
                    <div>
                      <FlexibleButton key={i} {...component} />
                    </div>
                  );
                case 'flexible_columns':
                  return <CardColumns key={i} {...component} />;
                case 'embedded_content':
                  return <EmbeddedContent key={i} {...component} />;
                case 'text_block':
                  return <TextBlock key={i} {...component} />;
                case 'media_carousel':
                  return <FlexibleCarousel key={i} {...component} />;
                case 'table':
                  return <FlexibleTable key={i} {...component} />;
                default:
                  console.log('Undefined flexible component!');
                  return null;
              }
            })}
          </div>
        )}
        {data?.content_sidebar && data.content_sidebar.length > 0 && (
          <div className='lg:w-1/3 lg:ml-4'>
            {data.content_sidebar.map((component, i) => {
              switch (component.acf_fc_layout) {
                case 'flexible_card':
                  return (
                    <div key={i} className='mb-4'>
                      <FlexibleCard {...component} />
                    </div>
                  );
                default:
                  console.log('Undefined flexible component!');
                  return null;
              }
            })}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default FlexiblePage;
