import React, { Fragment, useState, useEffect } from 'react';
import Modal from 'react-responsive-modal';
import User from '../../types/User';
import ReactCodeInput from 'react-code-input';
import { PulseLoader } from 'react-spinners';
import Button from '../../button';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onCodeSubmitted: (code: string, cb: (success: boolean) => void) => void;
  onCancel: () => void;
  user: User | undefined;
  tfaUseEmail?: boolean;
  setTfaUseEmail?: (v: boolean) => void;
  onSelectedTfaMethod?: (useEmail: boolean) => void;
}
const SecondFactorAuthenticationModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  onCodeSubmitted,
  onCancel,
  user,
  tfaUseEmail,
  setTfaUseEmail,
  onSelectedTfaMethod,
}) => {
  const [hasSubmit, setHasSubmit] = useState(false);
  const [codeComponentKey, setCodeComponentKey] = useState(1);
  const [failed, setFailed] = useState(false);
  const [showMethodSelector, setShowMethodSelector] = useState(false);

  useEffect(() => {
    setShowMethodSelector(onSelectedTfaMethod != undefined);
  }, []);

  const onClose = () => {
    setIsOpen(false);
    setFailed(false);
    onCancel();
  };

  const onCodeInput = (code: string) => {
    if (code.length == 7) {
      setHasSubmit(true);
      onCodeSubmitted(code, (success) => {
        setHasSubmit(false);
        setFailed(!success);
        setCodeComponentKey(codeComponentKey + 1);
      });
    }
  };

  const resend = () => {
    setShowMethodSelector(true);
  };

  const selectMethod = (useEmail: boolean) => {
    setShowMethodSelector(false);
    setTfaUseEmail && setTfaUseEmail(useEmail);
    onSelectedTfaMethod && onSelectedTfaMethod(useEmail);
  };

  return (
    <Modal
      classNames={{ modal: 'modal' }}
      center={true}
      open={isOpen}
      onClose={onClose}
      showCloseIcon={true}
    >
      <div className='flex flex-col'>
        <div className='h2 px-4 mb-1'>Security Check</div>
        {showMethodSelector ? (
          <div className='px-4'>
            <p className='mb-4'>
              How would you like to receive your security code?
            </p>
            <div className='grid grid-cols-2 gap-10'>
              <Button
                onClick={() => selectMethod(false)}
                className='btn-secondary'
              >
                Text Message
              </Button>
              <Button
                onClick={() => selectMethod(true)}
                className='btn-secondary'
              >
                Email
              </Button>
            </div>
          </div>
        ) : (
          <Fragment>
            <p className='px-4 mb-4'>
              {tfaUseEmail ? (
                <span>
                  We&apos;ve sent a code to your <b>email</b> that will expire
                  after 10 minutes.
                </span>
              ) : (
                <span>
                  We&apos;ve sent a text to your mobile number{' '}
                  {(user?.mobileNumber?.length || 0) <= 4 && (
                    <span>ending with</span>
                  )}{' '}
                  <b>{user?.mobileNumber}</b>.
                </span>
              )}
              <br />
              <br />
              Please enter your verification code below.
            </p>
            {!hasSubmit ? (
              <Fragment>
                <div className='px-4'>
                  <ReactCodeInput
                    key={codeComponentKey}
                    name='tfaCode'
                    type='text'
                    inputMode='numeric'
                    fields={7}
                    className='reactCodeInput'
                    autoFocus={true}
                    disabled={hasSubmit}
                    onChange={onCodeInput}
                  />
                </div>
                {failed && !hasSubmit && (
                  <div className='px-4 mt-3 text-danger'>
                    The code you provided was incorrect, please try again.
                  </div>
                )}

                {onSelectedTfaMethod && (
                  <a
                    href='#'
                    onClick={resend}
                    className='underline text-center text-blue-dark font-bold mt-4'
                  >
                    Resend
                  </a>
                )}
              </Fragment>
            ) : (
              <div className='pt-1.5 lg:pt-1 text-center'>
                <PulseLoader color='#252f60' size='8px' />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Modal>
  );
};

export default SecondFactorAuthenticationModal;
