import React from 'react';
import { IconWithText as Props } from '../../../types/FlexibleComponents';
import FlexibleIcon from '../FlexibleIcon/FlexibleIcon';

const IconWithText: React.FC<Props> = ({ color, text, icon }) => {
  return (
    <div className='flex flex-row items-center'>
      <div className='flex-none mr-4'>
        <FlexibleIcon icon={icon} />
      </div>
      <div className='flex-1'>
        <div className={`h2 ${color ? 'text-white' : ''}`}>{text}</div>
      </div>
    </div>
  );
};

export default IconWithText;
