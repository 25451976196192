import { FieldProps } from 'formik';
import React from 'react';
import { FileField } from '../../../../types/FlexibleForm';
import FileInput from '../../../input/FileInput';

const FlexibleFileInput: React.FC<FieldProps & FileField> = ({
  field,
  meta,
  label,
  form,
  validation_message,
  tooltip,
  name,
  ...rest
}) => {
  return (
    <FileInput
      {...rest}
      {...field}
      label={label}
      errorMessage={
        (form.touched[field.name]?.valueOf() &&
          form.errors[field.name]?.toString()) ||
        undefined
      }
      onChange={(e) => {
        e.target.files && e.target.files.length > 0
          ? form.setFieldValue(field.name, {
              file: e.target.files[0],
              path: e.target.value,
            })
          : form.setFieldValue(field.name, null);
      }}
      onFileRemove={() => form.setFieldValue(field.name, null)}
      tooltip={tooltip || undefined}
    />
  );
};

export default FlexibleFileInput;
