import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

interface Props {
  phoneNumber: string;
  mobileNumber: string;
}

function updateUserContactDetails(
  userContactDetails: Props
): Promise<AxiosResponse> {
  return axios.put(endpoints.user.updateUserContactDetails, userContactDetails);
}

export default updateUserContactDetails;
