import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

export interface CurrentBalance {
  balance: number;
  accountReference: string;
}

function getCurrentBalance(): Promise<AxiosResponse<CurrentBalance>> {
  return axios.get(endpoints.user.getCurrentBalance);
}

export default getCurrentBalance;
