import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import arrowIcon from '../../assets/images/arrow.svg';
import Fieldset from '../fieldset/Fieldset';

export interface OptionType {
  label: string;
  value: string;
}

interface Props {
  options: OptionType[];
  onChange: (selectedOption: OptionType) => void;
  selectedOption?: OptionType;
  inputClassName?: string;
  optionsClassName?: string;
  label?: string;
  tooltip?: string;
  errorMessage?: string;
  required?: boolean;
  fieldsetClassName?: string;
}

const DropdownItem: React.FC<{
  id: string;
  label: string;
  onClick: () => void;
  optionsClassName?: string;
}> = ({ label, onClick, id, optionsClassName }) => {
  return (
    <div
      key={id}
      onClick={onClick}
      className={`input dropdownItem pl-4 flex items-center cursor-pointer hover:underline ${optionsClassName} `}
    >
      {label}
    </div>
  );
};

const SelectInput: React.FC<Props> = ({
  options,
  onChange,
  selectedOption,
  inputClassName,
  optionsClassName,
  label,
  errorMessage,
  required,
  tooltip,
  fieldsetClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const selectRef = useRef<HTMLDivElement>(null);

  const selectOption = (option: OptionType) => {
    onChange(option);
    toggling();
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  return (
    <Fieldset
      label={label}
      required={required}
      errorMessage={errorMessage}
      tooltip={tooltip}
      className={fieldsetClassName}
    >
      <div ref={selectRef} className='relative'>
        <div
          className={classNames(
            `input pl-4 flex items-center cursor-pointer ${inputClassName}  `,
            {
              'rounded-lg border-white border-l-2 border-r-2 border-t-2':
                !isOpen,
              'rounded-t-lg border-blue border-2': isOpen,
            }
          )}
          onClick={() => toggling()}
        >
          {selectedOption?.label || '- Please select - '}
          <div
            className={classNames('absolute right-6 top-4 duration-500', {
              'rotate-90': !isOpen,
              'rotate-270': isOpen,
            })}
          >
            <img className='h-4' src={arrowIcon} alt='Open' />
          </div>
        </div>
        {isOpen && (
          <div className='z-10 absolute w-full rounded-b-lg border-blue border-l-2 border-r-2 border-b-2'>
            <div className='dropdownListContainter'>
              {options.map((option, i) => {
                return (
                  <DropdownItem
                    onClick={() => selectOption(option)}
                    label={option.label}
                    id={option.value}
                    optionsClassName={optionsClassName}
                    key={`${option.label}_${option.value}_${i}`}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Fieldset>
  );
};

export default SelectInput;
