import React from 'react';
import FlexibleCard from '../../components/flexible/FlexibleCard/FlexibleCard';
import FlexibleColumns from '../../components/flexible/FlexibleColumns';
import MainLayout from '../../layouts/MainLayout';
import { useAuth } from '../../state/AuthProvider';
import { usePolyglot } from '../../state/PolyglotProvider';

const MaintenanceAndRepairsPage: React.FC = () => {
  const { user } = useAuth();
  const { polyglot } = usePolyglot();

  return (
    <MainLayout>
      <div className='flex flex-col flex-1'>
        <h1 className='h1'>
          {polyglot?.t('pages.maintenance_and_repairs.title')}
        </h1>
        <div className='flex flex-col-reverse lg:flex-row  my-4 '>
          <div className='flex flex-col lg:w-2/3 w-full lg:mr-4 '>
            {!user?.isSharedOwner && (
              <FlexibleColumns
                acf_fc_layout='flexible_columns'
                columns={[
                  {
                    content: [
                      {
                        acf_fc_layout: 'flexible_card',
                        color: 'white',
                        title:
                          polyglot?.t(
                            'pages.maintenance_and_repairs.card_book_a_repair.title'
                          ) || '',
                        icon: 'bookRepair',
                        link_type: 'none',
                        file: null,
                        embedded_content: null,
                        internal_link: null,
                        external_link: null,
                        tooltip_title: polyglot?.t(
                          'pages.maintenance_and_repairs.card_book_a_repair.tooltip_title'
                        ),
                        tooltip_body: polyglot?.t(
                          'pages.maintenance_and_repairs.card_book_a_repair.tooltip_body'
                        ),
                        content: [
                          {
                            acf_fc_layout: 'text_block',
                            wysiwyg: `<p>${polyglot?.t(
                              'pages.maintenance_and_repairs.card_book_a_repair.introduction'
                            )}</p>`,
                          },
                          {
                            acf_fc_layout: 'button',
                            label:
                              polyglot?.t(
                                'pages.maintenance_and_repairs.card_book_a_repair.cta_label'
                              ) || '',
                            link_type: 'internal_link',
                            file: null,
                            embedded_content: null,
                            internal_link: {
                              id: 2418,
                              url: '/maintenance-repairs/book-a-repair',
                            },
                            external_link: null,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    content: [
                      {
                        acf_fc_layout: 'flexible_card',
                        color: 'white',
                        title:
                          polyglot?.t(
                            'pages.maintenance_and_repairs.card_track_a_repair.title'
                          ) || '',
                        icon: 'trackRepair',
                        link_type: 'none',
                        file: null,
                        embedded_content: null,
                        internal_link: null,
                        external_link: null,
                        tooltip_title: polyglot?.t(
                          'pages.maintenance_and_repairs.card_track_a_repair.tooltip_title'
                        ),
                        tooltip_body: polyglot?.t(
                          'pages.maintenance_and_repairs.card_track_a_repair.tooltip_body'
                        ),
                        content: [
                          {
                            acf_fc_layout: 'text_block',
                            wysiwyg: `<p>${polyglot?.t(
                              'pages.maintenance_and_repairs.card_track_a_repair.introduction'
                            )}</p>`,
                          },
                          {
                            acf_fc_layout: 'button',
                            label:
                              polyglot?.t(
                                'pages.maintenance_and_repairs.card_track_a_repair.cta_label'
                              ) || '',
                            link_type: 'internal_link',
                            file: null,
                            embedded_content: null,
                            internal_link: {
                              id: 2419,
                              url: '/maintenance-repairs/track-a-repair',
                            },
                            external_link: null,
                          },
                        ],
                      },
                    ],
                  },
                ]}
                columns_per_row={2}
                fixed_width_columns
              />
            )}

            <FlexibleColumns
              acf_fc_layout='flexible_columns'
              columns={[
                {
                  content: [
                    {
                      acf_fc_layout: 'flexible_card',
                      color: 'white',
                      title:
                        polyglot?.t(
                          'pages.maintenance_and_repairs.card_who_is_responsible.title'
                        ) || '',
                      icon: 'bell',
                      link_type: 'internal_link',
                      file: null,
                      embedded_content: null,
                      internal_link: {
                        id: 2411,
                        url: '/help-advice/responsibilities',
                      },
                      external_link: null,
                      tooltip_title: polyglot?.t(
                        'pages.maintenance_and_repairs.card_who_is_responsible.tooltip_title'
                      ),
                      tooltip_body: polyglot?.t(
                        'pages.maintenance_and_repairs.card_who_is_responsible.tooltip_body'
                      ),
                      content: [],
                    },
                  ],
                },
                {
                  content: [
                    {
                      acf_fc_layout: 'flexible_card',
                      color: 'white',
                      title:
                        polyglot?.t(
                          'pages.maintenance_and_repairs.card_repair_guidance.title'
                        ) || '',
                      icon: 'bell',
                      link_type: 'internal_link',
                      file: null,
                      embedded_content: null,
                      internal_link: {
                        id: 2413,
                        url: '/help-advice/repair-guidance',
                      },
                      external_link: null,
                      tooltip_title: polyglot?.t(
                        'pages.maintenance_and_repairs.card_repair_guidance.tooltip_title'
                      ),
                      tooltip_body: polyglot?.t(
                        'pages.maintenance_and_repairs.card_repair_guidance.tooltip_body'
                      ),
                      content: [],
                    },
                  ],
                },
              ]}
              columns_per_row={2}
              fixed_width_columns
            />
          </div>
          <div className='flex flex-col lg:w-1/3 lg:ml-4 mt-0 mb-4 lg:mt-0 lg:mb-0 '>
            <div className='mb-4 '>
              <FlexibleCard
                acf_fc_layout='flexible_card'
                color='blue'
                title={
                  polyglot?.t(
                    'pages.maintenance_and_repairs.sidebar_card.title'
                  ) || ''
                }
                icon='none'
                link_type='external_link'
                file={null}
                embedded_content={null}
                internal_link={null}
                external_link='tel:0345 305 5335'
                content={[
                  {
                    acf_fc_layout: 'text_block',
                    wysiwyg: `<p>${polyglot?.t(
                      'pages.maintenance_and_repairs.sidebar_card.introduction'
                    )}</p>`,
                  },
                  {
                    acf_fc_layout: 'icon_with_text',
                    icon: 'phone',
                    text: 'Call: 0345 305 5335',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export default MaintenanceAndRepairsPage;
