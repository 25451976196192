import React, { Dispatch, FC, useContext, useState } from 'react';

interface SidebarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const SidebarContext = React.createContext<SidebarProps>({
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
});

export const useSidebar = () => useContext(SidebarContext);

const SidebarProvider: FC = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <SidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
