import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import Button from '../../button';
import ChangePassword from '../../pages/AccountPage/components/ChangePassword';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSuccessfullyResetPassword: () => void;
}
const ChangePasswordModal: React.FC<Props> = ({
  isOpen,
  onSuccessfullyResetPassword,
}) => {
  const [successfullyResetPassword, setSuccessfullyResetPassword] =
    useState(false);
  return (
    <Modal
      classNames={{ modal: 'modal' }}
      center={true}
      open={isOpen}
      onClose={() => {}}
      showCloseIcon={false}
    >
      {successfullyResetPassword ? (
        <div className='flex flex-col'>
          <div className='h2 px-4'>
            You have successfully reset your password. You may now press the
            button below to continue to the JJH Portal.
          </div>
          <div className='flex justify-center'>
            <Button
              onClick={() => onSuccessfullyResetPassword()}
              className='btn-primary'
            >
              Continue to site
            </Button>
          </div>
        </div>
      ) : (
        <div className='flex flex-col'>
          <div className='h2 px-4'>
            An Administrator has requested for you to change your password.
            Please enter your new password below.
          </div>
          <ChangePassword
            onSuccessfulPasswordReset={() => setSuccessfullyResetPassword(true)}
          />
        </div>
      )}
    </Modal>
  );
};

export default ChangePasswordModal;
