import { FormikHelpers } from 'formik';
import React, { ChangeEvent } from 'react';
import MultiFileInput from '../../../input/MultiFileInput';

interface Props {
  setFieldTouched: FormikHelpers<any>['setFieldTouched'];
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  fieldNames: string[];
  values: string[];
  maxFiles: number;
  label: string;
  inputClassName: string;
  setFieldError: FormikHelpers<any>['setFieldError'];
}

const FlexibleMultiFileInput: React.FC<Props> = ({
  fieldNames,
  maxFiles,
  setFieldTouched,
  setFieldValue,
  values,
  label,
  inputClassName,
  setFieldError,
}) => {
  const numberOfUploadedFiles = values.filter((v) => v !== '').length;

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const tmp = values.findIndex((v) => v === '');
    if (e.target.files[0].size / 1024 / 1024 > 2) {
      setFieldTouched(fieldNames[0]);
      setFieldError(
        fieldNames[0],
        'Selected file is too big. Files must be below 2MB'
      );
      return;
    }
    setFieldError(fieldNames[0], undefined);
    setFieldValue(fieldNames[tmp], {
      file: e.target.files[0],
      path: e.target.value,
    });
    setFieldTouched(fieldNames[tmp]);
  };

  return (
    <MultiFileInput
      inputClassName={inputClassName}
      fieldNames={fieldNames}
      label={label}
      id={label}
      values={values}
      onChange={(e) => {
        e.target.files && e.target.files.length > 0 && onFileChange(e);
      }}
      onFileRemove={(fieldName) => setFieldValue(fieldName, '')}
      numberOfUploadedFiles={numberOfUploadedFiles}
      maxFiles={maxFiles}
    />
  );
};

export default FlexibleMultiFileInput;
