import React, { Dispatch, SetStateAction } from 'react';
import MenuObject from '../../../types/MenuObject';
import SidebarItem from './SidebarItem';
import menuArrow from '../../../assets/images/menuArrow.svg';
import classnames from 'classnames';
import escapeSpecialCharacters from '../../../helpers/escapeSpecialCharacters';

interface Props {
  menuItems?: SubMenuItems[];
  setIsSubMenuOpen: Dispatch<SetStateAction<boolean>>;
  activeSubMenu: string;
}
interface SubMenuItems {
  parentUrl: string;
  children: MenuObject[];
}

const SidebarSubMenu: React.FC<Props> = ({
  menuItems,
  setIsSubMenuOpen,
  activeSubMenu,
}) => {
  return (
    <>
      <div
        className={`flex flex-row items-center  hover:underline cursor-pointer  px-7 mb-4`}
      >
        <img
          alt='<'
          className='self-end rotate-180 h-6 w-6'
          src={menuArrow}
          onClick={() => setIsSubMenuOpen(false)}
        />
        <span className='pl-3 font-semibold text-blue text-sm '>
          Back to main dashboard
        </span>
      </div>
      {menuItems?.map((item, i) => (
        <div
          key={`SidebarItem_wrapper_${i}`}
          className={classnames({
            hidden: item.parentUrl !== activeSubMenu,
          })}
        >
          {item.children.map((child, i) => {
            return (
              <SidebarItem
                name={escapeSpecialCharacters(child.title)}
                link={child.url}
                key={`SidebarItem_${i}`}
                className={classnames(
                  'border-white border-b-2 pl-7 lg:pl-0 py-4 cursor-pointer',
                  {
                    'font-semibold': i === 0,
                    'font-medium': i !== 0,
                  }
                )}
              />
            );
          })}
        </div>
      ))}
    </>
  );
};

export default SidebarSubMenu;
