import * as Yup from 'yup';
import { FlexibleFormField } from '../../../types/FlexibleForm';
import createValidationSchemaAddressField from './createValidationSchemaAddressField';
import createValidationSchemaCheckboxField from './createValidationSchemaCheckboxField';
import createValidationSchemaConsentField from './createValidationSchemaConsentField';
import createValidationSchemaDateField from './createValidationSchemaDateField';
import createValidationSchemaEmailField from './createValidationSchemaEmailField';
import createValidationSchemaFileField from './createValidationSchemaFileField';
import createValidationSchemaNumberField from './createValidationSchemaNumberField';
import createValidationSchemaPhoneField from './createValidationSchemaPhoneField';
import createValidationSchemaRadioField from './createValidationSchemaRadioField';
import createValidationSchemaSelectField from './createValidationSchemaSelectField';
import createValidationSchemaTextareaField from './createValidationSchemaTextareaField';
import createValidationSchemaTextField from './createValidationSchemaTextField';
import createValidationSchemaTimeField from './createValidationSchemaTimeField';

export default function createValidationSchema(fields: FlexibleFormField[]): {
  [key: string]: any;
} {
  const shape: { [key: string]: any } = {};

  for (const field of fields) {
    switch (field.type) {
      case 'address':
        shape[field.name] = createValidationSchemaAddressField(field);
        break;
      case 'checkbox':
        shape[field.name] = createValidationSchemaCheckboxField(field);
        break;
      case 'consent':
        shape[field.name] = createValidationSchemaConsentField(field);
        break;
      case 'date':
        shape[field.name] = createValidationSchemaDateField(field);
        break;
      case 'email':
        shape[field.name] = createValidationSchemaEmailField(field);
        break;
      case 'file':
        shape[field.name] = createValidationSchemaFileField(field);
        break;
      case 'number':
        shape[field.name] = createValidationSchemaNumberField(field);
        break;
      case 'phone':
        shape[field.name] = createValidationSchemaPhoneField(field);
        break;
      case 'radio':
        shape[field.name] = createValidationSchemaRadioField(field);
        break;
      case 'select':
        shape[field.name] = createValidationSchemaSelectField(field);
        break;
      case 'text':
        shape[field.name] = createValidationSchemaTextField(field);
        break;
      case 'textarea':
        shape[field.name] = createValidationSchemaTextareaField(field);
        break;
      case 'time':
        shape[field.name] = createValidationSchemaTimeField(field);
        break;
    }
  }

  return Yup.object().shape(shape);
}
