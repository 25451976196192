import React, { PropsWithChildren } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { useAuth } from '../state/AuthProvider';

const PrivateRoute: React.FC<PropsWithChildren<any>> = ({
  children,
  requiresFullAccess,
  ...rest
}) => {
  const { isAuthenticated, isLoading } = useAuth();
  const { user } = useAuth();
  const hasAccess = requiresFullAccess ? !user?.isPotentialTenant : true;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoading ? (
          <div className='flex h-full justify-center items-center'>
            <PulseLoader color='#D1D1D1' size='8px' />
          </div>
        ) : isAuthenticated && hasAccess ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
