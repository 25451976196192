import { FlexibleFormField } from '../../../types/FlexibleForm';
import User from '../../../types/User';

function getAutofillValue(field: FlexibleFormField, user?: User) {
  if (!user) {
    return '';
  }

  const { forename, middlename, surname, address, correspondenceAddress } =
    user;

  switch (field.autofill) {
    case null:
      return '';
    case 'address.addressId':
      return address.addressId.toString();
    case 'address.country':
      return address.country;
    case 'address.county':
      return address.county;
    case 'address.district':
      return address.district;
    case 'address.postcode':
      return address.postcode;
    case 'address.propertyNumberOrName':
      return address.propertyNumberOrName;
    case 'address.street':
      return address.street;
    case 'address.town':
      return address.town;
    case 'correspondenceAddress.addressId':
      return correspondenceAddress.addressId.toString();
    case 'correspondenceAddress.country':
      return correspondenceAddress.country;
    case 'correspondenceAddress.county':
      return correspondenceAddress.county;
    case 'correspondenceAddress.district':
      return correspondenceAddress.district;
    case 'correspondenceAddress.postcode':
      return correspondenceAddress.postcode;
    case 'correspondenceAddress.propertyNumberOrName':
      return correspondenceAddress.propertyNumberOrName;
    case 'correspondenceAddress.street':
      return correspondenceAddress.street;
    case 'correspondenceAddress.town':
      return correspondenceAddress.town;
    case 'isCreditClaimant':
    case 'isSharedOwner':
      return user[field.autofill] ? '1' : '';
    case 'name':
      return [forename, middlename, surname].filter((s) => !!s).join(' ');
    case 'correspondenceName':
    case 'dateOfBirth':
    case 'email':
    case 'forename':
    case 'gender':
    case 'middlename':
    case 'mobileNumber':
    case 'nationalInsurance':
    case 'phoneNumber':
    case 'surname':
      return user[field.autofill];
    case 'address':
    case 'correspondenceAddress':
      console.log("Don't use address or correspondenceAddress directly.");
      return '';
    case 'mainRentAccountId':
      return user.mainRentAccountId;
    default:
      console.log('Unmatched case in getAutofillValue function.');
      return '';
  }
}

export default function createInitialValues(
  fields: FlexibleFormField[],
  authenticatedUserDetails?: User
): { [key: string]: string } {
  return fields.reduce((acc, field) => {
    switch (field.type) {
      case 'address':
        const subfields = field.fields.reduce(
          (acc, subfield) => ({ ...acc, [subfield.name]: '' }),
          {}
        );
        return { ...acc, [field.name]: '', ...subfields };
      case 'checkbox':
        const options = field.options.reduce(
          (acc, option) => ({ ...acc, [option.name]: '' }),
          {}
        );
        return { ...acc, [field.name]: '', ...options };
      case 'consent':
        // Allows conditional validation to work for Consent fields.
        // While the Gravity Forms API expects form field names in the format of
        // input_X_1 when submitting forms, it expects them in the format of input_X
        // (parentFieldName) for the purpose of conditional logic.
        const tmp = field.name.split('_');
        const parentFieldName = `${tmp[0]}_${tmp[1]}`;
        return { ...acc, [parentFieldName]: '', [field.name]: '' };
      case 'date':
      case 'email':
      case 'file':
      case 'number':
      case 'phone':
      case 'radio':
      case 'select':
      case 'text':
      case 'textarea':
      case 'time':
        return {
          ...acc,
          [field.name]: getAutofillValue(field, authenticatedUserDetails),
        };
      case 'multifile':
        // At the time of calling this function, we shouldn't have to handle
        // this case, because we supply as the argument to this function the
        // fields as-is from WP.
        return acc;
    }
  }, {});
}
