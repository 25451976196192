import classNames from 'classnames';
import React, { Fragment } from 'react';
import TableRow from './TableRow';

interface Props {
  rows: (string | null)[][];
  showBottomRow?: boolean;
}

const TableBody: React.FC<Props> = ({ rows, showBottomRow }) => {
  return (
    <Fragment>
      {rows.map((row, i) => {
        return (
          <TableRow
            key={i}
            className={classNames({
              'border-t-0': i === 0,
              'border-b-0': i === rows.length - 1 && !showBottomRow,
            })}
          >
            {row.map((cell, i) => {
              return (
                <td key={i} className='px-4 py-3 text-blue text-sm min-w-28'>
                  {cell}
                </td>
              );
            })}
          </TableRow>
        );
      })}
      {showBottomRow && (
        <tr>
          <td colSpan={100} className='py-3'></td>
        </tr>
      )}
    </Fragment>
  );
};

export default TableBody;
