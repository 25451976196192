import React from 'react';
import { EmbeddedContent as Props } from '../../../types/FlexibleComponents';

const EmbeddedContent: React.FC<Props> = ({ embedded_content }) => {
  return (
    <div>
      <div className='pb-16/9 relative'>
        <iframe
          src={embedded_content}
          title='title'
          width='100%'
          height='100%'
          frameBorder='0'
          className='absolute'
        />
      </div>
    </div>
  );
};

export default EmbeddedContent;
