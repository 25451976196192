import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AtAGlance from '../../components/atAGlance';
import Card from '../../components/card';
import MainLayout from '../../layouts/MainLayout';
import { usePolyglot } from '../../state/PolyglotProvider';
import homeColouredIcon from '../../assets/images/homeColouredIcon.svg';
import paymentSupportIcon from '../../assets/images/paymentSupportIcon.svg';
import paymentAlertIcon from '../../assets/images/paymentAlertIcon.svg';
import settings from '../../config/settings';
import api from '../../api';

const PaymentPage: React.FC = () => {
  const { polyglot } = usePolyglot();

  const [isCurrentBalanceLoading, setIsCurrentBalanceLoading] = useState(false);
  const [currentBalanceData, setCurrentBalanceData] = useState<any>();
  const { push } = useHistory();

  useEffect(() => {
    let isComponentMounted = true;
    setIsCurrentBalanceLoading(true);
    (async function () {
      try {
        const currentBalanceResponse = await api.getCurrentBalance();

        if (currentBalanceResponse.data.balance) {
          if (isComponentMounted) {
            setCurrentBalanceData(currentBalanceResponse.data);
          }
        }
        if (isComponentMounted) {
          setIsCurrentBalanceLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsCurrentBalanceLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  return (
    <MainLayout>
      <div className='flex flex-col flex-1'>
        <h1 className='h1 mb-4'>{polyglot?.t('pages.payments.title')}</h1>

        <div className='flex flex-col-reverse lg:flex-row  my-4 '>
          <div className='flex flex-col lg:w-2/3 w-full lg:mr-4'>
            <div className='mb-4'>
              <Card
                title={
                  polyglot?.t('pages.payments.card_rent.title', {
                    date: '1st December',
                  }) || ''
                }
                description={polyglot?.t(
                  'pages.payments.card_rent.introduction'
                )}
                primaryButtonText={polyglot?.t(
                  'pages.payments.card_rent.cta_label'
                )}
                secondaryButtonText={`Pay offline by calling: ${polyglot?.t(
                  'general.support_telephone_number'
                )}`}
                secondaryButtonLink={`tel:${polyglot?.t(
                  'general.support_telephone_number'
                )}`}
                icon={homeColouredIcon}
                primaryButtonOnClick={() => {
                  window.open(settings.allPayUrl, '_blank');
                }}
                toolTipTitle={polyglot?.t(
                  'pages.payments.card_rent.tooltip_title'
                )}
                toolTipBody={polyglot?.t(
                  'pages.payments.card_rent.tooltip_body'
                )}
              />
            </div>
            <div className='flex flex-col md:flex-row  my-4'>
              <div className='md:w-1/2 md:mr-4 mb-4 md:mb-0'>
                <Card
                  title={
                    polyglot?.t('pages.payments.card_direct_debit.title') || ''
                  }
                  description={polyglot?.t(
                    'pages.payments.card_direct_debit.introduction'
                  )}
                  primaryButtonText={polyglot?.t(
                    'pages.payments.card_direct_debit.cta_label'
                  )}
                  primaryButtonOnClick={() => push('/direct-debit')}
                  icon={paymentAlertIcon}
                  toolTipTitle={polyglot?.t(
                    'pages.payments.card_direct_debit.tooltip_title'
                  )}
                  toolTipBody={polyglot?.t(
                    'pages.payments.card_direct_debit.tooltip_body'
                  )}
                />
              </div>
              <div className='md:w-1/2 md:ml-4 mt-4 md:mt-0'>
                <Card
                  title={
                    polyglot?.t('pages.payments.card_payment_support.title') ||
                    ''
                  }
                  description={polyglot?.t(
                    'pages.payments.card_payment_support.introduction'
                  )}
                  primaryButtonText={polyglot?.t(
                    'pages.payments.card_payment_support.cta_label'
                  )}
                  primaryButtonOnClick={() =>
                    push('/help-advice/payment-support')
                  }
                  icon={paymentSupportIcon}
                  toolTipTitle={polyglot?.t(
                    'pages.payments.card_payment_support.tooltip_title'
                  )}
                  toolTipBody={polyglot?.t(
                    'pages.payments.card_payment_support.tooltip_body'
                  )}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col lg:w-1/3 lg:ml-4 mt-0 mb-4 lg:mt-0 lg:mb-0'>
            <AtAGlance
              isCurrentBalanceLoading={isCurrentBalanceLoading}
              currentBalance={currentBalanceData?.balance}
              lastPayment={
                currentBalanceData && {
                  date: currentBalanceData.lastPaymentDate,
                  amount: currentBalanceData.lastPaymentAmount,
                }
              }
              containerClassname={`${'bg-blue flex flex-col   rounded-lg pt-6 px-7 pb-8'}`}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default PaymentPage;
