import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { PulseLoader } from 'react-spinners';
import Button from '../../button';

interface Props {
  currentBalance?: number;
  containerClassname?: string;
  isCurrentBalanceLoading: boolean;
  lastPayment?: { amount: number; date: string };
}

const AtAGlance: React.FC<Props> = ({
  currentBalance,
  containerClassname,
  isCurrentBalanceLoading,
  lastPayment,
}) => {
  const { push } = useHistory();
  return (
    <div className={containerClassname}>
      <div className='h2 text-white'>At a glance</div>
      <div className='pt-6'>
        <div className='h3 uppercase text-white font-semibold'>
          Current Balance
        </div>
        {isCurrentBalanceLoading ? (
          <PulseLoader color='#D1D1D1' size='8px' />
        ) : (
          <div className='h1 text-white text-5xl lg:text-6xl pt-2 leading-16'>
            £{(currentBalance || 0).toFixed(2)}
          </div>
        )}
      </div>
      <div className='divider'></div>
      <div>
        <div className='h3 uppercase text-white font-semibold'>
          LAST PAYMENT MADE
        </div>
        <div className='text-white '>
          {isCurrentBalanceLoading ? (
            <PulseLoader color='#D1D1D1' size='8px' />
          ) : (
            <Fragment>
              <span className='pr-2'>
                £
                {(
                  (lastPayment && lastPayment.amount < 0
                    ? lastPayment.amount * -1
                    : lastPayment?.amount) || 0
                ).toFixed(2)}
              </span>
              <span>{lastPayment?.date}</span>
            </Fragment>
          )}
        </div>
      </div>

      <Button
        icon='arrow'
        className='btn-secondary bg-white text-blue relative mb-2'
        onClick={() => push('/payments/rent-statement')}
      >
        View my rent statements
      </Button>
    </div>
  );
};

export default AtAGlance;
