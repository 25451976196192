import React from 'react';
import Modal from 'react-responsive-modal';
import Button from '../../button';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const FormSuccessfullySubmittedModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal
      classNames={{ modal: 'modal' }}
      center={true}
      open={isOpen}
      onClose={() => {}}
      showCloseIcon={false}
    >
      <div className='flex flex-col'>
        <div className='h2 px-4'>
          Thank you for your submission. One of our advisors will be in contact
          as soon as possible.
        </div>
        <div id='portalForm' className='flex justify-center'>
          <Button
            id='portalForm'
            onClick={() => {
              setIsOpen(false);
            }}
            className='btn-primary mb-0'
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FormSuccessfullySubmittedModal;
