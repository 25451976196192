import { FieldProps } from 'formik';
import React from 'react';
import { TextField } from '../../../../types/FlexibleForm';

import TextInput from '../../../input/TextInput';

const FlexibleTextInput: React.FC<FieldProps & TextField> = ({
  field,
  meta,
  label,
  form,
  validation_message,
  tooltip,
  placeholder,

  ...rest
}) => {
  return (
    <TextInput
      {...rest}
      {...field}
      label={label}
      placeholder={placeholder || undefined}
      errorMessage={
        (form.touched[field.name]?.valueOf() &&
          form.errors[field.name]?.toString()) ||
        undefined
      }
      tooltip={tooltip || undefined}
    />
  );
};

export default FlexibleTextInput;
