import { FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import tickIcon from '../../../../assets/images/tickIcon.svg';

const FlexibleCheckboxInput: React.FC = ({ children }) => {
  return <div className=''>{children}</div>;
};

interface FlexibleCheckboxInputOptionProps {
  setFieldTouched: FormikHelpers<any>['setFieldTouched'];
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  fieldName: string;
  label: string;
  value: string;
  parentFieldName: string;
}

const FlexibleCheckboxInputOption: React.FC<
  FlexibleCheckboxInputOptionProps
> = ({
  setFieldTouched,
  setFieldValue,
  fieldName,
  parentFieldName,
  label,
  value,
}) => {
  useEffect(() => {
    // Allows conditional validation to work for Checkbox fields.

    // While the Gravity Forms API expects form field names in the format of

    // input_X_1, input_X_2, etc. when submitting forms, it expects them in the

    // format of input_X (parentFieldName) for the purpose of conditional logic.

    // NOTE: Conditional logic will only work properly when there is one

    // checkbox option. This is a limitation of Gravity Forms.

    setFieldValue(parentFieldName, value);
  }, [value]);

  return (
    <div
      className='flex h-6 flex-row mb-2 cursor-pointer'
      onClick={() => {
        setFieldTouched(parentFieldName);
        setFieldValue(fieldName, value !== '' ? '' : label);
      }}
    >
      <div className='flex items-center'>
        <div className='bg-white border-2 flex border-blue h-5 items-center justify-center mr-4 rounded-md w-5'>
          {value !== '' && <img src={tickIcon} className='h-3 w-3' />}
        </div>
      </div>
      <div className=''>
        <p className='p'>{label}</p>
      </div>
    </div>
  );
};

export { FlexibleCheckboxInputOption, FlexibleCheckboxInput };
