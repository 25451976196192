import React, { Fragment, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import api from '../../../api';
import { Vulnerability } from '../../../api/getAllVulnerabilities';
import successIcon from '../../../assets/images/successIcon.svg';
import warningIcon from '../../../assets/images/warningIcon.svg';
import Button from '../../../button';
import IconWithText from '../../../components/flexible/IconWithText';
import CheckboxInput from '../../../components/input/CheckboxInput';
import { useAuth } from '../../../state/AuthProvider';
import { usePolyglot } from '../../../state/PolyglotProvider';

const Vulnerabilities: React.FC = () => {
  const { user, setUser } = useAuth();
  const [vulnerabilities, setVulnerabilities] = useState<Vulnerability[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaveShown, setIsSaveShown] = useState(false);
  const [message, setMessage] = useState('');
  const [messageIcon, setMessageIcon] = useState(warningIcon);
  const { polyglot } = usePolyglot();
  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!user || !vulnerabilities) {
      setIsSubmitting(false);
      return;
    }
    try {
      await api.updateUserVulnerabilities(
        vulnerabilities.filter((v) => v.value)
      );
      setUser({
        ...user,
        vulnerabilities: vulnerabilities.filter((v) => v.value),
      });
      setIsSubmitting(false);
      setMessage('Details updated successfully.');
      setMessageIcon(successIcon);
    } catch (error) {
      setIsSubmitting(false);
      setMessage(
        'An unexpected error occured. If this problem persists please get in touch.'
      );
      setMessageIcon(warningIcon);
      console.log(error);
    }
  };

  const handleVulnerabilityChange = (vulnerability: Vulnerability) => {
    if (!vulnerabilities) {
      return;
    }
    const vulnerabilityToUpdate = vulnerabilities?.findIndex(
      (v) => v.codeId === vulnerability.codeId
    );

    if (vulnerabilityToUpdate < 0) {
      return;
    }
    const vulnerabilitiesList = [...vulnerabilities];
    vulnerabilitiesList[vulnerabilityToUpdate] = {
      ...vulnerability,
      value: vulnerability.value ? false : true,
    };
    setVulnerabilities(vulnerabilitiesList);
  };

  useEffect(() => {
    let isComponentMounted = true;
    setIsLoading(true);
    (async function () {
      try {
        const response = await api.getAllVulnerabilities();

        if (isComponentMounted) {
          setVulnerabilities(
            response.data.map((vulnerability) => {
              return {
                ...vulnerability,
                value: user?.vulnerabilities.find(
                  (v) => v.codeId === vulnerability.codeId
                )
                  ? true
                  : false,
              };
            })
          );
          setIsLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!user || !vulnerabilities) {
      return;
    }

    const hasValuesBeenUpdated =
      vulnerabilities
        .filter((v) => v.value)
        .map((v) => v.codeId)
        .sort()
        .join(',') !==
      user.vulnerabilities
        .filter((v) => (v.value === undefined ? true : v.value))
        .map((v) => v.codeId)
        .sort()
        .join(',');

    if (!isSaveShown) {
      hasValuesBeenUpdated && setIsSaveShown(true);
      return;
    }
    !hasValuesBeenUpdated && setIsSaveShown(false);
  }, [vulnerabilities, user]);

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='lg:w-2/3 lg:mr-4'>
        <div className='flex flex-col  rounded-lg p-6 bg-white mb-4'>
          <div className='h2 mb-4'>Vulnerabilities</div>
          {isLoading ? (
            <PulseLoader color='#D1D1D1' size='8px' />
          ) : (
            user && (
              <Fragment>
                <div className='flex flex-col lg:flex-row lg:flex-wrap'>
                  {vulnerabilities?.map((v, i) => {
                    return (
                      <div className='lg:w-1/2 w-full py-2' key={i}>
                        <CheckboxInput
                          label={v.description}
                          value={v.value}
                          onClick={() => handleVulnerabilityChange(v)}
                        />
                      </div>
                    );
                  })}
                </div>

                {message && (
                  <div className='flex flex-row pt-2'>
                    <img
                      src={messageIcon}
                      alt='!'
                      className='mr-4 h-6 self-center'
                    />
                    <div className='inputErrorMessage'>{message}</div>
                  </div>
                )}
                {isSaveShown && (
                  <div>
                    <Button
                      onClick={() => handleSubmit()}
                      className='btn-primary'
                    >
                      {isSubmitting ? (
                        <div className='pt-1.5 lg:pt-1'>
                          <PulseLoader color='#D1D1D1' size='8px' />
                        </div>
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                )}
              </Fragment>
            )
          )}
        </div>
      </div>
      <div className='lg:w-1/3 lg:ml-4 mt-0 mb-4 lg:mt-0 lg:mb-0'>
        {polyglot?.t(
          'pages.update_profile.update_vulnerabilities_card_1_title'
        ) && (
          <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
            <div className='h2 text-white'>
              {polyglot?.t(
                'pages.update_profile.update_vulnerabilities_card_1_title'
              )}
            </div>
            <div className='py-4 text-white'>
              {polyglot?.t(
                'pages.update_profile.update_vulnerabilities_card_1_introduction'
              )}
            </div>
            <a
              href={`tel:${polyglot?.t('general.support_telephone_number')}`}
              target='_blank'
              rel='noreferrer'
            >
              <IconWithText
                acf_fc_layout='icon_with_text'
                icon='phone'
                text={`Call: ${polyglot?.t(
                  'general.support_telephone_number'
                )}`}
                color='white'
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Vulnerabilities;
