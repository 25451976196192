import classNames from 'classnames';
import React from 'react';

interface Props {
  tableClassName?: string;
  tableWrapperClassName?: string;
  overflowTable?: boolean;
}

const Table: React.FC<Props> = ({
  children,
  tableWrapperClassName = '',
  tableClassName,
  overflowTable,
}) => {
  return (
    <div
      className={classNames('overflow-x-auto lg:w-full lg:mx-0 flex', {
        '-mx-7 overflowTable': overflowTable,
        ['' + tableWrapperClassName]: tableWrapperClassName,
      })}
    >
      <table
        className={classNames(
          `${tableClassName} w-full rounded-lg bg-white lg:ml-0`,
          {
            'ml-7': overflowTable,
          }
        )}
      >
        {children}
      </table>
      {overflowTable && <div className='min-w-7 lg:hidden'></div>}
    </div>
  );
};

export default Table;
