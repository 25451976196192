import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import api from '../../api';
import warningIcon from '../../assets/images/warningIcon.svg';
import Button from '../../button';
import TextInput from '../../components/input/TextInput';
import PasswordResetEmailSentModal from '../../components/modals/PasswordResetEmailSentModal';
import LoginLayout from '../../layouts/LoginLayout';
import { usePolyglot } from '../../state/PolyglotProvider';

const PasswordResetPage: React.FC = () => {
  const { polyglot } = usePolyglot();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resetError, setResetError] = useState('');
  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  async function onSubmit() {
    setIsSubmitting(true);

    if (email.trim().length === 0) {
      setResetError('Please enter an email');
      setIsSubmitting(false);
      return;
    }
    setResetError('');

    const emailRegex = new RegExp(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    );
    if (!emailRegex.test(email)) {
      setResetError('Please enter a valid email');
      setIsSubmitting(false);
      return;
    }

    try {
      await api.requestPasswordReset(email);
      setIsSubmitting(false);
      setIsModalOpen(true);
      setEmail('');
    } catch (error: any) {
      setIsSubmitting(false);
      if (error.response && error.response.status == 400) {
        setResetError("An account with this email address doesn't exist.");
      } else {
        setResetError(
          'A problem occured, please try again. If this problem persists please contact a system administrator.'
        );
      }
    }

    return;
  }

  return (
    <LoginLayout>
      <h1 className='xl:w-84 my-4 lg:my-0 text-center xl:text-left'>
        {polyglot?.t('pages.forgot_password.title')}
      </h1>
      <p className='my-4 lg:my-0 text-center xl:text-left'>
        {polyglot?.t('pages.forgot_password.introduction')}
      </p>
      <div>
        <form
          onSubmit={(e) => e.preventDefault()}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSubmit();
            }
          }}
        >
          <TextInput
            className='mb-5'
            label='Email'
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            required={true}
          />

          {resetError && (
            <div className='flex flex-row pt-2'>
              <img src={warningIcon} alt='!' className='pr-4' />
              <div className='inputErrorMessage'>{resetError}</div>
            </div>
          )}
        </form>
        <div className='flex flex-col md:flex-row w-full justify-between flex-wrap'>
          <Button
            className='btn-primary'
            disabled={isSubmitting}
            onClick={() => onSubmit()}
          >
            {isSubmitting ? (
              <PulseLoader color='#D1D1D1' size='8px' />
            ) : (
              'Request Password Reset'
            )}
          </Button>
          <Button onClick={() => history.push('/')} className='btn-secondary'>
            Return to Login
          </Button>
        </div>
      </div>
      <PasswordResetEmailSentModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </LoginLayout>
  );
};

export default PasswordResetPage;
