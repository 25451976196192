import createUserFromVerify from './createUserFromVerify';
import getAllVulnerabilities from './getAllVulnerabilities';
import getContacts from './getContacts';
import getCurrentBalance from './getCurrentBalance';
import getFlexibleText from './getFlexibleText';
import getFormContent from './getFormContent';
import getGenderOptions from './getGenderOptions';
import getHallnetUrl from './getHallnetUrl';
import getOnboardingScreens from './getOnboardingScreens';
import getPageContent from './getPageContent';
import getRentAccounts from './getRentAccounts';
import getRentStatement from './getRentStatement';
import getSettings from './getSettings';
import login from './login';
import logout from './logout';
import refresh from './refresh';
import registerNewAccount from './registerNewAccount';
import requestPasswordReset from './requestPasswordReset';
import resetPassword from './resetPassword';
import submitDynamicForm from './submitDynamicForm';
import tfaDismiss from './tfaDismiss';
import updateCorrespondenceDetails from './updateCorrespondenceDetails';
import updateHouseholdContactDetails from './updateHouseholdContactDetails';
import updatePassword from './updatePassword';
import updateTFA from './updateTFA';
import updateUniversalCredit from './updateUniversalCredit';
import updateUser from './updateUser';
import updateUserContactDetails from './updateUserContactDetails';
import updateUsername from './updateUsername';
import updateUserVulnerabilities from './updateUserVulnerabilities';
import verifyUser from './verifyUser';
import verifyUserDetails from './verifyUserDetails';

const api = {
  getPageContent,
  getFormContent,
  getSettings,
  login,
  refresh,
  getOnboardingScreens,
  registerNewAccount,
  updatePassword,
  verifyUser,
  getRentStatement,
  getRentAccounts,
  getContacts,
  submitDynamicForm,
  resetPassword,
  requestPasswordReset,
  updateUsername,
  updateTFA,
  createUserFromVerify,
  getCurrentBalance,
  getFlexibleText,
  getHallnetUrl,
  updateUser,
  getAllVulnerabilities,
  updateUserVulnerabilities,
  updateCorrespondenceDetails,
  updateUserContactDetails,
  updateUniversalCredit,
  verifyUserDetails,
  updateHouseholdContactDetails,
  getGenderOptions,
  logout,
  tfaDismiss,
};

export default api;
