import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

function verifyUserDetails(user: any): Promise<AxiosResponse> {
  return axios.put(endpoints.user.updateUser, {
    emailAddress: user.email,
    nationalInsurance: user.nationalInsurance,
    phoneNumber: user.phoneNumber,
    mobileNumber: user.mobileNumber,
    UCValue: user.ucValue,
  });
}

export default verifyUserDetails;
