export interface ISettings {
  configuration: 'dev' | 'stage' | 'prod' | 'demo' | '';
  apiBaseUrl: string;
  bearerTokenKey: string;
  ocpApimSubscriptionKey: string;
  demoText: string;
  refreshTokenKey: string;
  passwordRegex: RegExp;
  userKey: string;
  allPayUrl: string;
}

const isDev = process.env.REACT_APP_CONFIGURATION === 'dev';
const isStage = process.env.REACT_APP_CONFIGURATION === 'stage';
const isProd = process.env.REACT_APP_CONFIGURATION === 'prod';
const isDemo = process.env.REACT_APP_CONFIGURATION === 'demo';

const settings: ISettings = {
  configuration: isDev
    ? 'dev'
    : isStage
    ? 'stage'
    : isDemo
    ? 'demo'
    : isProd
    ? 'prod'
    : '',

  apiBaseUrl: isDev
    ? 'https://jjh-apimanagement.azure-api.net/v1/api/'
    : isStage
    ? 'https://jjh-apimanagement.azure-api.net/v1/api/staging/'
    : isDemo
    ? 'https://jjh-apimanagement.azure-api.net/v1/api/demo/'
    : isProd
    ? 'https://jjh-apimanagement-prod.azure-api.net/v1/api/'
    : 'INVALID_REACT_APP_CONFIGURATION',

  demoText: 'Please note: this is a demo version of the Johnnie Johnson Portal',

  ocpApimSubscriptionKey: isProd
    ? 'e7c4611997384a838508721eba76123b'
    : '437066219383402597302df18ba1ff99',

  bearerTokenKey: 'JJH_PORTAL:BEARER_TOKEN',
  userKey: 'JJH_PORTAL:USER',
  refreshTokenKey: 'JJH_PORTAL:REFRESH_TOKEN',
  passwordRegex:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,25}$/,
  allPayUrl:
    'https://pay.allpay.net/johnnie/reference/create/?merchantId=30030518&schemecode=JJHT',
};

export default settings;
