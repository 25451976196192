import React from 'react';
import ReactTooltip from 'react-tooltip';

interface Props {}

const TooltipConfig: React.FC<Props> = ({}) => {
  return (
    <ReactTooltip
      backgroundColor='#ffffff'
      textColor='#252F60'
      html={true}
      className='jjhtooltip bg-white shadow-tooltip'
      place='right'
      event='click'
      eventOff='mouseleave'
      globalEventOff='touchstart'
    />
  );
};

export default TooltipConfig;
