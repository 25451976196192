import React, { useEffect, useState } from 'react';
import {
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import MainLayout from '../../layouts/MainLayout';
import ChangePassword from './components/ChangePassword';
import ChangeUsername from './components/ChangeUsername';
import classnames from 'classnames';
import Button from '../../button';
import SelectInput, { OptionType } from '../../components/input/SelectInput';
import UserDetails from './components/UserDetails';
import ContactDetails from './components/ContactDetails';
import CorrespondenceDetails from './components/CorrespondenceDetails';
import Vulnerabilities from './components/Vulnerabilities';
import User from '../../types/User';
import api from '../../api';
import { useAuth } from '../../state/AuthProvider';
import HouseholdContactUpdate from './components/HouseholdContactUpdate';
import { PulseLoader } from 'react-spinners';
import HouseholdMembers from './components/HouseholdMembers';

interface RouteProps {
  page: string;
}

const DropdownOptions = [
  {
    label: 'User Details',
    value: '/account/',
  },
  {
    label: 'Contact Details',
    value: '/account/contactDetails',
  },
  {
    label: 'Household Members',
    value: '/account/household-members',
  },
  {
    label: 'Correspondence Details',
    value: '/account/correspondenceDetails',
  },
  {
    label: 'Vulnerabilities',
    value: '/account/vulnerabilities',
  },

  {
    label: 'Change Password',
    value: '/account/updatePassword',
  },
  {
    label: 'Change Username',
    value: '/account/updateUsername',
  },
];
const DropdownOptionsHouseholdUser = [
  {
    label: 'User Details',
    value: '/account/',
  },

  {
    label: 'Correspondence Details',
    value: '/account/correspondenceDetails',
  },
];

const AccountPage: React.FC = () => {
  const location = useLocation();
  const { push } = useHistory();
  const { user } = useAuth();
  const [selectedForm, setSelectedForm] = useState<OptionType>(() => {
    const selectedFormItem = location
      ? DropdownOptions.find((option) => option.value === location.pathname)
      : undefined;
    return selectedFormItem || DropdownOptions[0];
  });
  const match = useRouteMatch<RouteProps>('/account/:page');
  const [isLoading, setIsLoading] = useState(false);

  const [contacts, setContacts] = useState<User[]>([]);
  const [selectedContact, setSelectedContact] = useState<OptionType>();
  const isLoggedInUser =
    selectedContact?.value === user?.id.toString() || false;
  const userAge =
    new Date(
      new Date().getTime() -
        new Date(
          contacts.find((u) => u.id.toString() === selectedContact?.value)
            ?.dateOfBirth || ''
        ).getTime()
    ).getFullYear() - 1970;
  const isUserUnder16 = userAge < 16;

  useEffect(() => {
    let isComponentMounted = true;
    setIsLoading(true);
    (async function () {
      try {
        if (!user) {
          return;
        }
        const contactsResponse = await api.getContacts();

        if (contactsResponse.data.length > 0) {
          if (isComponentMounted) {
            const options = contactsResponse.data
              .sort((c) => (c.isMain ? -1 : 1))
              .map((a) => {
                return {
                  label: [
                    a.forename,
                    a.middlename,
                    a.surname,
                    a.isMain ? '(Primary)' : '',
                  ]
                    .filter((s) => !!s)
                    .join(' '),

                  value: a.id.toString(),
                };
              });
            setContacts(contactsResponse.data);

            setSelectedContact(
              options.find((u) => u.value === user.id.toString())
            );
          }
        }
        if (isComponentMounted) {
          setIsLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  return (
    <MainLayout>
      <div className='flex flex-col flex-1'>
        <div className='w-full mb-4 lg:mb-0'>
          <div className='h1 mb-4'>Update your profile</div>
          <div className='lg:w-1/2'>
            If you are looking to update personal information such as name and
            address details please call our Customer Services Hub on 0345 305
            5335.
          </div>
        </div>
        {isLoading ? (
          <div className='pt-4 flex justify-center'>
            <PulseLoader color='#D1D1D1' size='16px' />
          </div>
        ) : (
          <>
            <div className='lg:flex hidden '>
              {isLoggedInUser ? (
                <>
                  <Button
                    onClick={() => push('/account')}
                    className={classnames(
                      'btn-tertiary mr-4 my-0 md:my-4 h-14 xl:h-7 no-underline border-b-2 rounded-none font-semibold',
                      {
                        'border-blue': !match?.params.page,
                        'border-transparent': match?.params.page,
                      }
                    )}
                  >
                    Details
                  </Button>
                  <Button
                    onClick={() => push('/account/contactDetails')}
                    className={classnames(
                      'btn-tertiary mr-4 my-0 md:my-4 h-14 xl:h-7 no-underline border-b-2 rounded-none font-semibold',
                      {
                        'border-blue': match?.params.page === 'contactDetails',
                        'border-transparent':
                          match?.params.page !== 'contactDetails',
                      }
                    )}
                  >
                    Contact Details
                  </Button>
                  <Button
                    onClick={() => push('/account/household-members')}
                    className={classnames(
                      'btn-tertiary mr-4 my-0 md:my-4 h-14 xl:h-7 no-underline border-b-2 rounded-none font-semibold',
                      {
                        'border-blue':
                          match?.params.page === 'household-members',
                        'border-transparent':
                          match?.params.page !== 'household-members',
                      }
                    )}
                  >
                    Household Members
                  </Button>
                  <Button
                    onClick={() => push('/account/correspondenceDetails')}
                    className={classnames(
                      'btn-tertiary mr-4 my-0 md:my-4 h-14 xl:h-7 no-underline border-b-2 rounded-none font-semibold',
                      {
                        'border-blue':
                          match?.params.page === 'correspondenceDetails',
                        'border-transparent':
                          match?.params.page !== 'correspondenceDetails',
                      }
                    )}
                  >
                    Correspondence Details
                  </Button>
                  <Button
                    onClick={() => push('/account/vulnerabilities')}
                    className={classnames(
                      'btn-tertiary mr-4 my-0 md:my-4 h-14 xl:h-7 no-underline border-b-2 rounded-none font-semibold',
                      {
                        'border-blue': match?.params.page === 'vulnerabilities',
                        'border-transparent':
                          match?.params.page !== 'vulnerabilities',
                      }
                    )}
                  >
                    Vulnerabilities
                  </Button>
                  <Button
                    onClick={() => push('/account/updatePassword')}
                    className={classnames(
                      'btn-tertiary mr-4 my-0 md:my-4 h-14 xl:h-7 no-underline border-b-2 rounded-none font-semibold',
                      {
                        'border-blue': match?.params.page === 'updatePassword',
                        'border-transparent':
                          match?.params.page !== 'updatePassword',
                      }
                    )}
                  >
                    Change Password
                  </Button>
                  <Button
                    onClick={() => push('/account/updateUsername')}
                    className={classnames(
                      'btn-tertiary mr-4 my-0 md:my-4 h-14 xl:h-7 no-underline border-b-2 rounded-none font-semibold',
                      {
                        'border-blue': match?.params.page === 'updateUsername',
                        'border-transparent':
                          match?.params.page !== 'updateUsername',
                      }
                    )}
                  >
                    Change Username
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => push('/account')}
                    className={classnames(
                      'btn-tertiary mr-4 my-0 md:my-4 h-14 xl:h-7 no-underline border-b-2 rounded-none font-semibold',
                      {
                        'border-blue': !match?.params.page,
                        'border-transparent': match?.params.page,
                      }
                    )}
                  >
                    Details
                  </Button>
                  {!isUserUnder16 && (
                    <Button
                      onClick={() => push('/account/correspondenceDetails')}
                      className={classnames(
                        'btn-tertiary mr-4 my-0 md:my-4 h-14 xl:h-7 no-underline border-b-2 rounded-none font-semibold',
                        {
                          'border-blue':
                            match?.params.page === 'correspondenceDetails',
                          'border-transparent':
                            match?.params.page !== 'correspondenceDetails',
                        }
                      )}
                    >
                      Correspondence Details
                    </Button>
                  )}
                </>
              )}
            </div>

            <div className='lg:hidden mb-4'>
              <SelectInput
                options={
                  isLoggedInUser
                    ? DropdownOptions
                    : DropdownOptionsHouseholdUser
                }
                onChange={(e) => {
                  setSelectedForm(e);
                  push(e.value);
                }}
                inputClassName='bg-white'
                optionsClassName='bg-white'
                selectedOption={selectedForm}
              />
            </div>

            <Switch>
              {isLoggedInUser ? (
                <>
                  <PrivateRoute exact={true} path='/account/updatePassword'>
                    <ChangePassword />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path='/account/updateUsername'>
                    <ChangeUsername />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path='/account/contactDetails'>
                    <ContactDetails />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path='/account/household-members'>
                    <HouseholdMembers contacts={contacts} />
                  </PrivateRoute>
                  <PrivateRoute
                    exact={true}
                    path='/account/correspondenceDetails'
                  >
                    <CorrespondenceDetails />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path='/account/vulnerabilities'>
                    <Vulnerabilities />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path='/account'>
                    <UserDetails />
                  </PrivateRoute>
                </>
              ) : (
                <>
                  <PrivateRoute exact={true} path='/account'>
                    <HouseholdContactUpdate
                      user={contacts.find(
                        (u) => u.id.toString() === selectedContact?.value
                      )}
                    />
                  </PrivateRoute>
                  <PrivateRoute
                    exact={true}
                    path='/account/correspondenceDetails'
                  >
                    <CorrespondenceDetails />
                  </PrivateRoute>
                </>
              )}
            </Switch>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default AccountPage;
