import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import api from '../api';
import MainLayout from '../layouts/MainLayout';
import AccountPage from '../pages/AccountPage/AccountPage';
import ComponentPage from '../pages/ComponentPage/ComponentPage';
import FlexiblePage from '../pages/FlexiblePage/FlexiblePage';
import HomePage from '../pages/HomePage';
import LoadingPage from '../pages/LoadingPage';
import LoginPage from '../pages/LoginPage';
import OnboardingPage from '../pages/OnboardingPage';
import RequestPasswordResetPage from '../pages/RequestPasswordResetPage';
import PaymentPage from '../pages/PaymentPage';
import RegisterPage from '../pages/RegisterPage';
import TalkToUsPage from '../pages/TalkToUsPage';
import ViewRentStatementPage from '../pages/ViewRentStatementPage';
import { useAuth } from '../state/AuthProvider';
import { useSettings } from '../state/SettingsProvider';
import PrivateRoute from './PrivateRoute';
import PasswordResetPage from '../pages/PasswordResetPage/PasswordResetPage';
import VerifyUserPage from '../pages/VerifyUserPage';
import MaintenanceAndRepairsPage from '../pages/MaintenanceAndRepairsPage';
import VerifyDetailsPage from '../pages/VerifyDetailsPage.tsx/VerifyDetailsPage';
import TenancyPage from '../pages/TenancyPage';
import EndTenancyPage from '../pages/EndTenancyPage';
import AstralinePage from '../pages/AstralinePage';

const Router: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, user } = useAuth();
  const { setSocialLinks, setMenuItems } = useSettings();
  const [pageReferences, setPageReferences] = useState<any[]>([]);
  useEffect(() => {
    let isComponentMounted = true;
    (async function () {
      if (isComponentMounted && isAuthenticated) {
        setIsLoading(true);
        api
          .getSettings()
          .then(({ data: { menu, social, pages } }) => {
            setMenuItems(menu);
            setSocialLinks(social);
            setPageReferences(pages);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, [isAuthenticated]);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path='/onboarding'>
          <OnboardingPage />
        </PrivateRoute>
        <Route path='/components'>
          <ComponentPage />
        </Route>
        <Route path='/login'>
          <LoginPage />
        </Route>
        <PrivateRoute path='/verifyDetails'>
          <VerifyDetailsPage />
        </PrivateRoute>
        <Route path='/register'>
          <RegisterPage />
        </Route>
        <Route exact={true} path='/payments'>
          <PaymentPage />
        </Route>
        <Route exact={true} path='/payments/rent-statement'>
          <ViewRentStatementPage />
        </Route>
        <Route path='/requestPasswordReset'>
          <RequestPasswordResetPage />
        </Route>
        <Route path='/resetPassword'>
          <PasswordResetPage />
        </Route>
        <Route path='/verifyUser'>
          <VerifyUserPage />
        </Route>
        <PrivateRoute path='/account'>
          <AccountPage />
        </PrivateRoute>

        <PrivateRoute requiresFullAccess={true} path='/talk-to-us'>
          <TalkToUsPage />
        </PrivateRoute>

        <PrivateRoute
          requiresFullAccess={true}
          exact={true}
          path='/maintenance-repairs'
        >
          <MaintenanceAndRepairsPage />
        </PrivateRoute>

        <PrivateRoute requiresFullAccess={true} exact path='/tenancy'>
          <TenancyPage />
        </PrivateRoute>
        <PrivateRoute
          requiresFullAccess={true}
          exact
          path='/tenancy/end-tenancy'
        >
          <EndTenancyPage />
        </PrivateRoute>

        <PrivateRoute exact path='/astraline'>
          <AstralinePage />
        </PrivateRoute>

        {pageReferences.map((item, i) => {
          const key = `PageReferenceRoute_${i}`;
          if (
            (item.url === '/maintenance-repairs/book-a-repair' ||
              item.url === '/maintenance-repairs/track-a-repair') &&
            user?.isSharedOwner
          ) {
            return (
              <PrivateRoute key={key} exact={true} path={item.url}>
                <Redirect to='/maintenance-repairs' />;
              </PrivateRoute>
            );
          }

          return (
            item.id && (
              <PrivateRoute key={key} exact={true} path={item.url}>
                <MainLayout>
                  <FlexiblePage pageId={item.id} />
                </MainLayout>
              </PrivateRoute>
            )
          );
        })}

        <PrivateRoute path='/'>
          <HomePage />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
