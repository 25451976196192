import * as Yup from 'yup';
import { CheckboxField } from '../../../types/FlexibleForm';
import handleConditionalLogicValidationSchema from './handleConditionalLogicValidationSchema';

function createSchema(field: CheckboxField) {
  let schema = Yup.string();

  const options = field.options;

  if (field.required) {
    schema = schema.test(
      field.name,
      'Please tick at least one box',
      function (value) {
        for (const option of options) {
          if (this.parent[option.name]) {
            return true;
          }
        }

        return false;
      }
    );
  }

  return schema;
}

export default function createValidationSchemaCheckboxField(
  field: CheckboxField
): Yup.StringSchema<any> {
  if (!field.conditional_logic) {
    return createSchema(field);
  }

  const { rules } = field.conditional_logic;
  const ruleFieldNames = rules.map((rule) => rule.fieldName);
  return Yup.string().when(ruleFieldNames, {
    is: (...values: any[]) => {
      const normalizedValues = values.map((value) => value || '');
      return handleConditionalLogicValidationSchema(
        field.conditional_logic,
        normalizedValues
      );
    },
    then: createSchema(field),
  });
}
