import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

function updateTFA(
  enable: boolean,
  code?: string,
  useEmail?: boolean
): Promise<AxiosResponse> {
  const data = { enable, code, useEmail };
  return axios.post(endpoints.user.updateTFA, data);
}

export default updateTFA;
