import React from 'react';
import { FieldProps } from 'formik';
import SelectInput from '../../../input/SelectInput';
import { SelectField } from '../../../../types/FlexibleForm';

const FlexibleSelectInput: React.FC<FieldProps & SelectField> = ({
  field,
  meta,
  label,
  tooltip,
  options,
  form,
  ...rest
}) => {
  const getSelectedOption = () => {
    const selected = options.find((o) => o.value && o.value === field.value);
    return selected
      ? { label: selected.label, value: selected.value || selected.label }
      : undefined;
  };
  return (
    <SelectInput
      {...rest}
      {...field}
      onChange={(option) => form.setFieldValue(field.name, option.value)}
      options={options.map((o) => {
        return {
          label: o.label,
          value: o.value || o.label,
        };
      })}
      label={label}
      selectedOption={getSelectedOption()}
      errorMessage={
        (form.touched[field.name]?.valueOf() &&
          form.errors[field.name]?.toString()) ||
        undefined
      }
      tooltip={tooltip || ''}
    />
  );
};

export default FlexibleSelectInput;
