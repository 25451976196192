import { FormikHelpers } from 'formik';
import React, { useEffect } from 'react';

interface Props {
  setFieldTouched: FormikHelpers<any>['setFieldTouched'];
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  fieldName: string;
  value: string;
  label: string;
}

const FlexibleConsentInput: React.FC<Props> = ({
  setFieldValue,
  fieldName,
  label,
  value,
}) => {
  useEffect(() => {
    // Allows conditional validation to work for Consent fields.

    // While the Gravity Forms API expects form field names in the format of

    // input_X_1 when submitting forms, it expects them in the format of input_X

    // (parentFieldName) for the purpose of conditional logic.

    const tmp = fieldName.split('_');

    const parentFieldName = `${tmp[0]}_${tmp[1]}`;

    setFieldValue(parentFieldName, value);
  }, [fieldName, value]);
  return (
    <div
      className='flex flex-row  mb-2 cursor-pointer'
      onClick={() => {
        setFieldValue(fieldName, value === '1' ? '' : '1');
      }}
    >
      <div className='flex items-center'>
        <div className='bg-white border-2 flex border-blue h-5 items-center justify-center mr-4 rounded-md w-5'>
          {value === '1' && (
            <div className='bg-blue h-3 rounded-full w-3'></div>
          )}
        </div>
      </div>
      <div className=''>
        <p className='p'>{label}</p>
      </div>
    </div>
  );
};

export default FlexibleConsentInput;
