enum LoginActions {
  'LoggedIn' = 'LoggedIn',
  'FirstLogin' = 'FirstLogin',
  'NoAccount' = 'NoAccount',
  'LockedOut' = 'LockedOut',
  'AccountDisabled' = 'AccountDisabled',
  'IncorrectDetails' = 'IncorrectDetails',
  'ForcePasswordChange' = 'ForcePasswordChange',
  'PreviousUser' = 'PreviousUser',
  'TFA' = 'TFA',
}

export default LoginActions;
