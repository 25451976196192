import React from 'react';
import loginSquiggle from '../assets/images/login-squiggle.svg';
import './LoginLayout.css';
import logo from '../assets/images/logo.svg';

interface LoginLayoutProps {
  rightSideContent?: JSX.Element | undefined;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({
  children,
  rightSideContent,
}) => {
  return (
    <div className='loginLayout flex flex-row flex-wrap grow min-h-full bg-lightBlue'>
      <div className='w-full lg:w-6/12'>
        <div className='mx-10 lg:mx-20 flex flex-col min-h-screen'>
          <div className='pt-4 grow-0 flex'>
            <img className='w-48 h-auto' src={logo} alt='Logo' />
          </div>
          <div className='flex flex-col flex-grow justify-center py-16'>
            {children}
          </div>
        </div>
      </div>

      {rightSideContent ? (
        <div
          className='lg:w-6/12 lg:block xl:w-6/12 lg:fixed lg:right-0 h-auto lg:h-screen xl:overflow-y-auto bg-blue'
          style={{
            paddingRight: '12px',
            marginRight: '-15px',
          }}
        >
          <img
            src={loginSquiggle}
            alt=''
            className='h-screen hidden lg:block lg:fixed top-0 left-1/2 bottom-0'
          />
          {rightSideContent}
        </div>
      ) : (
        <div className='hidden lg:block lg:fixed lg:w-6/12 xl:w-6/12 lg:right-0 h-screen homeBackgroundImage'>
          <img src={loginSquiggle} alt='' className='h-screen' />
        </div>
      )}
    </div>
  );
};

export default LoginLayout;
