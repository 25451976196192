import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

export interface RentAccount {
  accountId: number;
  isMain: boolean;
  address: string;
  startDate: Date;
  endDate?: Date;
  subAccounts: SubAccount[];
  assetId: number;
}

interface SubAccount {
  postingCode: string;
  isMainAccount: boolean;
  name: string;
  balance: number;
}

function getRentAccounts(): Promise<AxiosResponse<RentAccount[]>> {
  return axios.get(endpoints.user.getRentAccounts);
}

export default getRentAccounts;
