import React from 'react';

interface Props {
  image: any;
}
const ImageCarouselItem: React.FC<Props> = ({ image }) => {
  return (
    <div className=' h-full rounded-md  lg:rounded-lg '>
      <img src={image} className='h-full w-full rounded-md  lg:rounded-lg' />
    </div>
  );
};

export default ImageCarouselItem;
