import classNames from 'classnames';
import React, { useState } from 'react';
import { Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Button from '../../button';
import FlexibleForm from '../../components/flexible/FlexibleForm/FlexibleForm';
import IconWithText from '../../components/flexible/IconWithText';
import SelectInput, { OptionType } from '../../components/input/SelectInput';
import PrivateRoute from '../../components/PrivateRoute';
import MainLayout from '../../layouts/MainLayout';
import { usePolyglot } from '../../state/PolyglotProvider';

interface RouteProps {
  page: string;
}

const DropdownOptions = [
  {
    label: 'General Enquiry',
    value: '/talk-to-us/general-enquiry',
  },
  {
    label: 'Antisocial Behaviour',
    value: '/talk-to-us/anti-social-behaviour',
  },
  {
    label: 'Compliments',
    value: '/talk-to-us/compliments',
  },
  {
    label: 'Complaints',
    value: '/talk-to-us/complaints',
  },
];

const TalkToUsPage: React.FC = () => {
  const { push } = useHistory();
  const match = useRouteMatch<RouteProps>('/talk-to-us/:page');
  const [selectedForm, setSelectedForm] = useState<OptionType>(
    DropdownOptions[0]
  );
  const { polyglot } = usePolyglot();

  return (
    <MainLayout>
      <div className='flex flex-col flex-1'>
        <div className='w-full mb-4'>
          <div className='h1 mb-4'>Talk to us</div>
          <div className='lg:w-2/3 mb-4 lg:mb-0 '>
            {polyglot?.t('pages.talk_to_us.introduction')}
          </div>
        </div>
        <div className='hidden lg:flex-row lg:flex items-center mb-4 '>
          <Button
            onClick={() => push('/talk-to-us/general-enquiry')}
            className={classNames(
              'btn-tertiary mr-4 my-0 md:my-4 h-7 no-underline border-b-2 rounded-none font-semibold',
              {
                'border-blue': match?.params.page === 'general-enquiry',
                'border-transparent': match?.params.page !== 'general-enquiry',
              }
            )}
          >
            General Enquiry
          </Button>
          <Button
            onClick={() => push('/talk-to-us/anti-social-behaviour')}
            className={classNames(
              'btn-tertiary mr-4 my-0 md:my-4 h-7 no-underline border-b-2 rounded-none font-semibold',
              {
                'border-blue': match?.params.page === 'anti-social-behaviour',
                'border-transparent':
                  match?.params.page !== 'anti-social-behaviour',
              }
            )}
          >
            Anti Social Behaviour
          </Button>
          <Button
            onClick={() => push('/talk-to-us/compliments')}
            className={classNames(
              'btn-tertiary mr-4 my-0 md:my-4 h-7 no-underline border-b-2 rounded-none font-semibold',
              {
                'border-blue': match?.params.page === 'compliments',
                'border-transparent': match?.params.page !== 'compliments',
              }
            )}
          >
            Compliments
          </Button>
          <Button
            onClick={() => push('/talk-to-us/complaints')}
            className={classNames(
              'btn-tertiary mr-4 my-0 md:my-4 h-7 no-underline border-b-2 rounded-none font-semibold',
              {
                'border-blue': match?.params.page === 'complaints',
                'border-transparent': match?.params.page !== 'complaints',
              }
            )}
          >
            Complaints
          </Button>
        </div>
        <div className='lg:hidden mb-4'>
          <SelectInput
            options={DropdownOptions}
            label='Select a form'
            onChange={(e) => {
              setSelectedForm(e);
              push(e.value);
            }}
            inputClassName='bg-white'
            optionsClassName='bg-white'
            selectedOption={selectedForm}
          />
        </div>
        <Switch>
          <PrivateRoute exact path='/talk-to-us/general-enquiry'>
            <div className='flex lg:flex-row flex-col mb-4'>
              <FlexibleForm
                formId='12'
                title='Send an online enquiry'
                description="Fill out the contact form below and we'll get back to you as soon as possible."
              />
              <div className='lg:w-1/3 lg:ml-4 mt-4 lg:mt-0'>
                {polyglot?.t(
                  'pages.talk_to_us.general_enquiry_card_1_title'
                ) && (
                  <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
                    <div className='h2 text-white'>
                      {polyglot?.t(
                        'pages.talk_to_us.general_enquiry_card_1_title'
                      )}
                    </div>
                    <div className='py-4 text-white'>
                      {polyglot?.t(
                        'pages.talk_to_us.general_enquiry_card_1_introduction'
                      )}
                    </div>
                    <a
                      href={`tel:${polyglot?.t(
                        'general.support_telephone_number'
                      )}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <IconWithText
                        acf_fc_layout='icon_with_text'
                        icon='phone'
                        text={`Call: ${polyglot?.t(
                          'general.support_telephone_number'
                        )}`}
                        color='white'
                      />
                    </a>
                  </div>
                )}
                {polyglot?.t(
                  'pages.talk_to_us.general_enquiry_card_2_title'
                ) && (
                  <div className='bg-white flex flex-col  rounded-lg p-4'>
                    <div className='h2'>
                      {polyglot?.t(
                        'pages.talk_to_us.general_enquiry_card_2_title'
                      )}
                    </div>
                    <div className='py-4 text-blue'>
                      {polyglot?.t(
                        'pages.talk_to_us.general_enquiry_card_2_introduction'
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path='/talk-to-us/anti-social-behaviour'>
            <div className='flex lg:flex-row flex-col'>
              <FlexibleForm
                formId='13'
                title='Report Antisocial Behaviour '
                description="Fill out the contact form below and we'll get back to you as soon as possible."
              />
              <div className='lg:w-1/3 lg:ml-4 mt-4 lg:mt-0'>
                {polyglot?.t('pages.talk_to_us.asb_card_1_title') && (
                  <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
                    <div className='h2 text-white'>
                      {polyglot?.t('pages.talk_to_us.asb_card_1_title')}
                    </div>
                    <div className='py-4 text-white'>
                      {polyglot?.t('pages.talk_to_us.asb_card_1_introduction')}
                    </div>
                    <a
                      href={`tel:${polyglot?.t(
                        'general.support_telephone_number'
                      )}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <IconWithText
                        acf_fc_layout='icon_with_text'
                        icon='phone'
                        text={`Call: ${polyglot?.t(
                          'general.support_telephone_number'
                        )}`}
                        color='white'
                      />
                    </a>
                  </div>
                )}
                {polyglot?.t('pages.talk_to_us.asb_card_2_title') && (
                  <div className='bg-white flex flex-col  rounded-lg p-4'>
                    <div className='h2'>
                      {polyglot?.t('pages.talk_to_us.asb_card_2_title')}
                    </div>
                    <div className='py-4 text-blue'>
                      {polyglot?.t('pages.talk_to_us.asb_card_2_introduction')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path='/talk-to-us/compliments'>
            <div className='flex lg:flex-row flex-col'>
              <FlexibleForm
                formId='7'
                title='Give us a Compliment'
                description="Fill out the contact form below and we'll get back to you as soon as possible."
              />
              <div className='lg:w-1/3 lg:ml-4 mt-4 lg:mt-0'>
                {polyglot?.t('pages.talk_to_us.compliments_card_1_title') && (
                  <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
                    <div className='h2 text-white'>
                      {polyglot?.t('pages.talk_to_us.compliments_card_1_title')}
                    </div>
                    <div className='py-4 text-white'>
                      {polyglot?.t(
                        'pages.talk_to_us.compliments_card_1_introduction'
                      )}
                    </div>
                    <a
                      href={`tel:${polyglot?.t(
                        'general.support_telephone_number'
                      )}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <IconWithText
                        acf_fc_layout='icon_with_text'
                        icon='phone'
                        color='white'
                        text={`Call: ${polyglot?.t(
                          'general.support_telephone_number'
                        )}`}
                      />
                    </a>
                  </div>
                )}
                {polyglot?.t('pages.talk_to_us.compliments_card_2_title') && (
                  <div className='bg-white flex flex-col  rounded-lg p-4'>
                    <div className='h2'>
                      {polyglot?.t('pages.talk_to_us.compliments_card_2_title')}
                    </div>
                    <div className='py-4 text-blue'>
                      {polyglot?.t(
                        'pages.talk_to_us.compliments_card_2_introduction'
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </PrivateRoute>
          <PrivateRoute exact path='/talk-to-us/complaints'>
            <div className='flex lg:flex-row flex-col'>
              <FlexibleForm
                formId='18'
                title='Have a Complaint?'
                description="Fill out the contact form below and we'll get back to you as soon as possible."
              />
              <div className='lg:w-1/3 lg:ml-4'>
                {polyglot?.t('pages.talk_to_us.complaints_card_1_title') && (
                  <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
                    <div className='h2 text-white'>
                      {polyglot?.t('pages.talk_to_us.complaints_card_1_title')}
                    </div>
                    <div className='py-4 text-white'>
                      {polyglot?.t(
                        'pages.talk_to_us.complaints_card_1_introduction'
                      )}
                    </div>
                    <a
                      href={`tel:${polyglot?.t(
                        'general.support_telephone_number'
                      )}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <IconWithText
                        acf_fc_layout='icon_with_text'
                        icon='phone'
                        text={`Call: ${polyglot?.t(
                          'general.support_telephone_number'
                        )}`}
                        color='white'
                      />
                    </a>
                  </div>
                )}
                {polyglot?.t('pages.talk_to_us.complaints_card_2_title') && (
                  <div className='bg-white flex flex-col  rounded-lg p-4'>
                    <div className='h2'>
                      {polyglot?.t('pages.talk_to_us.complaints_card_2_title')}
                    </div>
                    <div className='py-4 text-blue'>
                      {polyglot?.t(
                        'pages.talk_to_us.complaints_card_2_introduction'
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </PrivateRoute>

          <Redirect path='/talk-to-us' to='/talk-to-us/general-enquiry' />
        </Switch>
      </div>
    </MainLayout>
  );
};

export default TalkToUsPage;
