import {
  ConditionalLogic,
  ConditionalLogicRule,
} from '../../../types/FlexibleForm';
import handleRule from './handleRule';

function all(rules: ConditionalLogicRule[], values: any) {
  for (let i = 0; i < rules.length; i++) {
    if (!handleRule(rules[i], values[i])) {
      return false;
    }
  }

  return true;
}

function any(rules: ConditionalLogicRule[], values: any) {
  for (let i = 0; i < rules.length; i++) {
    if (handleRule(rules[i], values[i])) {
      return true;
    }
  }

  return false;
}

export default function handleConditionalLogicValidationSchema(
  conditionalLogic: ConditionalLogic | null,
  normalizedValues: any
) {
  if (!conditionalLogic) {
    return true;
  }

  const { action, logic, rules } = conditionalLogic;

  if (logic === 'all') {
    if (all(rules, normalizedValues)) {
      return action === 'show';
    } else {
      return action === 'hide';
    }
  } else {
    if (any(rules, normalizedValues)) {
      return action === 'show';
    } else {
      return action === 'hide';
    }
  }
}
