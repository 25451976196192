import { ConditionalLogicRule } from '../../../types/FlexibleForm';

export default function handleRule(
  rule: ConditionalLogicRule,
  fieldValue: any
) {
  switch (rule.operator) {
    case 'is':
      return fieldValue === rule.value;
    case 'isnot':
      return fieldValue !== rule.value;
    case '>':
      if (!parseInt(fieldValue) || !parseInt(rule.value)) {
        // At least one value is NaN.
        return false;
      }
      return fieldValue > parseInt(rule.value);
    case '<':
      if (!parseInt(fieldValue) || !parseInt(rule.value)) {
        // At least one value is NaN.
        return false;
      }
      return fieldValue < parseInt(rule.value);
    case 'contains':
      return fieldValue.toString().includes(rule.value.toString());
    case 'starts_with':
      const regexStartsWith = new RegExp(`^${rule.value}`);
      return fieldValue.match(regexStartsWith) !== null;
    case 'ends_with':
      const regexEndsWith = new RegExp(`${rule.value}$`);
      return fieldValue.match(regexEndsWith) !== null;
  }
}
