import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';
import settings from '../config/settings';
import LoginActions from '../types/LoginActions';

interface ResponseData {
  action: LoginActions;
  message: string;
  token: string;
  refreshToken: string;
}

function refresh(): Promise<AxiosResponse<ResponseData>> {
  const data = {
    token: sessionStorage.getItem(settings.bearerTokenKey),
    refreshToken: sessionStorage.getItem(settings.refreshTokenKey),
  };
  return axios.post(endpoints.user.refresh, data);
}

export default refresh;
