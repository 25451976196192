import React, { useState } from 'react';
import { ReactComponent as Play } from '../../../assets/images/play.svg';
import ViewVideoModal from '../../modals/ViewVideoModal';

interface Props {
  image: any;
  video: string;
}
const VideoCarouselItem: React.FC<Props> = ({ image, video }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  return (
    <div className='flex justify-center relative h-full '>
      <img
        src={image}
        className='w-full h-full rounded-md  lg:rounded-lg cursor-pointer'
        onClick={() => setIsVideoModalOpen(true)}
      />
      <div className='absolute flex w-full bottom-0 h-10 md:h-20 lg:h-24 items-center bg-blue px-5 rounded-b-md  lg:rounded-b-lg max-w-2xl'>
        <div
          className='rounded-full cursor-pointer bg-white h-5 w-5 md:h-10 md:w-10 lg:h-15.5 lg:w-15.5 flex items-center justify-center'
          onClick={() => setIsVideoModalOpen(true)}
        >
          <Play className='h-3 w-3 md:h-4 md:w-4 lg:h-5 lg:w-5 ml-1' />
        </div>
        <div className='pl-4 text-white font-medium'>Play Video</div>
      </div>
      <ViewVideoModal
        isOpen={isVideoModalOpen}
        setIsModalOpen={setIsVideoModalOpen}
        src={video}
        title='Johnnie Johnson Housing&#039;s 2020 Highlights'
      />
    </div>
  );
};

export default VideoCarouselItem;
