import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import closeMenuIcon from '../../assets/images/closeMenuIcon.svg';
import facebookIcon from '../../assets/images/facebookIcon.png';
import instagramIcon from '../../assets/images/instagramIcon.svg';
import linkedInIcon from '../../assets/images/linkedInIcon.svg';
import openMenuIcon from '../../assets/images/openMenuIcon.svg';
import profileIcon from '../../assets/images/profileIcon.svg';
import twitterIcon from '../../assets/images/twitterIcon.png';
import { useSidebar } from '../../state/SidebarProvider';
import SocialLinks from '../../types/SocialLinks';

interface Props {
  socialLinks?: SocialLinks;
  isMobileSearchOpen: boolean;
  setIsMobileSearchOpen: Dispatch<SetStateAction<boolean>>;
}
const Menu: React.FC<Props> = ({
  socialLinks,
  setIsMobileSearchOpen,
  isMobileSearchOpen,
}) => {
  const { isSidebarOpen, setIsSidebarOpen } = useSidebar();
  const { push } = useHistory();

  return (
    <div className='flex flex-row min-h-24 justify-between items-center px-7 lg:pl-0 lg:pr-10 z-30 bg-white  fixed w-screen lg:screenMinusSidebar  '>
      <div className='block lg:hidden'>
        {isSidebarOpen ? (
          <img
            src={closeMenuIcon}
            className='h-5 md:h-6'
            alt='close'
            onClick={() => setIsSidebarOpen(false)}
          />
        ) : (
          <img
            src={openMenuIcon}
            className='h-5 md:h-6'
            alt='open'
            onClick={() => {
              if (isMobileSearchOpen) {
                setIsMobileSearchOpen(false);
              }
              setIsSidebarOpen(true);
            }}
          />
        )}
      </div>
      <div className='lg:w-1/2 lg:block hidden '></div>
      <div className='flex-row flex lg:flex-1  justify-end h-8 items-center xl:pl-20'>
        <div className='lg:flex flex-row px-8 hidden '>
          <a href={socialLinks?.facebook} target='_blank' className='pr-2'>
            <img
              src={facebookIcon}
              className='min-h-6 max-h-6 min-w-6 cursor-pointer'
              alt='Facebook'
            />
          </a>
          <a href={socialLinks?.twitter} target='_blank' className='px-2 '>
            <img
              src={twitterIcon}
              className='min-h-6 max-h-6 min-w-6 cursor-pointer'
              alt='Twitter'
            />
          </a>
          <a href={socialLinks?.linkedin} target='_blank' className=' px-2'>
            <img
              src={linkedInIcon}
              className='min-h-6 max-h-6 min-w-6 cursor-pointer'
              alt='LinkedIn'
            />
          </a>
          <a href={socialLinks?.instagram} target='_blank' className='pl-2 '>
            <img
              src={instagramIcon}
              className='min-h-6 max-h-6 min-w-6 cursor-pointer'
              alt='Instagram'
            />
          </a>
        </div>

        <div
          onClick={() => {
            setIsSidebarOpen(false);
            push('/account');
          }}
          className='flex flex-row items-center pl-3 md:pl-4 cursor-pointer'
        >
          <img
            src={profileIcon}
            alt=''
            className='md:pr-2 min-h-7 max-h-7 min-w-7'
          />
          <span className='hidden lg:inline-block'>Your Profile</span>
        </div>
      </div>
    </div>
  );
};

export default Menu;
