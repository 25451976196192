import React from 'react';
import classnames from 'classnames';
interface Props {
  isActive?: boolean;
  isDisabled?: boolean;
}

const PaginationItem: React.FC<Props> = ({
  isActive,
  isDisabled,
  children,
}) => {
  return (
    <div
      className={classnames('mx-0.5 px-2.5 rounded  ', {
        'bg-white ': isActive,
      })}
    >
      <div
        className={classnames('font-semibold', {
          'text-lightBlue': isDisabled,
          'text-blue ': !isDisabled,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default PaginationItem;
