import React from 'react';
import image1 from '../../assets/images/logo.svg';
import radiator from '../../assets/images/radiator.png';
import Carousel from '../../components/carousel/Carousel';
import ImageCarouselItem from '../../components/carousel/imageCarouselItem/ImageCarouselItem';
import VideoCarouselItem from '../../components/carousel/videoCarouselItem/VideoCarouselItem';
import MainLayout from '../../layouts/MainLayout';

const ComponentPage: React.FC = () => {
  return (
    <MainLayout>
      <div className='w-2/3'>
        <Carousel>
          <ImageCarouselItem image={image1} />
          <VideoCarouselItem
            video={'https://www.youtube.com/embed/7PPDs8GVI3A?feature=oembed'}
            image={radiator}
          />
        </Carousel>
      </div>
    </MainLayout>
  );
};

export default ComponentPage;
