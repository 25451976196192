import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import api from '../api';
import LoadingPage from '../pages/LoadingPage';
import MenuObject from '../types/MenuObject';
import SocialLinks from '../types/SocialLinks';
interface SettingsProps {
  setSocialLinks: Dispatch<SetStateAction<SocialLinks>>;
  socialLinks?: SocialLinks;
  setMenuItems: Dispatch<SetStateAction<MenuObject[]>>;
  menuItems: MenuObject[];
  flexibleContent: Partial<FlexibleContent>;
}

interface FlexibleContent {
  [key: string]: unknown;
  pages: {
    [key: string]: unknown;
    login: {
      [key: string]: unknown;
      marketing_points: LandingPageMarketingPoint[];
    };
  };
}

export interface LandingPageMarketingPoint {
  title: string;
  description: string;
}

const SettingsContext = React.createContext({} as SettingsProps);

const useSettings = () => useContext(SettingsContext);

const SettingsProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [socialLinks, setSocialLinks] = useState<SocialLinks>({
    facebook: '',
    twitter: '',
    instagram: '',
    linkedin: '',
  });

  const [menuItems, setMenuItems] = useState<MenuObject[]>([]);

  const [flexibleContent, setFlexibleContent] = useState<FlexibleContent>(
    {} as FlexibleContent
  );

  useEffect(() => {
    let isComponentMounted = true;
    setIsLoading(true);
    (async function () {
      try {
        const flexibleTextResponse = await api.getFlexibleText();
        if (flexibleTextResponse.data) {
          if (isComponentMounted) {
            setFlexibleContent(flexibleTextResponse.data);
          }
        }
        if (isComponentMounted) {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        setMenuItems,
        menuItems,
        setSocialLinks,
        socialLinks,
        flexibleContent,
      }}
    >
      {isLoading ? <LoadingPage /> : children}
    </SettingsContext.Provider>
  );
};

export { useSettings };
export default SettingsProvider;
