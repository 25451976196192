import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

function updateUniversalCredit(
  isUniversalCreditClaimant: boolean
): Promise<AxiosResponse> {
  return axios.put(endpoints.user.updateUniversalCredit, {
    value: isUniversalCreditClaimant,
  });
}

export default updateUniversalCredit;
