import React from 'react';
import Fieldset from '../fieldset';
import { ReactComponent as FileUploadIcon } from '../../assets/images/fileUploadIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
interface Props {
  errorMessage?: string;
  inputRef?: any;
  label: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: any;
  required?: boolean;
  className?: string;
  inputClassName?: string;
  tooltip?: string;
  onFileRemove?: () => void;
  maxFiles?: number;
}

const FileInput: React.FC<Props> = ({
  className,
  errorMessage,
  inputRef,
  label,
  name,
  onChange,
  value,
  required,
  tooltip,
  inputClassName,
  onFileRemove,
}) => {
  var fullPath = value?.path;
  var filename = '';
  if (fullPath) {
    var startIndex =
      fullPath.indexOf('\\') >= 0
        ? fullPath.lastIndexOf('\\')
        : fullPath.lastIndexOf('/');
    filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1);
    }
  }
  return (
    <>
      <Fieldset
        className={className}
        label={label}
        required={required}
        errorMessage={errorMessage}
        tooltip={tooltip}
      >
        <div className=''>
          <div className='w-full'>
            <input
              onClick={(e) => {
                name && document.getElementById(name)?.click();
              }}
              placeholder='Click to upload'
              className={inputClassName}
            />
            <FileUploadIcon className='viewPasswordIcon -mt-9' />
          </div>

          <input
            className='hidden'
            id={name}
            name={name}
            type='file'
            accept='image/*'
            onChange={(e) => onChange && onChange(e)}
            ref={inputRef}
          />
        </div>
      </Fieldset>
      {value && (
        <div
          onClick={() => onFileRemove && onFileRemove()}
          className='border-blue border-b my-4'
        >
          <span className='mr-5 font-medium'>{filename}</span>
          <span>
            {value.file.size && (value.file.size / 1024).toFixed(0)} kb
          </span>
          <CloseIcon className='absoulte float-right cursor-pointer' />
        </div>
      )}
    </>
  );
};

export default FileInput;
