import React, { useRef } from 'react';
import Slider from 'react-slick';
import { ReactComponent as Arrow } from '../../assets/images/icon-arrow-white.svg';

const Carousel: React.FC = ({ children }) => {
  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const sliderRef = useRef<Slider>(null);

  return (
    <div className='max-w-2xl '>
      <div className=''>
        <Slider ref={sliderRef} {...settings}>
          {children}
        </Slider>
      </div>
      <div className='flex w-full justify-between pt-4'>
        <div
          className='rounded-full z-50 bg-blue h-7 w-7 md:h-10 md:w-10 lg:h-14 lg:w-14 flex justify-center items-center cursor-pointer '
          onClick={() => sliderRef.current?.slickPrev()}
        >
          <Arrow
            className='h-4 w-4 md:h-5 md:w-5 lg:h-6 lg:w-6'
            fill='#ffffff'
            color='#ffffff'
          />
        </div>
        <div
          className='rounded-full z-50 bg-blue h-7 w-7 md:h-10 md:w-10 lg:h-14 lg:w-14 flex justify-center items-center rotate-180 cursor-pointer'
          onClick={() => sliderRef.current?.slickNext()}
        >
          <Arrow
            className='h-4 w-4 md:h-5 md:w-5 lg:h-6 lg:w-6'
            fill='#ffffff'
            color='#ffffff'
          />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
