import * as Yup from 'yup';
import { AddressField } from '../../../types/FlexibleForm';
import handleConditionalLogicValidationSchema from './handleConditionalLogicValidationSchema';

function createSchema(field: AddressField) {
  let schema = Yup.string();

  const subfields = field.fields;

  if (field.required) {
    schema = schema.test(
      field.name,
      'Please fill all address fields',
      function (value) {
        for (const subfield of subfields) {
          if (
            !this.parent[subfield.name] &&
            !subfield.label.endsWith('Line 2')
          ) {
            return false;
          }
        }

        return true;
      }
    );
  }

  return schema;
}

export default function createValidationSchemaAddressField(
  field: AddressField
): Yup.StringSchema<any> {
  if (!field.conditional_logic) {
    return createSchema(field);
  }

  const { rules } = field.conditional_logic;
  const ruleFieldNames = rules.map((rule) => rule.fieldName);
  return Yup.string().when(ruleFieldNames, {
    is: (...values: any[]) => {
      const normalizedValues = values.map((value) => value || '');
      return handleConditionalLogicValidationSchema(
        field.conditional_logic,
        normalizedValues
      );
    },
    then: createSchema(field),
  });
}
