import React from 'react';
import Fieldset from '../fieldset/Fieldset';
import CharacterCountIndicator from './CharacterCountIndicator';

interface Props {
  errorMessage?: string;
  inputRef?: any;
  label: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;

  value: string;
  pattern?: string;
  maxLength?: number;
  required?: boolean;
  className?: string;
  inputClassName?: string;
  tooltip?: string;
  rows?: number;
}

const TextAreaInput: React.FC<Props> = ({
  className,
  errorMessage,
  inputRef,
  label,
  name,
  onChange,
  placeholder,
  value,
  maxLength,
  required,
  inputClassName,
  tooltip,
  rows = 3,
}) => {
  return (
    <Fieldset
      className={className}
      label={label}
      required={required}
      errorMessage={errorMessage}
      tooltip={tooltip}
    >
      <textarea
        maxLength={maxLength}
        id={`form-input-${label}`}
        className={` ${inputClassName} ${
          errorMessage && 'error'
        } pt-2 placeholder-placeholder`}
        placeholder={placeholder}
        defaultValue={value}
        onChange={(e) => onChange && onChange(e)}
        ref={inputRef}
        name={name}
        rows={rows}
        value={value}
      />

      {maxLength && (
        <CharacterCountIndicator max={maxLength} count={value?.length || 0} />
      )}
    </Fieldset>
  );
};

export default TextAreaInput;
