import { FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';

type Props = {
  setFieldTouched: FormikHelpers<any>['setFieldTouched'];
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  fieldName: string;
  value: string;
};

const FlexibleTimeInput: React.FC<Props> = ({
  setFieldTouched,
  setFieldValue,
  fieldName,
  value,
}) => {
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');

  useEffect(() => {
    setFieldTouched(fieldName);
    setFieldValue(
      fieldName,
      `${selectedHour.padStart(2, '0')}:${selectedMinute.padStart(2, '0')}`
    );
  }, [selectedHour, selectedMinute]);
  return (
    <div className='flex items-center -mx-1'>
      <div className='px-1 w-1/2'>
        <input
          onChange={(event) => {
            const value = parseInt(event.target.value);
            if (value > 23) {
              setSelectedHour('23');
              return;
            }
            if (value < 0) {
              setSelectedHour('0');
              return;
            }

            setSelectedHour(`${value < 10 ? `0${value}` : value}`);
          }}
          type='number'
          value={value.split(':')[0] || '00'}
          min='00'
          max='23'
          className='input bg-grey'
          inputMode='numeric'
          placeholder='00'
        />
      </div>
      <div>
        <p>:</p>
      </div>
      <div className='px-1 w-1/2'>
        <input
          onChange={(event) => {
            const value = parseInt(event.target.value);
            if (value > 45) {
              setSelectedHour('45');
              return;
            }
            if (value < 0) {
              setSelectedHour('0');
              return;
            }
            setSelectedMinute(`${value < 10 ? `0${value}` : value}`);
          }}
          type='number'
          value={value.split(':')[1] || '00'}
          min='00'
          max='45'
          step='15'
          className='input bg-grey'
          placeholder='00'
          inputMode='numeric'
        />
      </div>
    </div>
  );
};

export default FlexibleTimeInput;
