import React from 'react';
import TableRow from './TableRow';

interface Props {
  cells: (string | null)[];
  className?: string;
}

const TableHeader: React.FC<Props> = ({ cells, className }) => {
  return (
    <thead>
      <TableRow className={['text-left border-t-0', className || ''].join(' ')}>
        {cells.map((cell, i) => {
          return (
            <th
              scope='col'
              key={i}
              className='px-4 py-3 font-semibold text-blue'
            >
              {cell}
            </th>
          );
        })}
      </TableRow>
    </thead>
  );
};

export default TableHeader;
