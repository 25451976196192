import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import { PulseLoader } from 'react-spinners';
import api from '../../api';
import successIcon from '../../assets/images/successIcon.svg';
import warningIcon from '../../assets/images/warningIcon.svg';
import Button from '../../button';
import PasswordInput from '../../components/input/PasswordInput';
import PasswordStrengthIndicator from '../../components/input/PasswordStrengthIndicator';
import SuccessfulPasswordResetModal from '../../components/modals/SuccessfulPasswordResetModal';
import settings from '../../config/settings';
import LoginLayout from '../../layouts/LoginLayout';

const PasswordResetPage: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageIcon, setMessageIcon] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const regex = new RegExp(settings.passwordRegex);
  const resetToken = useLocation()
    .search.split('&')
    .reduce((a: Record<string, string>, b: string) => {
      const [key, val] = b.split('=');
      return {
        ...a,
        [key]: val,
      };
    }, {} as any)['?token'];

  const onSubmit = async () => {
    setIsSubmitting(true);
    if (newPassword !== confirmNewPassword) {
      setMessage('Passwords must match');
      setMessageIcon(warningIcon);
      setIsSubmitting(false);
      return;
    }

    if (!regex.test(newPassword)) {
      setMessage(
        'Passwords must contain between 8 and 25 characters with at least: one uppercase character, one lowercase character, one special character and one number.'
      );
      setMessageIcon(warningIcon);
      setIsSubmitting(false);
      return;
    }

    await api
      .resetPassword(newPassword, resetToken)
      .then(() => {
        setMessageIcon(successIcon);
        setMessage('Password updated successfully');
        setIsSubmitting(false);
        setNewPassword('');
        setConfirmNewPassword('');
        setIsModalOpen(true);
      })
      .catch(() => {
        setMessageIcon(warningIcon);
        setMessage(
          'Error setting password. If this problem persists please contact a system administrator.'
        );
        setIsSubmitting(false);
      });
  };

  if (!resetToken) {
    return <Redirect to='/login' />;
  }

  return (
    <LoginLayout>
      <h1 className='xl:w-84 my-4 lg:my-0 h1'>Reset Password</h1>
      <p className='my-4 lg:my-0'>
        Thanks for your request to reset your password. Please enter your new
        pasword below and confirm it to reset and regain access to your account.
      </p>
      <div className=''>
        <div className='lg:max-w-lg'>
          <PasswordInput
            required={true}
            inputClassName='bg-white'
            label='New Password'
            value={newPassword}
            onChange={({ target: { value } }) => setNewPassword(value)}
          />
        </div>
        <div className='mt-4 lg:max-w-lg'>
          <PasswordInput
            required={true}
            inputClassName='bg-white'
            label='Confirm Password'
            value={confirmNewPassword}
            onChange={({ target: { value } }) => setConfirmNewPassword(value)}
            message={message}
            messageIcon={messageIcon}
          />
        </div>
        <div className='my-4 lg:max-w-lg'>
          <PasswordStrengthIndicator
            password={newPassword}
            isValid={regex.test(newPassword)}
          />
        </div>
        <Button
          disabled={isSubmitting}
          onClick={() => onSubmit()}
          className='btn-primary md:w-56'
        >
          {isSubmitting ? <PulseLoader color='#D1D1D1' size='8px' /> : 'Change'}
        </Button>
      </div>
      <SuccessfulPasswordResetModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </LoginLayout>
  );
};

export default PasswordResetPage;
