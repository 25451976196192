import React from 'react';
import DatePicker from 'react-datepicker';
import Fieldset from '../fieldset';
interface Props {
  errorMessage?: string;
  inputRef?: any;
  label: string;
  name?: string;
  onChange?: (e: Date) => void;
  value: Date;
  required?: boolean;
  className?: string;
  maxDate?: Date;
  tooltip?: string;
  minDate?: Date;
}

const DateInput: React.FC<Props> = ({
  className,
  errorMessage,
  inputRef,
  label,
  name,
  onChange,
  required,
  value,
  maxDate,
  tooltip,
  minDate,
}) => {
  return (
    <Fieldset
      className={className}
      label={label}
      required={required}
      errorMessage={errorMessage}
      tooltip={tooltip}
    >
      <DatePicker
        id={`form-input-${label}`}
        className={`input ${className} ${errorMessage && 'error'}`}
        onChange={(e: Date) => {
          onChange && onChange(e);
        }}
        ref={inputRef}
        name={name}
        dateFormat='d MMMM yyyy'
        selected={value}
        maxDate={maxDate || new Date(9999, 12, 31)}
        showFullMonthYearPicker={true}
        showYearDropdown={true}
        minDate={minDate}
        withPortal={true}
        onFocus={(e) => e.currentTarget.blur()}
      />
    </Fieldset>
  );
};

export default DateInput;
