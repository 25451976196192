import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

function logout(): Promise<AxiosResponse> {
  return axios.post(endpoints.user.logout, '', {
    withCredentials: true,
    headers: {
      'Access-Control-Allow-Credentials': 'true',
    },
  });
}

export default logout;
