import React, { useState } from 'react';
import api from '../../../api';
import PasswordInput from '../../../components/input/PasswordInput';
import settings from '../../../config/settings';
import warningIcon from '../../../assets/images/warningIcon.svg';
import successIcon from '../../../assets/images/successIcon.svg';
import { PulseLoader } from 'react-spinners';
import PasswordStrengthIndicator from '../../../components/input/PasswordStrengthIndicator';
import Button from '../../../button';
import classNames from 'classnames';

interface Props {
  onSuccessfulPasswordReset?: () => void;
  isBgBlue?: boolean;
}

const ChangePassword: React.FC<Props> = ({
  onSuccessfulPasswordReset,
  isBgBlue,
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageIcon, setMessageIcon] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const regex = new RegExp(settings.passwordRegex);

  const onSubmit = async () => {
    setIsSubmitting(true);
    if (newPassword !== confirmNewPassword) {
      setMessage('Passwords must match');
      setMessageIcon(warningIcon);
      setIsSubmitting(false);
      return;
    }

    if (!regex.test(newPassword)) {
      setMessage(
        'Passwords must contain between 8 and 25 characters with at least: one uppercase character, one lowercase character, one special character and one number.'
      );
      setMessageIcon(warningIcon);
      setIsSubmitting(false);
      return;
    }

    await api
      .updatePassword(newPassword)
      .then(() => {
        setMessageIcon(successIcon);
        setMessage('Password updated successfully');
        setIsSubmitting(false);
        setNewPassword('');
        setConfirmNewPassword('');
        onSuccessfulPasswordReset && onSuccessfulPasswordReset();
      })
      .catch(() => {
        setMessageIcon(warningIcon);
        setMessage(
          'Error setting password. If this problem persists please contact a system administrator.'
        );
        setIsSubmitting(false);
      });
  };

  return (
    <div
      className={classNames(' flex flex-col lg:w-2/3  rounded-lg p-6', {
        'bg-white': !isBgBlue,
        'bg-lightBlue': isBgBlue,
      })}
    >
      <div className='h2'>Change Password</div>
      <div>
        Passwords must contain between 8 and 25 characters with at least: one
        uppercase character, one lowercase character, one special character and
        one number.
      </div>
      <div className='my-4  lg:max-w-lg'>
        <PasswordInput
          required={true}
          inputClassName='bg-grey'
          label='New Password'
          value={newPassword}
          onChange={({ target: { value } }) => setNewPassword(value)}
        />
      </div>
      <div className='my-4 lg:max-w-lg'>
        <PasswordInput
          required={true}
          inputClassName='bg-grey'
          label='Confirm Password'
          value={confirmNewPassword}
          onChange={({ target: { value } }) => setConfirmNewPassword(value)}
          message={message}
          messageIcon={messageIcon}
        />
      </div>
      <div className='my-4 lg:max-w-lg'>
        <PasswordStrengthIndicator
          password={newPassword}
          isValid={regex.test(newPassword)}
        />
      </div>
      <Button
        disabled={isSubmitting}
        onClick={() => onSubmit()}
        className='btn-primary md:w-56'
      >
        {isSubmitting ? (
          <div className='pt-1.5 lg:pt-1'>
            <PulseLoader color='#D1D1D1' size='8px' />
          </div>
        ) : (
          'Change'
        )}
      </Button>
    </div>
  );
};

export default ChangePassword;
