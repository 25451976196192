import React, { useEffect } from 'react';
import { ReactComponent as TooltipIcon } from '../../assets/images/tooltip.svg';
import ReactTooltip from 'react-tooltip';

interface Props {
  title: string;
  body?: string;
}

const Tooltip: React.FC<Props> = ({ title, body }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div
      className='inline px-2'
      data-tip={`<h2 class='text-lg font-bold'>${title}</h2><p class='text-base'>${body}</p>`}
    >
      <TooltipIcon className='inline w-5.5 h-5.5 cursor-pointer text-blue transition hover:opacity-50' />
    </div>
  );
};

export default Tooltip;
