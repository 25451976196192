import React, { useEffect, useState } from 'react';
import { AccordionWithTabs as Props } from '../../../types/FlexibleComponents';
import Accordion from '../../accordion';
import Pagination from '../../pagination';
import classnames from 'classnames';
import usePagination from '../../../hooks/usePagination';
import SelectInput from '../../input/SelectInput';

const AccordionWithTabs: React.FC<Props> = ({ tabs }) => {
  const [selectedTab, setSelectedTab] = useState<string | null>('');
  const [selectedAccordion, setSelectedAccordion] = useState<number>();

  const data =
    selectedTab === '' ? tabs : tabs.filter((item) => item.tab === selectedTab);

  const { setCurrentPage, paginate, currentPage, numberOfPages } =
    usePagination(data, 10);

  function getTabItems() {
    const values: string[] = [];

    for (let i = 0; i < tabs.length; i++) {
      const value = tabs[i].tab;

      if (values.includes(value)) {
        continue;
      }

      values.push(value);
    }

    return values.map((value) => ({ label: value, value: value }));
  }

  useEffect(() => {
    setCurrentPage(1);
    setSelectedAccordion(undefined);
  }, [selectedTab]);

  return (
    <div className='mb-4'>
      <div className='pb-2'>
        <div className='xl:flex xl:overflow-x-auto hidden'>
          <div
            className={classnames(
              'rounded-md my-4 font-semibold px-10 min-h-8 items-center flex',
              {
                'text-white bg-blue': !selectedTab,
                'text-blue': selectedTab,
              }
            )}
            onClick={() => {
              setSelectedTab('');
            }}
          >
            All
          </div>
          {getTabItems().map((item, i) => (
            <div
              key={`AccordianTabItem_${i}`}
              className={classnames(
                'rounded-md my-4 font-semibold px-10  items-center flex whitespace-no-wrap',
                {
                  'text-white bg-blue': selectedTab === item.value,
                  'text-blue': selectedTab !== item.value,
                }
              )}
              onClick={() => {
                setSelectedTab(item.value);
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className='xl:hidden '>
          <SelectInput
            options={[{ label: 'All', value: '' }, ...getTabItems()]}
            onChange={(e) => {
              setSelectedTab(e.value);
            }}
            inputClassName='bg-white '
            optionsClassName='bg-white'
            fieldsetClassName='w-full lg:mr-3 mb-2 mt-4'
            selectedOption={[
              { label: 'All', value: '' },
              ...getTabItems(),
            ].find((e) => e.value === selectedTab)}
          />
        </div>
      </div>
      <div>
        {paginate(data).map((item, i) => (
          <div className='mb-4' key={i}>
            <Accordion
              {...item}
              onClick={(e) => {
                if (i === selectedAccordion) {
                  setSelectedAccordion(undefined);
                  return;
                }
                setSelectedAccordion(i);
              }}
              isOpen={selectedAccordion === i}
            />
          </div>
        ))}
      </div>
      {numberOfPages > 1 && (
        <div className='mt-4'>
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
          />
        </div>
      )}
    </div>
  );
};

export default AccordionWithTabs;
