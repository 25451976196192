import classNames from 'classnames';
import { Field, Form, Formik, FormikState } from 'formik';
import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import api from '../../../api';
import warningIcon from '../../../assets/images/warningIcon.svg';
import Button from '../../../button';
import settings from '../../../config/settings';
import LoadingPage from '../../../pages/LoadingPage';
import { useAuth } from '../../../state/AuthProvider';
import { FlexibleForm as FlexibleFormType } from '../../../types/FlexibleComponents';
import {
  FileField,
  FlexibleFormField,
  MultiFileField,
} from '../../../types/FlexibleForm';
import Fieldset from '../../fieldset';
import FormSuccessfullySubmittedModal from '../../modals/FormSuccessfullySubmittedModal';
import {
  FlexibleCheckboxInput,
  FlexibleCheckboxInputOption,
} from './components/FlexibleCheckboxInput';
import FlexibleConsentInput from './components/FlexibleConsentInput';
import FlexibleDateInput from './components/FlexibleDateInput';
import FlexibleFileInput from './components/FlexibleFileInput';
import FlexibleMultiFileInput from './components/FlexibleMultiFileInput';
import { FlexibleRadioInput } from './components/FlexibleRadioInput';
import FlexibleSelectInput from './components/FlexibleSelectInput';
import FlexibleTextAreaInput from './components/FlexibleTextArea';
import FlexibleTextInput from './components/FlexibleTextInput';
import FlexibleTimeInput from './components/FlexibleTimeInput';
import createInitialValues from './createInitialValues';
import createValidationSchema from './createValidationSchema';
import handleConditionalLogicForm from './handleConditionalLogicForm';

interface Props {
  formId: string;
  title: string;
  description: string;
  isFullWidth?: boolean;
}
function formatFieldsBeforeRender(fields: FlexibleFormField[]) {
  const fieldsCopy = [...fields];

  const firstFileField = fieldsCopy.find((f) => f.type === 'file');

  if (!firstFileField) {
    return fieldsCopy;
  }

  const multifileField: MultiFileField = {
    type: 'multifile',

    names: fieldsCopy

      .filter((f) => f.type === 'file')

      .map((f) => (f as FileField).name),

    label: (firstFileField as FileField).label,

    required: (firstFileField as FileField).required,

    tooltip: (firstFileField as FileField).tooltip,

    validation_message: (firstFileField as FileField).validation_message,

    max_files: fieldsCopy.filter((f) => f.type === 'file').length,

    conditional_logic: (firstFileField as FileField).conditional_logic,
    autofill: null,
  };

  fieldsCopy[fieldsCopy.indexOf(firstFileField)] = multifileField;

  return fieldsCopy.filter((f) => f.type !== 'file');
}

const FlexibleForm: React.FC<Props> = ({
  formId,
  title,
  description,
  isFullWidth,
}) => {
  const [data, setData] = useState<FlexibleFormType>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    let isComponentMounted = true;
    setIsLoading(true);
    (async function () {
      try {
        const response = await api.getFormContent(formId);
        // const response = { data: test };

        if (isComponentMounted) {
          setData(response.data);

          setIsLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsLoading(false);

        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, [formId]);

  const handleSubmit = async (
    fields: any,
    resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void
  ) => {
    setIsSubmitting(true);
    if (settings.configuration !== 'demo') {
      const formValues = Object.keys(fields).map((key) => ({
        key,
        value: fields[key],
      }));
      const formData = new FormData();
      formValues.forEach((field) => {
        if (field.value.file) {
          formData.append(field.key, field.value.file);
        } else {
          formData.append(field.key, field.value);
        }
      });
      try {
        const res = await api.submitDynamicForm(formId, formData);
        if (res.status === 200) {
          setIsSuccessModalOpen(true);
          setIsSubmitting(false);
          resetForm({});
          setFormError('');
        }
      } catch {
        setIsSubmitting(false);
        setFormError(
          'An unexpected error has occured. If this problem persists please get in touch.'
        );
      }
    } else {
      // demo mode, pretend to wait to submit and then show success screen.
      await new Promise((r) => setTimeout(r, 750));
      setIsSuccessModalOpen(true);
      setIsSubmitting(false);
      resetForm({});
      setFormError('');
    }
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div
      className={classNames('bg-white rounded-lg p-6 ', {
        'lg:w-2/3 lg:mr-4': !isFullWidth,
      })}
    >
      {data && data.fields ? (
        <>
          <div className='h2 mb-4'>{title}</div>
          <div className='mb-4'>{description}</div>
          <Formik
            validationSchema={createValidationSchema(data.fields)}
            initialValues={createInitialValues(data.fields, user)}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
            validateOnChange={true}
          >
            {({
              setFieldTouched,
              setFieldValue,
              values,
              errors,
              touched,
              setFieldError,
            }) => (
              <Form id='reset'>
                {formatFieldsBeforeRender(data.fields).map((field, i) => {
                  const { conditional_logic } = field;
                  if (!handleConditionalLogicForm(conditional_logic, values)) {
                    return null;
                  }

                  switch (field.type) {
                    case 'address':
                      return (
                        <div key={i} className='mb-4'>
                          <Fieldset
                            errorMessage={
                              touched[field.name]
                                ? errors[field.name]?.toString()
                                : undefined
                            }
                          >
                            {field.fields.map((subfield, i) => (
                              <div key={i} className='mb-4'>
                                <Field
                                  {...subfield}
                                  name={subfield.name}
                                  label={subfield.label}
                                  component={FlexibleTextInput}
                                  inputClassName='bg-grey placeholder-white'
                                />
                              </div>
                            ))}
                          </Fieldset>
                        </div>
                      );
                    case 'checkbox':
                      return (
                        <div key={i} className='mb-4'>
                          <Fieldset
                            label={field.label}
                            required={field.required}
                            errorMessage={
                              touched[field.name]
                                ? errors[field.name]?.toString()
                                : undefined
                            }
                            tooltip={field.tooltip || undefined}
                          >
                            <FlexibleCheckboxInput>
                              {field.options.map((option, i) => {
                                return (
                                  <FlexibleCheckboxInputOption
                                    key={i}
                                    setFieldTouched={setFieldTouched}
                                    parentFieldName={field.name}
                                    setFieldValue={setFieldValue}
                                    fieldName={option.name}
                                    label={option.label}
                                    value={values[option.name] as string}
                                  />
                                );
                              })}
                            </FlexibleCheckboxInput>
                          </Fieldset>
                        </div>
                      );
                    case 'consent':
                      return (
                        <div key={i} className='mb-4'>
                          <Fieldset
                            label={field.label}
                            required={field.required}
                            errorMessage={
                              touched[field.name]
                                ? errors[field.name]?.toString()
                                : undefined
                            }
                          >
                            <FlexibleConsentInput
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                              fieldName={field.name}
                              value={values[field.name] as string}
                              label={field.label}
                            />
                          </Fieldset>
                        </div>
                      );
                    case 'date':
                      return (
                        <div key={i} className='mb-4'>
                          <Fieldset
                            label={field.label}
                            required={field.required}
                            errorMessage={
                              touched[field.name]
                                ? errors[field.name]?.toString()
                                : undefined
                            }
                          >
                            <FlexibleDateInput
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                              label={field.label}
                              fieldName={field.name}
                            />
                          </Fieldset>
                        </div>
                      );
                    case 'email':
                      return (
                        <div key={i} className='mb-4'>
                          <Field
                            {...field}
                            name={field.name}
                            label={field.label}
                            component={FlexibleTextInput}
                            inputClassName='bg-grey'
                          />
                        </div>
                      );
                    case 'file':
                      return (
                        <div key={i} className='mb-4'>
                          <Field
                            {...field}
                            name={field.name}
                            label={field.label}
                            component={FlexibleFileInput}
                            inputClassName='bg-grey'
                          />
                        </div>
                      );
                    case 'number':
                      return (
                        <div key={i} className='mb-4'>
                          <Field
                            {...field}
                            name={field.name}
                            label={field.label}
                            component={FlexibleTextInput}
                            inputClassName='bg-grey'
                          />
                        </div>
                      );
                    case 'phone':
                      return (
                        <div key={i} className='mb-4'>
                          <Field
                            {...field}
                            name={field.name}
                            label={field.label}
                            component={FlexibleTextInput}
                            inputClassName='bg-grey'
                            type='tel'
                          />
                        </div>
                      );
                    case 'radio':
                      return (
                        <div key={i} className='mb-4'>
                          <Fieldset
                            label={field.label}
                            errorMessage={
                              touched[field.name]
                                ? errors[field.name]?.toString()
                                : undefined
                            }
                            tooltip={field.tooltip || undefined}
                            required={field.required}
                          >
                            <FlexibleRadioInput
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                              fieldName={field.name}
                              value={values[field.name] as string}
                              options={field.options}
                            />
                          </Fieldset>
                        </div>
                      );
                    case 'select':
                      return (
                        <div key={i} className='mb-4'>
                          <Field
                            {...field}
                            name={field.name}
                            label={field.label}
                            component={FlexibleSelectInput}
                            inputClassName='bg-grey'
                            optionsClassName='bg-grey'
                          />
                        </div>
                      );
                    case 'text':
                      return (
                        <div key={i} className='mb-4'>
                          <Field
                            {...field}
                            name={field.name}
                            label={field.label}
                            component={FlexibleTextInput}
                            inputClassName='bg-grey'
                          />
                        </div>
                      );
                    case 'textarea':
                      return (
                        <div key={i} className='mb-4'>
                          <Field
                            {...field}
                            name={field.name}
                            label={field.label}
                            component={FlexibleTextAreaInput}
                            inputClassName='bg-grey'
                          />
                        </div>
                      );
                    case 'time':
                      return (
                        <div key={i} className='mb-4'>
                          <Fieldset
                            label={field.label}
                            errorMessage={
                              touched[field.name]
                                ? errors[field.name]?.toString()
                                : undefined
                            }
                            tooltip={field.tooltip || undefined}
                            required={field.required}
                          >
                            <FlexibleTimeInput
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                              fieldName={field.name}
                              value={values[field.name]}
                            />
                          </Fieldset>
                        </div>
                      );
                    case 'multifile':
                      return (
                        <div key={i} className='mb-4'>
                          <Fieldset
                            label={field.label}
                            errorMessage={
                              touched[field.names[0]]
                                ? errors[field.names[0]]?.toString()
                                : undefined
                            }
                            tooltip={field.tooltip || undefined}
                            required={field.required}
                          >
                            <FlexibleMultiFileInput
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                              fieldNames={field.names}
                              values={field.names.map((n) => values[n])}
                              maxFiles={field.max_files}
                              label={field.label}
                              inputClassName='bg-grey'
                              setFieldError={setFieldError}
                            />
                          </Fieldset>
                        </div>
                      );
                  }
                })}
                {formError && (
                  <div className='flex flex-row pt-2'>
                    <img
                      src={warningIcon}
                      alt='!'
                      className={'mr-4 h-6 self-center'}
                    />
                    <div className='inputErrorMessage'>{formError}</div>
                  </div>
                )}

                {settings.configuration === 'demo' && (
                  <p className='text-red text-sm'>
                    As this form is in demo mode, your submission will NOT be
                    saved.
                  </p>
                )}

                <Button
                  disabled={isSubmitting}
                  className='btn-primary w-full lg:w-auto'
                  type='submit'
                >
                  {isSubmitting ? (
                    <div className='pt-1.5 lg:pt-1'>
                      <PulseLoader color='#D1D1D1' size='8px' />
                    </div>
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Form>
            )}
          </Formik>
          <FormSuccessfullySubmittedModal
            isOpen={isSuccessModalOpen}
            setIsOpen={setIsSuccessModalOpen}
          />
        </>
      ) : (
        <div>Form not found. Please contact a system administrator</div>
      )}
    </div>
  );
};

export default FlexibleForm;
