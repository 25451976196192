import { FieldProps } from 'formik';
import React from 'react';
import { TextareaField } from '../../../../types/FlexibleForm';

import TextAreaInput from '../../../input/TextAreaInput';

const FlexibleTextAreaInput: React.FC<FieldProps & TextareaField> = ({
  field,
  meta,
  label,
  form,
  tooltip,
  placeholder,
  max_length,
  ...rest
}) => {
  return (
    <TextAreaInput
      {...rest}
      {...field}
      maxLength={max_length || undefined}
      label={label}
      placeholder={placeholder || undefined}
      errorMessage={
        (form.touched[field.name]?.valueOf() &&
          form.errors[field.name]?.toString()) ||
        undefined
      }
      tooltip={tooltip || undefined}
    />
  );
};

export default FlexibleTextAreaInput;
