import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

function resetPassword(
  password: string,
  token: string
): Promise<AxiosResponse> {
  const data = { newPassword: password, token };
  return axios.put(endpoints.user.resetPassword, data);
}

export default resetPassword;
