import React, { Fragment, useEffect, useState } from 'react';
import api from '../../../api';
import Button from '../../../button';
import TextInput from '../../../components/input/TextInput';
import { useAuth } from '../../../state/AuthProvider';
import { UserAddress } from '../../../types/User';
import successIcon from '../../../assets/images/successIcon.svg';
import warningIcon from '../../../assets/images/warningIcon.svg';
import { PulseLoader } from 'react-spinners';
import CorrespondenceUpdateConfirmModal from '../../../components/modals/CorrespondenceUpdateConfirmModal';
import IconWithText from '../../../components/flexible/IconWithText';
import { usePolyglot } from '../../../state/PolyglotProvider';

const CorrespondenceDetails: React.FC = () => {
  const { user, setUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [correspondenceName, setCorrespondenceName] = useState(
    user?.correspondenceName || ''
  );
  const [correspondenceAddress, setCorrespondenceAddress] =
    useState<UserAddress>(
      user?.correspondenceAddress || {
        propertyNumberOrName: '',
        town: '',
        addressId: 0,
        country: '',
        county: '',
        district: '',
        postcode: '',
        street: '',
      }
    );
  const [isSaveShown, setIsSaveShown] = useState(false);
  const [message, setMessage] = useState('');
  const [messageIcon, setMessageIcon] = useState(warningIcon);
  const [postcodeError, setPostcodeError] = useState('');
  const postcodeRegex = new RegExp(
    '^([A-PR-UWYZ](([0-9](([0-9]|[A-HJKSTUW])?)?)|([A-HK-Y][0-9]([0-9]|[ABEHMNPRVWXY])?)) [0-9][ABD-HJLNP-UW-Z]{2})$'
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { polyglot } = usePolyglot();

  const submitUser = async () => {
    if (!user || !correspondenceAddress) {
      return;
    }
    try {
      const response = await api.updateCorrespondenceDetails({
        correspondenceName,
        correspondenceAddress,
      });

      setUser({
        ...user,
        correspondenceName: response.data.correspondenceName,
        correspondenceAddress: response.data.correspondenceAddress,
      });
      if (correspondenceAddress.addressId === 0) {
        setCorrespondenceAddress({
          ...correspondenceAddress,
          addressId: response.data.correspondenceAddress.addressId,
        });
      }
      setIsLoading(false);
      setMessage('Details updated successfully.');
      setMessageIcon(successIcon);
    } catch (error) {
      setIsLoading(false);
      setMessage(
        'An unexpected error occured. If this problem persists please get in touch.'
      );
      setMessageIcon(warningIcon);
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!user || !correspondenceAddress) {
      setIsLoading(false);
      return;
    }
    if (correspondenceAddress.postcode.length > 0) {
      if (!postcodeRegex.test(correspondenceAddress.postcode)) {
        setPostcodeError(
          'Please enter a valid postcode. (Spaces must be included)'
        );
        setIsLoading(false);
        return;
      }
    }
    setPostcodeError('');

    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const hasValuesBeenUpdated =
      correspondenceName !== user.correspondenceName ||
      Object.values(correspondenceAddress)
        .map((a) => a || '')
        .sort()
        .join(',') !==
        Object.values(user.correspondenceAddress)
          .map((a) => a || '')
          .sort()
          .join(',');
    if (!isSaveShown) {
      hasValuesBeenUpdated && setIsSaveShown(true);
      return;
    }
    !hasValuesBeenUpdated && setIsSaveShown(false);
  }, [correspondenceName, correspondenceAddress, user]);

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='lg:w-2/3 lg:mr-4'>
        <div className='flex flex-col  rounded-lg p-6 bg-white mb-4'>
          <div className='h2'>Correspondence</div>
          {user && (
            <Fragment>
              <div className='flex flex-col lg:flex-row lg:flex-wrap'>
                <div className='my-4 lg:w-1/2 lg:pr-2'>
                  <TextInput
                    inputClassName='bg-grey'
                    label='Name'
                    value={correspondenceName}
                    onChange={(e) => setCorrespondenceName(e.target.value)}
                  />
                </div>
                <div className='my-4 lg:w-1/2 lg:pl-2'>
                  <TextInput
                    inputClassName='bg-grey'
                    label='Property Number or Name'
                    value={correspondenceAddress.propertyNumberOrName}
                    onChange={(e) => {
                      setCorrespondenceAddress((prev) => ({
                        ...prev,
                        propertyNumberOrName: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className='my-4 lg:w-1/2 lg:pr-2'>
                  <TextInput
                    inputClassName='bg-grey'
                    label='Street'
                    value={correspondenceAddress.street}
                    onChange={(e) =>
                      setCorrespondenceAddress((prev) => ({
                        ...prev,
                        street: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className='my-4 lg:w-1/2 lg:pl-2'>
                  <TextInput
                    inputClassName='bg-grey'
                    label='Town'
                    value={correspondenceAddress.town}
                    onChange={(e) => {
                      setCorrespondenceAddress((prev) => ({
                        ...prev,
                        town: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className='my-4 lg:w-1/2 lg:pr-2'>
                  <TextInput
                    inputClassName='bg-grey'
                    label='District'
                    value={correspondenceAddress.district}
                    onChange={(e) => {
                      setCorrespondenceAddress((prev) => ({
                        ...prev,
                        district: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className='my-4 lg:w-1/2 lg:pl-2'>
                  <TextInput
                    inputClassName='bg-grey'
                    label='County'
                    value={correspondenceAddress.county}
                    onChange={(e) => {
                      setCorrespondenceAddress((prev) => ({
                        ...prev,
                        county: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className='my-4 lg:w-1/2 lg:pr-2'>
                  <TextInput
                    inputClassName='bg-grey'
                    label='Country'
                    value={correspondenceAddress.country}
                    onChange={(e) => {
                      setCorrespondenceAddress((prev) => ({
                        ...prev,
                        country: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className='my-4 lg:w-1/2 lg:pl-2'>
                  <TextInput
                    inputClassName='bg-grey'
                    label='Postcode'
                    value={correspondenceAddress.postcode}
                    onChange={(e) => {
                      setCorrespondenceAddress((prev) => ({
                        ...prev,
                        postcode: e.target.value,
                      }));
                    }}
                    errorMessage={postcodeError}
                    pattern={`([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})`}
                  />
                </div>
              </div>
              {message && (
                <div className='flex flex-row pt-2'>
                  <img
                    src={messageIcon}
                    alt='!'
                    className='mr-4 h-6 self-center'
                  />
                  <div className='inputErrorMessage'>{message}</div>
                </div>
              )}
              {isSaveShown && (
                <div>
                  <Button
                    onClick={() => handleSubmit()}
                    className='btn-primary'
                  >
                    {isLoading ? (
                      <div className='pt-1.5 lg:pt-1'>
                        <PulseLoader color='#D1D1D1' size='8px' />
                      </div>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
      <div className='lg:w-1/3 lg:ml-4 mt-0 mb-4 lg:mt-0 lg:mb-0'>
        {polyglot?.t(
          'pages.update_profile.update_correspondence_details_card_1_title'
        ) && (
          <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
            <div className='h2 text-white'>
              {polyglot?.t(
                'pages.update_profile.update_correspondence_details_card_1_title'
              )}
            </div>
            <div className='py-4 text-white'>
              {polyglot?.t(
                'pages.update_profile.update_correspondence_details_card_1_introduction'
              )}
            </div>
            <a
              href={`tel:${polyglot?.t('general.support_telephone_number')}`}
              target='_blank'
              rel='noreferrer'
            >
              <IconWithText
                acf_fc_layout='icon_with_text'
                icon='phone'
                text={`Call: ${polyglot?.t(
                  'general.support_telephone_number'
                )}`}
                color='white'
              />
            </a>
          </div>
        )}
      </div>
      <CorrespondenceUpdateConfirmModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onAccept={submitUser}
      />
    </div>
  );
};

export default CorrespondenceDetails;
