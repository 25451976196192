import classnames from 'classnames';
import React from 'react';

interface Props {
  password: string;
  isValid: boolean;
}

const calculatePasswordStrength = (password: string, isValid: boolean) => {
  const numCharacters = password.length;
  const numUppercase = password.match(/[A-Z]/g)?.length || 0;
  const numLowercase = password.match(/[a-z]/g)?.length || 0;
  const numNumeric = password.match(/\d/g)?.length || 0;
  const numSpecial = password.match(/[@$!%*?&]/g)?.length || 0;

  const isOneAtLeastLength = (length: number) => {
    return (
      numUppercase >= length ||
      numLowercase >= length ||
      numNumeric >= length ||
      numSpecial >= length
    );
  };

  if (!isValid) {
    return 'Not valid';
  }

  if (
    numCharacters >= 12 &&
    numUppercase >= 2 &&
    numLowercase >= 2 &&
    numNumeric >= 2 &&
    numSpecial >= 2 &&
    isOneAtLeastLength(4)
  ) {
    return 'Very strong';
  }

  if (
    numCharacters >= 11 &&
    numUppercase >= 2 &&
    numLowercase >= 2 &&
    numNumeric >= 2 &&
    numSpecial >= 2 &&
    isOneAtLeastLength(3)
  ) {
    return 'Strong';
  }

  if (
    numCharacters >= 9 &&
    numUppercase >= 1 &&
    numLowercase >= 1 &&
    numNumeric >= 1 &&
    numSpecial >= 1 &&
    isOneAtLeastLength(2)
  ) {
    return 'Medium';
  }

  if (
    numCharacters >= 8 &&
    numUppercase >= 1 &&
    numLowercase >= 1 &&
    numNumeric >= 1 &&
    numSpecial >= 1
  ) {
    return 'Weak';
  }

  return;
};

const PasswordStrengthIndicator: React.FC<Props> = ({ password, isValid }) => {
  const passwordStrength = calculatePasswordStrength(password, isValid);

  return (
    <div className='flex items-center'>
      <div className='overflow-hidden border-2 border-black rounded-full w-2/3'>
        <div
          className={`h-4 origin-left transform transition-transform w-full ${classnames(
            {
              'bg-danger':
                passwordStrength === 'Not valid' || passwordStrength === 'Weak',
              'bg-warning': passwordStrength === 'Medium',
              'bg-success':
                passwordStrength === 'Strong' ||
                passwordStrength === 'Very strong',
            },
            {
              'scale-x-25': passwordStrength === 'Weak',
              'scale-x-50': passwordStrength === 'Medium',
              'scale-x-75': passwordStrength === 'Strong',
              'scale-x-100':
                passwordStrength === 'Very strong' ||
                passwordStrength === 'Not valid',
            }
          )}`}
        ></div>
      </div>
      <div className='w-1/3'>
        <p className={`font-semibold ml-4 text-blue`}>{passwordStrength}</p>
      </div>
    </div>
  );
};

export default PasswordStrengthIndicator;
