import React, { useEffect, useState } from 'react';
import Card from '../../components/card';
import MainLayout from '../../layouts/MainLayout';
import homeColouredIcon from '../../assets/images/homeColouredIcon.svg';
import maintenanceColouredIcon from '../../assets/images/maintenanceColouredIcon.svg';
import trackRepairColouredIcon from '../../assets/images/trackRepairColouredIcon.svg';
import paymentColouredIcon from '../../assets/images/paymentColouredIcon.svg';
import helpColouredIcon from '../../assets/images/helpColouredIcon.svg';
import logo from '../../assets/images/logo.svg';
import AtAGlance from '../../components/atAGlance';
import api from '../../api';
import { useAuth } from '../../state/AuthProvider';
import { usePolyglot } from '../../state/PolyglotProvider';
import { useHistory } from 'react-router-dom';
import settings from '../../config/settings';

const Home: React.FC = () => {
  const [isCurrentBalanceLoading, setIsCurrentBalanceLoading] = useState(false);
  const [currentBalanceData, setCurrentBalanceData] = useState<any>();
  const { user, setUser } = useAuth();
  const { polyglot } = usePolyglot();

  const { push } = useHistory();

  useEffect(() => {
    let isComponentMounted = true;
    setIsCurrentBalanceLoading(true);
    (async function () {
      try {
        const currentBalanceResponse = await api.getCurrentBalance();

        if (currentBalanceResponse.data.balance) {
          if (isComponentMounted) {
            setCurrentBalanceData(currentBalanceResponse.data);
          }
        }
        if (isComponentMounted) {
          setIsCurrentBalanceLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsCurrentBalanceLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  useEffect(() => {
    let isComponentMounted = true;
    setIsCurrentBalanceLoading(true);
    (async function () {
      try {
        const rentAccountsResponse = await api.getRentAccounts();

        if (rentAccountsResponse.data) {
          if (isComponentMounted) {
            // @ts-ignore
            setUser({
              ...user,
              mainRentAccountId: rentAccountsResponse.data?.find(
                (a) => a.isMain
              )?.accountId,
            });
          }
        }
        if (isComponentMounted) {
          setIsCurrentBalanceLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsCurrentBalanceLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  return (
    <MainLayout>
      <div className='flex flex-col flex-1'>
        <div className='pb-4 flex lg:hidden '>
          <img className='w-48 h-auto' src={logo} alt='Logo' />
        </div>
        <h1 className='h1'>
          {polyglot?.t('pages.dashboard.title', {
            name: user?.forename,
          })}
        </h1>
        <div className='flex flex-col pt-4'>
          <div className='flex flex-col-reverse lg:flex-row  my-4 '>
            <div className='flex flex-col lg:w-2/3 w-full lg:mr-4'>
              <div className='mb-4'>
                <Card
                  title={
                    polyglot?.t('pages.dashboard.card_rent.title', {
                      date: '1st December',
                    }) || ''
                  }
                  icon={homeColouredIcon}
                  description={polyglot?.t(
                    'pages.dashboard.card_rent.introduction'
                  )}
                  primaryButtonText={polyglot?.t(
                    'pages.dashboard.card_rent.cta_label'
                  )}
                  secondaryButtonText={`Pay offline by calling: ${polyglot?.t(
                    'general.support_telephone_number'
                  )}`}
                  secondaryButtonLink={`tel:${polyglot?.t(
                    'general.support_telephone_number'
                  )}`}
                  primaryButtonOnClick={() => {
                    window.open(settings.allPayUrl, '_blank');
                  }}
                  toolTipTitle={polyglot?.t(
                    'pages.dashboard.card_rent.tooltip_title'
                  )}
                  toolTipBody={polyglot?.t(
                    'pages.dashboard.card_rent.tooltip_body'
                  )}
                />
              </div>
              {!user?.isSharedOwner && !user?.isPotentialTenant && (
                <div className='flex flex-col md:flex-row  my-4'>
                  <div className='md:w-1/2 md:mr-4 mb-4 md:mb-0'>
                    <Card
                      title={
                        polyglot?.t(
                          'pages.dashboard.card_book_a_repair.title'
                        ) || ''
                      }
                      icon={maintenanceColouredIcon}
                      description={polyglot?.t(
                        'pages.dashboard.card_book_a_repair.introduction'
                      )}
                      primaryButtonText={polyglot?.t(
                        'pages.dashboard.card_book_a_repair.cta_label'
                      )}
                      primaryButtonOnClick={() =>
                        push('/maintenance-repairs/book-a-repair')
                      }
                      toolTipTitle={polyglot?.t(
                        'pages.dashboard.card_book_a_repair.tooltip_title'
                      )}
                      toolTipBody={polyglot?.t(
                        'pages.dashboard.card_book_a_repair.tooltip_body'
                      )}
                    />
                  </div>
                  <div className='md:w-1/2 md:ml-4 mt-4 md:mt-0'>
                    <Card
                      title={
                        polyglot?.t(
                          'pages.dashboard.card_track_a_repair.title'
                        ) || ''
                      }
                      icon={trackRepairColouredIcon}
                      description={polyglot?.t(
                        'pages.dashboard.card_track_a_repair.introduction'
                      )}
                      primaryButtonText={polyglot?.t(
                        'pages.dashboard.card_track_a_repair.cta_label'
                      )}
                      primaryButtonOnClick={() =>
                        push('/maintenance-repairs/track-a-repair')
                      }
                      toolTipTitle={polyglot?.t(
                        'pages.dashboard.card_track_a_repair.tooltip_title'
                      )}
                      toolTipBody={polyglot?.t(
                        'pages.dashboard.card_track_a_repair.tooltip_body'
                      )}
                    />
                  </div>
                </div>
              )}
              <div className='flex flex-col md:flex-row my-4'>
                <div className='md:w-1/2 md:mr-4 mb-4 md:mb-0'>
                  <div
                    onClick={() => push('/help-advice/payment-support')}
                    className='cursor-pointer'
                  >
                    <Card
                      title={
                        polyglot?.t(
                          'pages.dashboard.card_direct_debit.title'
                        ) || ''
                      }
                      icon={paymentColouredIcon}
                      toolTipTitle={polyglot?.t(
                        'pages.dashboard.card_direct_debit.tooltip_title'
                      )}
                      toolTipBody={polyglot?.t(
                        'pages.dashboard.card_direct_debit.tooltip_body'
                      )}
                    />
                  </div>
                </div>
                <div className='md:w-1/2 md:ml-4 mt-4 md:mt-0'>
                  <div
                    onClick={() => push('/help-advice')}
                    className='cursor-pointer'
                  >
                    <Card
                      title={
                        polyglot?.t(
                          'pages.dashboard.card_help_and_advice.title'
                        ) || ''
                      }
                      icon={helpColouredIcon}
                      primaryButtonOnClick={() =>
                        push('/maintenance-repairs/track-a-repair')
                      }
                      toolTipTitle={polyglot?.t(
                        'pages.dashboard.card_help_and_advice.tooltip_title'
                      )}
                      toolTipBody={polyglot?.t(
                        'pages.dashboard.card_help_and_advice.tooltip_body'
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col lg:w-1/3 lg:ml-4 mt-0 mb-4 lg:mt-0 lg:mb-0'>
              <AtAGlance
                isCurrentBalanceLoading={isCurrentBalanceLoading}
                currentBalance={currentBalanceData?.balance}
                lastPayment={
                  currentBalanceData && {
                    date: currentBalanceData.lastPaymentDate,
                    amount: currentBalanceData.lastPaymentAmount,
                  }
                }
                containerClassname={`${'bg-blue flex flex-col   rounded-lg pt-6 px-7 pb-8'}`}
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Home;
