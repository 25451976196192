import classnames from 'classnames';
import rafSchedule from 'raf-schd';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import api from '../../api';
import { ReactComponent as ArrowAlt } from '../../assets/images/arrow-alt.svg';
import logo from '../../assets/images/logo.svg';
import { ReactComponent as SquiggleDesktop } from '../../assets/images/squiggle-desktop.svg';
import { ReactComponent as SquiggleMobile } from '../../assets/images/squiggle-mobile.svg';
import FlexibleButton from '../../components/flexible/FlexibleButton';
import OnboardingScreen from '../../types/OnboardingScreen';
import ProgressBar from './components/ProgressBar';

const OnboardingPage: React.FC = () => {
  // TODO: Move 'data fetching' up to the root of the app, and pass
  // screens down as prop.
  const [screens, setScreens] = useState<OnboardingScreen[]>([]);

  useEffect(() => {
    let isComponentMounted = true;

    (async function () {
      try {
        const response = await api.getOnboardingScreens();

        if (isComponentMounted) {
          setScreens(response.data);
        }
      } catch (error) {
        // TODO: Add error handling.
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  const [currentScreen, setCurrentScreen] = useState(0);
  const [isHiddenScrollDown, setIsHiddenScrollDown] = useState(false);

  // On mobiles/tablets, change the screen based on left/right swipe.
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentScreen < screens.length - 1) {
        setCurrentScreen((screen) => screen + 1);
      }
    },
    onSwipedRight: () => {
      if (currentScreen > 0) {
        setCurrentScreen((screen) => screen - 1);
      }
    },
    delta: 64,
  });

  const parentRef = useRef<HTMLDivElement>(null);
  const childRefs = useRef<HTMLDivElement[]>([]);

  // On desktops, change the background image depending on the text currently
  // in the viewport.
  useLayoutEffect(() => {
    const schedule = rafSchedule(() => {
      if (window.innerWidth >= 1024) {
        childRefs.current.forEach((el, i) => {
          const { height, top } = el.getBoundingClientRect();
          const headerOffset = 158 / 2; // Height of fixed header, halved.
          if (
            top >= height * -0.5 + headerOffset &&
            top < height * 0.5 + headerOffset
          ) {
            setCurrentScreen(i);
          }
        });

        if (window.scrollY > 32) {
          setIsHiddenScrollDown(true);
        } else {
          setIsHiddenScrollDown(false);
        }
      }
    });

    schedule();

    window.addEventListener('scroll', schedule);

    return () => {
      window.removeEventListener('scroll', schedule);
    };
  }, []);

  // Smoothly adjust the height of the text container on screen transition.
  useLayoutEffect(() => {
    if (
      window.innerWidth < 1024 &&
      parentRef.current &&
      childRefs.current[currentScreen]
    ) {
      parentRef.current.style.height = `${childRefs.current[currentScreen].offsetHeight}px`;
    }
  }, [screens, currentScreen]);

  return (
    <div className='flex flex-col lg:flex-row h-screen' {...swipeHandlers}>
      <div className='lg:w-2/5'>
        {/* Fixed Header */}
        <div className='bg-green fixed left-0 px-4 sm:px-8 md:px-12 xl:px-24 py-4 sm:py-8 md:py-12 top-0 w-full lg:w-2/5 z-20'>
          <div className='flex -mx-2 sm:-mx-4'>
            <div className='px-2 sm:px-4 w-full'>
              <div className='flex items-center justify-between'>
                <img
                  src={logo}
                  alt='Johnnie Johnson Housing logo'
                  className='h-15.5 w-42'
                />
                <Link
                  to='/login'
                  className='border-b-2 border-blue font-semibold text-blue lg:hidden'
                >
                  {currentScreen === screens.length - 1 ? 'Proceed' : 'Skip'}
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Fixed Header Gradient */}
        <div className='bg-gradient-to-b fixed from-green h-4 lg:h-12 left-0 top-23.5 sm:top-31.5 md:top-39.5 w-full lg:w-2/5 z-20' />
        {/* Text */}
        <div className='bg-green relative pb-4 sm:pb-8 md:pb-12 lg:pb-16 pt-27.5 sm:pt-35.5 md:pt-43.5 px-4 sm:px-8 md:px-12 xl:px-24 lg:py-0 lg:z-10'>
          <div className='flex -mx-2 sm:-mx-4'>
            <div className='px-2 sm:px-4 w-full'>
              <div
                ref={parentRef}
                className='duration-500 overflow-hidden relative transition-height'
              >
                {screens.map((screen, i) => (
                  <div
                    key={i}
                    ref={(el) => (childRefs.current[i] = el as HTMLDivElement)}
                    className={`delay-500 lg:flex lg:h-screen lg:items-center left-0 lg:static top-0 transition-all ${classnames(
                      {
                        'relative z-10': currentScreen === i,
                        absolute: currentScreen !== i,
                      }
                    )}`}
                  >
                    <div
                      className={`transition-visibility ${classnames({
                        'delay-500 invisible': currentScreen !== i,
                      })}`}
                    >
                      <div
                        className={`duration-500 lg:opacity-100 transform lg:transform-none transition-all ${classnames(
                          {
                            'opacity-0 -translate-x-1/4': currentScreen > i,
                            'opacity-100 translate-x-0': currentScreen === i,
                            'opacity-0 translate-x-1/4': currentScreen < i,
                          }
                        )}`}
                      >
                        <h2 className='font-semibold mb-6 sm:mb-10 md:mb-14 text-2xl sm:text-4xl text-blue w-2/3 sm:w-1/2 lg:w-full'>
                          {screen.title}
                        </h2>
                        <p className='mb-6 sm:mb-10 md:mb-14 text-base text-blue w-full sm:w-2/3 lg:w-full'>
                          {screen.description}
                        </p>
                        <div>
                          <FlexibleButton
                            {...screen}
                            link_type={screen.button_type}
                            label={screen.button_label || ''}
                            acf_fc_layout='button'
                          />

                          <Link
                            to='/login'
                            className={classnames(
                              'border-b-2 border-blue font-semibold text-blue hidden lg:inline-block',
                              {
                                'ml-10':
                                  screen.button_type === 'embedded_content' ||
                                  screen.button_type === 'external_link' ||
                                  screen.button_type === 'internal_link' ||
                                  screen.button_type === 'download',
                              }
                            )}
                          >
                            {currentScreen === screens.length - 1
                              ? 'Proceed'
                              : 'Skip'}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <ul className='lg:hidden mt-6 sm:mt-10 md:mt-14'>
                {screens.map((screen, i) => (
                  <li
                    key={i}
                    className={`h-3 inline-block mr-3 rounded-full w-3 ${
                      currentScreen === i
                        ? 'bg-blue opacity-100'
                        : 'bg-white opacity-30'
                    }`}
                  ></li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className={`duration-300 transition-opacity ${classnames({
              'opacity-0': currentScreen > 0,
            })}`}
          >
            <div className='absolute bg-white -bottom-20 cursor-pointer flex h-20 items-center justify-center lg:hidden pointer-events-none right-4 sm:right-8 md:right-12 rounded-full w-20 z-20'>
              <ArrowAlt className='h-6 -rotate-90 transform w-6' />
            </div>
          </div>
          <div
            className={`duration-300 transition-opacity ${classnames({
              'opacity-0': isHiddenScrollDown,
            })}`}
          >
            <div className='bg-white bottom-8 fixed lg:flex h-20 hidden items-center justify-center left-12 xl:left-24 pointer-events-none rounded-full w-20'>
              <ArrowAlt className='h-6 transform w-6' />
            </div>
          </div>
          <SquiggleMobile className='absolute lg:hidden left-0 -mt-px text-green top-full w-full z-10' />
          <SquiggleDesktop className='lg:bottom-0 lg:block fixed left-full lg:left-2/5 h-full lg:h-screen hidden -ml-px text-green top-0 z-10' />
        </div>
      </div>
      {/* Images */}
      <div className='lg:fixed h-0 lg:h-auto pb-full sm:pb-16/9 lg:pb-0 relative lg:inset-0 w-full'>
        {screens.map((screen, i) => (
          <img
            key={i}
            src={screen.image}
            className={`absolute duration-500 h-full inset-0 object-cover transition-opacity w-full ${classnames(
              {
                'opacity-100': currentScreen === i,
                'opacity-0': currentScreen !== i,
              }
            )}`}
          />
        ))}
      </div>
      <ProgressBar />
    </div>
  );
};

export default OnboardingPage;
