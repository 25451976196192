import homeIcon from '../assets/images/homeIcon.svg';
import maintenanceIcon from '../assets/images/maintenanceIcon.svg';
import paymentIcon from '../assets/images/paymentIcon.svg';
import tenancyIcon from '../assets/images/tenancyIcon.svg';
import helpIcon from '../assets/images/helpIcon.svg';
import talkToUsIcon from '../assets/images/talkToUsIcon.svg';

const getMenuIcon = (url: string) => {
  switch (url) {
    case '/':
      return homeIcon;
    case '/maintenance-repairs':
      return maintenanceIcon;
    case '/payments':
      return paymentIcon;
    case '/tenancy':
      return tenancyIcon;
    case '/help-advice':
      return helpIcon;
    case '/talk-to-us':
      return talkToUsIcon;
    default:
      return homeIcon;
  }
};

export default getMenuIcon;
