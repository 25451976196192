import React, { Fragment, useState } from 'react';
import Modal from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import Button from '../../button';
import { usePolyglot } from '../../state/PolyglotProvider';
import TextBlock from '../flexible/TextBlock';
import CheckboxInput from '../input/CheckboxInput';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onComplete: () => void;
}
const TFAPromptModal: React.FC<Props> = ({ isOpen, onComplete }) => {
  const [dontShowTicked, setDontShowTicked] = useState(false);
  const [showDismissConfirmation, setShowDismissConfirmation] = useState(false);
  const { polyglot } = usePolyglot();
  const { push } = useHistory();

  const yes = () => {
    push('/account/contactDetails');
    onComplete();
  };

  const no = () => {
    if (dontShowTicked) {
      setShowDismissConfirmation(true);
      // prevent this message from showing on next login
      api.tfaDismiss(true).catch((e) => {
        console.error(e);
      });
    } else {
      onComplete();
    }
  };

  return (
    <Modal
      classNames={{ modal: 'modal' }}
      center={true}
      open={isOpen}
      onClose={() => {}}
      showCloseIcon={false}
    >
      <div className='flex flex-col px-4'>
        {showDismissConfirmation ? (
          <Fragment>
            <div className='h2 mb-4'>
              {polyglot?.t('pages.tfa_prompt.dismiss_title')}
            </div>
            <TextBlock
              acf_fc_layout='text_block'
              wysiwyg={polyglot?.t('pages.tfa_prompt.dismiss_intro') || ''}
            />

            <div className='flex'>
              <Button onClick={onComplete} className='btn-primary mb-0 mr-4'>
                Ok
              </Button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className='h2 mb-4'>
              {polyglot?.t('pages.tfa_prompt.title')}
            </div>
            <TextBlock
              acf_fc_layout='text_block'
              wysiwyg={polyglot?.t('pages.tfa_prompt.introduction') || ''}
            />

            <CheckboxInput
              label={"Don't show me this message again"}
              value={dontShowTicked}
              onClick={() => setDontShowTicked(!dontShowTicked)}
            />

            <div className='flex'>
              <Button onClick={yes} className='btn-primary mb-0 mr-4'>
                Yes, enable this
              </Button>

              <Button onClick={no} className='btn-secondary mb-0'>
                No
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    </Modal>
  );
};

export default TFAPromptModal;
