import { FormikHelpers } from 'formik';
import React from 'react';
import { RadioFieldOption } from '../../../../types/FlexibleForm';

const FlexibleRadioInputOption: React.FC<FlexibleRadioInputOptionProps> = ({
  setFieldValue,
  isSelected,
  setFieldTouched,
  fieldName,
  label,
  value,
}) => {
  return (
    <div
      className='flex flex-row mb-2 h-6 cursor-pointer'
      onClick={() => {
        setFieldTouched(fieldName);
        setFieldValue(fieldName, value);
      }}
    >
      <div className='flex items-center'>
        <div className='bg-white border-2 flex border-blue h-5 items-center justify-center mr-4 rounded-full w-5'>
          {isSelected && <div className='bg-blue h-3 rounded-full w-3'></div>}
        </div>
      </div>
      <div className='flex-1'>
        <p className='p'>{label}</p>
      </div>
    </div>
  );
};

interface FlexibleRadioInputProps {
  setFieldTouched: FormikHelpers<any>['setFieldTouched'];
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  fieldName: string;
  value: string;
  options: RadioFieldOption[];
}

const FlexibleRadioInput: React.FC<FlexibleRadioInputProps> = ({
  value,
  setFieldTouched,
  fieldName,
  options,
  setFieldValue,
}) => {
  return (
    <div className=''>
      {options.map((option, i) => (
        <FlexibleRadioInputOption
          key={i}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          fieldName={fieldName}
          label={option.label}
          value={option.value}
          isSelected={value === option.value}
        />
      ))}
    </div>
  );
};

interface FlexibleRadioInputOptionProps {
  setFieldTouched: FormikHelpers<any>['setFieldTouched'];
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  fieldName: string;
  label: string;
  value: string;
  isSelected: boolean;
}

export { FlexibleRadioInput, FlexibleRadioInputOption };
