import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

interface Props {
  id: number;
  phoneNumber: string;
  mobileNumber: string;
  genderId: number;
  dateOfBirth: Date;
}

function updateHouseholdContactDetails(
  userContactDetails: Props
): Promise<AxiosResponse> {
  return axios.put(
    endpoints.user.updateHouseholdContactDetail,
    userContactDetails
  );
}

export default updateHouseholdContactDetails;
