import { useState } from 'react';

export default function usePagination<T>(data: T[], itemsPerPage: number) {
  const [currentPage, setCurrentPage] = useState(1);

  function paginate(items: T[]) {
    return items.filter(
      (item, i) =>
        i >= (currentPage - 1) * itemsPerPage && i < currentPage * itemsPerPage
    );
  }

  const numberOfPages = Math.ceil(data.length / itemsPerPage);

  return { setCurrentPage, paginate, currentPage, numberOfPages };
}
