import React from 'react';
import FlexibleForm from '../../components/flexible/FlexibleForm';
import IconWithText from '../../components/flexible/IconWithText';
import MainLayout from '../../layouts/MainLayout';
import { usePolyglot } from '../../state/PolyglotProvider';
import { ReactComponent as OffsiteLinkWhite } from '../../assets/images/offsiteLinkWhite.svg';

const AstralinePage: React.FC = () => {
  const { polyglot } = usePolyglot();

  return (
    <MainLayout>
      <h1 className='h1 mb-4'>{polyglot?.t('pages.astraline.title')}</h1>
      <h2 className='h2 mb-2'>{polyglot?.t('pages.astraline.subtitle')}</h2>
      <p className='p mb-4'>{polyglot?.t('pages.astraline.introduction')}</p>
      <a
        target='_blank'
        href='https://www.astraline.co.uk'
        rel='noreferrer'
        className='btn-primary h-12  font-semibold inline-block  py-3'
      >
        <div className='flex'>
          {polyglot?.t('pages.astraline.external_link')}
          {<OffsiteLinkWhite className='ml-4 h-15 w-15' />}
        </div>
      </a>
      <div className='flex lg:flex-row flex-col mb-4'>
        <div className='lg:w-2/3 lg:mr-4 mt-4 lg:mt-0'>
          <FlexibleForm
            formId='23'
            title={polyglot?.t('pages.astraline.form_title') || ''}
            description={polyglot?.t('pages.astraline.form_introduction') || ''}
            isFullWidth={true}
          />
        </div>
        <div className='lg:w-1/3 lg:ml-4 mt-4 lg:mt-0'>
          {polyglot?.t('pages.astraline.sidebar_card_1.title') && (
            <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
              <div className='h2 text-white'>
                {polyglot?.t('pages.astraline.sidebar_card_1.title')}
              </div>
              <div className='py-4 text-white'>
                {polyglot?.t('pages.astraline.sidebar_card_1.introduction')}
              </div>
              <a href={`tel:0345 217 0721`} target='_blank' rel='noreferrer'>
                <IconWithText
                  acf_fc_layout='icon_with_text'
                  icon='phone'
                  text={`Call: 0345 217 0721`}
                  color='white'
                />
              </a>
            </div>
          )}
          {polyglot?.t('pages.astraline.sidebar_card_2.title') && (
            <div className='bg-white flex flex-col  rounded-lg p-4'>
              <div className='h2'>
                {polyglot?.t('pages.astraline.sidebar_card_2.title')}
              </div>
              <div className='py-4 text-blue'>
                {polyglot?.t('pages.astraline.sidebar_card_2.introduction')}
              </div>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default AstralinePage;
