import React from 'react';
import { PulseLoader } from 'react-spinners';

const LoadingPage: React.FC<{}> = () => {
  return (
    <div className='h-full w-full flex justify-center items-center bg-lightBlue'>
      <PulseLoader color='#D1D1D1' size='25px' />
    </div>
  );
};

export default LoadingPage;
