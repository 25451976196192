import classnames from 'classnames';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import api from '../../../api';
import Button from '../../../button';
import TextInput from '../../../components/input/TextInput';
import { usePolyglot } from '../../../state/PolyglotProvider';
import VerifyUserActions from '../../../types/VerifyUserActions';

export interface VerifyUserRequest {
  emailAddress: string;
  rentAccountNumber: number;
  firstname: string;
  surname: string;
}
interface Props {
  onSuccessfullyRegistered: (userDetails: VerifyUserRequest) => void;
}

const RegisterForm: React.FC<Props> = ({ onSuccessfullyRegistered }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [emailAddressError, setEmailAddressError] = useState('');
  const [rentAccountNumber, setRentAccountNumber] = useState('');
  const [rentAccountNumberError, setRentAccountNumberError] = useState('');
  const [firstname, setFirstname] = useState('');
  const [firstnameError, setFirstnameError] = useState('');
  const [surname, setSurname] = useState('');
  const [surnameError, setSurnameError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState('');
  const { polyglot } = usePolyglot();
  const onSubmit = async () => {
    setIsSubmitting(true);
    const emailRegex = new RegExp(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    );

    if (emailAddress.trim().length === 0) {
      setEmailAddressError('Please enter a valid email');
      setIsSubmitting(false);
      return;
    }

    if (!emailRegex.test(emailAddress)) {
      setEmailAddressError('Please enter a valid email');
      setIsSubmitting(false);
      return;
    }
    setEmailAddressError('');

    if (
      rentAccountNumber.trim().length === 0 ||
      isNaN(Number(rentAccountNumber))
    ) {
      setRentAccountNumberError('Please enter a valid rent account');
      setIsSubmitting(false);
      return;
    }

    setRentAccountNumberError('');

    if (firstname.trim().length === 0) {
      setFirstnameError('Please enter a valid first name');
      setIsSubmitting(false);
      return;
    }
    setFirstnameError('');

    if (surname.trim().length === 0) {
      setSurnameError('Please enter a valid surname');
      setIsSubmitting(false);
      return;
    }

    setSurnameError('');

    await api
      .verifyUser(emailAddress, parseInt(rentAccountNumber), firstname, surname)
      .then(({ data }) => {
        setIsSubmitting(false);
        if (VerifyUserActions[data] === VerifyUserActions.Unverified) {
          setFormError(
            'Unfortunately this account doesnt exist in our system. Please get in contact and we can set up your account'
          );
          return;
        }
        if (VerifyUserActions[data] === VerifyUserActions.EmailExists) {
          setFormError(
            'This email account already exists on our system. You can request a password reset from the login screen.'
          );
          return;
        }
        setFormError('');
        if (VerifyUserActions[data] === VerifyUserActions.Verified) {
          onSuccessfullyRegistered({
            emailAddress,
            rentAccountNumber: parseInt(rentAccountNumber),
            firstname,
            surname,
          });
          return;
        }
      })
      .catch(() => {
        setFormError(
          'An unexpected error has occured. If this problem persists please get in touch.'
        );
        setIsSubmitting(false);
      });
  };

  return (
    <Fragment>
      <div
        className={classnames({
          'pb-6':
            !emailAddressError &&
            !firstnameError &&
            !rentAccountNumberError &&
            !firstnameError &&
            !surnameError,
        })}
      >
        <p className='pb-4'>{polyglot?.t('pages.register.introduction')}</p>

        <TextInput
          className='mb-5'
          label='Enter your email address'
          value={emailAddress}
          onChange={({ target: { value } }) => setEmailAddress(value)}
          required={true}
          errorMessage={emailAddressError}
          tooltip={polyglot?.t('pages.register.tooltip_email_address')}
        />
        <TextInput
          className='mb-5'
          label='Rent account number'
          value={rentAccountNumber}
          onChange={({ target: { value } }) => setRentAccountNumber(value)}
          required={true}
          errorMessage={rentAccountNumberError}
        />
        <TextInput
          className='mb-5'
          label='First name'
          value={firstname}
          onChange={({ target: { value } }) => setFirstname(value)}
          required={true}
          errorMessage={firstnameError}
          tooltip={polyglot?.t('pages.register.tooltip_first_name')}
        />
        <TextInput
          className='mb-5'
          label='Surname'
          value={surname}
          onChange={({ target: { value } }) => setSurname(value)}
          required={true}
          errorMessage={surnameError}
          tooltip={polyglot?.t('pages.register.tooltip_surname')}
        />
        {formError && <div className='inputErrorMessage pt-4'>{formError}</div>}
        <div className='flex flex-col md:flex-row'>
          <Button
            disabled={isSubmitting}
            onClick={() => onSubmit()}
            className='btn-primary px-4 min-w-32 md:mr-2'
          >
            {isSubmitting ? (
              <div className='pt-1.5 lg:pt-1'>
                <PulseLoader color='#D1D1D1' size='8px' />
              </div>
            ) : (
              'Register new account'
            )}
          </Button>
        </div>
        <p>
          <span className='mr-1'>Already registered?</span>
          <Link to='/login' className='underline'>
            Log in now
          </Link>
        </p>
      </div>

      <p className='my-4 lg:my-3 text-left'>
        {polyglot
          ?.t('pages.register.support_subtitle')
          .replace('%{number}', '')}
        <a
          href={`tel:${polyglot
            ?.t('general.support_telephone_number')
            .replace(' ', '')}`}
          className='underline font-semibold'
        >
          {polyglot?.t('general.support_telephone_number')}
        </a>
      </p>
    </Fragment>
  );
};

export default RegisterForm;
