import React, { Fragment } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSidebar } from '../../../state/SidebarProvider';
import menuArrow from '../../../assets/images/menuArrow.svg';

interface Props {
  name: string;
  icon?: any;
  link: string;
  className?: string;
  openSubMenu?: () => void;
}

const SidebarItem: React.FC<Props> = ({
  icon,
  name,
  link,
  className,
  openSubMenu,
}) => {
  const { push } = useHistory();
  const { setIsSidebarOpen } = useSidebar();
  return (
    <Fragment>
      <NavLink
        to={link}
        exact={link === '/'}
        activeClassName='underline'
        className={`lg:flex flex-row items-center lg:py-2 ${className} hover:underline cursor-pointer hidden `}
        onClick={() => {
          setIsSidebarOpen(false);
          push(link);
        }}
      >
        {icon && (
          <img src={icon} alt={name} className='pr-2 w-8 max-h-5 mt-1' />
        )}
        <span className='font-semibold text-blue leading-4'>{name}</span>
      </NavLink>
      <div
        className={`flex flex-row items-center lg:py-2 ${className} hover:underline cursor-pointer lg:hidden justify-between pr-6`}
      >
        <div
          className='flex flex-row items-center'
          onClick={() => {
            setIsSidebarOpen(false);
            push(link);
          }}
        >
          {icon && (
            <img src={icon} alt={name} className='pr-2 w-8 max-h-5 mt-1' />
          )}
          <span className=' text-blue leading-4'>{name}</span>
        </div>
        {openSubMenu && (
          <img
            alt='>'
            className='self-end'
            src={menuArrow}
            onClick={openSubMenu}
          />
        )}
      </div>
    </Fragment>
  );
};

export default SidebarItem;
