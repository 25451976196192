import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import Button from '../../../button';
import FlexibleTextInput from '../../../components/flexible/FlexibleForm/components/FlexibleTextInput';
import * as Yup from 'yup';
import api from '../../../api';
import warningIcon from '../../../assets/images/warningIcon.svg';
import successIcon from '../../../assets/images/successIcon.svg';
import { PulseLoader } from 'react-spinners';
import { useAuth } from '../../../state/AuthProvider';

const ChangeUsername: React.FC = () => {
  const { user, setUser } = useAuth();
  const [message, setMessage] = useState('');
  const [messageIcon, setMessageIcon] = useState(warningIcon);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const schema = Yup.object().shape({
    confirmUsername: Yup.string()
      .when('newUsername', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('newUsername')],
          'Usernames must match!'
        ),
      })
      .email('Username must be a valid email address')
      .required('Please enter a username'),
  });

  const handleSubmit = async (username: string) => {
    await api
      .updateUsername(username)
      .then(() => {
        setMessageIcon(successIcon);
        setMessage('Username updated successfully');
        setIsSubmitting(false);

        if (user) {
          // update user to ensure contact details are up to date
          const newUser = { ...user };
          newUser.email = username;
          setUser(newUser);
        }
      })
      .catch(() => {
        setMessageIcon(warningIcon);
        setMessage(
          'Error setting username. If this problem persists please contact a system administrator.'
        );
        setIsSubmitting(false);
      });
  };

  return (
    <div className='bg-white flex flex-col lg:w-2/3 rounded-lg p-6'>
      <div className='h2'>Change Username</div>
      <Formik
        initialValues={{
          newUsername: '',
          confirmUsername: '',
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          handleSubmit(values.newUsername);
        }}
      >
        <Form>
          <div className='my-4 lg:max-w-lg'>
            <Field
              name='newUsername'
              label='New Username'
              component={FlexibleTextInput}
              inputClassName='bg-grey'
            />
          </div>
          <div className='my-4 lg:max-w-lg'>
            <Field
              name='confirmUsername'
              label='Confirm Username'
              component={FlexibleTextInput}
              inputClassName='bg-grey'
            />
          </div>
          {message && (
            <div className='flex flex-row pt-2'>
              <img
                src={messageIcon || warningIcon}
                alt='!'
                className='mr-4 h-6 self-center'
              />
              <div className='inputErrorMessage'>{message}</div>
            </div>
          )}

          <Button
            disabled={isSubmitting}
            type='submit'
            className='btn-primary md:w-56'
          >
            {isSubmitting ? (
              <div className='pt-1.5 lg:pt-1'>
                <PulseLoader color='#D1D1D1' size='8px' />
              </div>
            ) : (
              'Change'
            )}
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default ChangeUsername;
