import * as Yup from 'yup';
import { NumberField } from '../../../types/FlexibleForm';
import handleConditionalLogicValidationSchema from './handleConditionalLogicValidationSchema';

function createSchema(field: NumberField) {
  let schema = Yup.number();

  if (field.required) {
    schema = schema.required(
      field.validation_message || 'Please enter a value'
    );
  }

  if (field.min !== null) {
    schema = schema.min(field.min, `Minimum value allowed is ${field.min}`);
  }

  if (field.max !== null) {
    schema = schema.max(field.max, `Maximum value allowed is ${field.max}`);
  }

  return schema;
}

export default function createValidationSchemaNumberField(
  field: NumberField
): Yup.NumberSchema<any> {
  if (!field.conditional_logic) {
    return createSchema(field);
  }

  const { rules } = field.conditional_logic;
  const ruleFieldNames = rules.map((rule) => rule.fieldName);
  return Yup.number().when(ruleFieldNames, {
    is: (...values: any[]) => {
      const normalizedValues = values.map((value) => value || '');
      return handleConditionalLogicValidationSchema(
        field.conditional_logic,
        normalizedValues
      );
    },
    then: createSchema(field),
  });
}
