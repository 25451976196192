import React, { useState } from 'react';
import viewPassword from '../../assets/images/viewPasswordIcon.svg';
import hidePassword from '../../assets/images/hidePasswordIcon.svg';
import warningIcon from '../../assets/images/warningIcon.svg';

interface Props {
  message?: string;
  inputRef?: any;
  label: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
  pattern?: string;
  maxLength?: number;
  required?: boolean;
  className?: string;
  messageIcon?: any;
  inputClassName?: string;
}

const PasswordInput: React.FC<Props> = ({
  className,
  message,
  inputRef,
  label,
  name,
  onChange,
  placeholder = 'xxxxxxxxxx',
  messageIcon,
  value,
  pattern,
  maxLength,
  required,
  inputClassName,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <fieldset className={className}>
      <label htmlFor={`form-input-${label}`}>
        {label}
        {required ? '*' : ''}
      </label>

      <input
        maxLength={maxLength}
        id={`form-input-${label}`}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        defaultValue={value}
        onChange={(e) => onChange && onChange(e)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
          }
        }}
        ref={inputRef}
        name={name}
        pattern={pattern}
        onPaste={(e) => {
          e.preventDefault();
        }}
        onCopy={(e) => {
          e.preventDefault();
        }}
        className={`${inputClassName} input placeholder-placeholder`}
        value={value}
      />
      <span className={`viewPasswordIcon `}>
        <img
          onClick={() => setShowPassword(!showPassword)}
          src={showPassword ? hidePassword : viewPassword}
          alt='view'
        />
      </span>
      {message && (
        <div className='flex flex-row pt-2'>
          <img
            src={messageIcon || warningIcon}
            alt='!'
            className='mr-4 h-6 self-center'
          />
          <div className='inputErrorMessage'>{message}</div>
        </div>
      )}
    </fieldset>
  );
};

export default PasswordInput;
