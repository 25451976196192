import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/App.css';
import 'react-responsive-modal/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import Router from './components/Router';
import reportWebVitals from './reportWebVitals';
import AuthProvider from './state/AuthProvider';
import SidebarProvider from './state/SidebarProvider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SettingsProvider from './state/SettingsProvider';
import PolyglotProvider from './state/PolyglotProvider';
import DemoBanner from './components/demoBanner/DemoBanner';
import TooltipConfig from './components/tooltip/TooltipConfig';

ReactDOM.render(
  <React.StrictMode>
    <SettingsProvider>
      <PolyglotProvider>
        <SidebarProvider>
          <AuthProvider>
            <DemoBanner />
            <Router />
            <TooltipConfig />
          </AuthProvider>
        </SidebarProvider>
      </PolyglotProvider>
    </SettingsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
