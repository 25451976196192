import React from 'react';
import Modal from 'react-responsive-modal';
import { useHistory } from 'react-router';
import Button from '../../button';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const RedirectToRegister: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { push } = useHistory();
  return (
    <Modal
      classNames={{ modal: 'modal' }}
      center={true}
      open={isOpen}
      onClose={() => {}}
      showCloseIcon={false}
    >
      <div className='flex flex-col'>
        <div className='h1 mb-4'>Having Problems?</div>
        <p className='p mb-4'>
          It seems you are trying to access an account that doesnt exist on our
          system. Click the button below to begin the register journey.
        </p>
        <div className='flex justify-center flex-col md:flex-row'>
          <Button
            onClick={() => {
              setIsOpen(false);
              push('/register');
            }}
            className='btn-primary mb-0 md:mr-4'
          >
            Register
          </Button>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            className='btn-secondary mb-0'
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RedirectToRegister;
