import React from 'react';
import { FlexibleIcon as FlexibleIconType } from '../../../types/FlexibleComponents';
import { ReactComponent as NotificationColouredIcon } from '../../../assets/images/notificationColouredIcon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/images/phoneIcon.svg';
import { ReactComponent as PlayIcon } from '../../../assets/images/play.svg';
import { ReactComponent as MaintenanceColouredIcon } from '../../../assets/images/maintenanceColouredIcon.svg';
import { ReactComponent as TrackRepairColouredIcon } from '../../../assets/images/trackRepairColouredIcon.svg';

interface Props {
  icon: FlexibleIconType;
}

const FlexibleIcon: React.FC<Props> = ({ icon }) => {
  switch (icon) {
    case 'bell':
      return <NotificationColouredIcon className='h-14 w-14' />;
    case 'phone':
      return <PhoneIcon className='h-14 w-14' />;
    case 'play':
      return (
        <div className='rounded-full cursor-pointer bg-white h-5 w-5 md:h-10 md:w-10 lg:h-15.5 lg:w-15.5 flex items-center justify-center'>
          <PlayIcon className='h-3 w-3 md:h-4 md:w-4 lg:h-5 lg:w-5 ml-1' />
        </div>
      );
    case 'bookRepair':
      return <MaintenanceColouredIcon className='h-14 w-14' />;
    case 'trackRepair':
      return <TrackRepairColouredIcon className='h-14 w-14' />;
  }
};

export default FlexibleIcon;
