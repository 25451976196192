import React from 'react';
import classnames from 'classnames';
import Button from '../../button';
import Tooltip from '../tooltip/Tooltip';

interface Props {
  className?: string;
  title: string;
  icon?: any;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonLink?: string;
  description?: string;
  toolTipTitle?: string;
  toolTipBody?: string;
}
const Card: React.FC<Props> = ({
  className,
  title,
  icon,
  primaryButtonOnClick,
  primaryButtonText,
  secondaryButtonLink,
  secondaryButtonText,
  description,
  toolTipTitle,
  toolTipBody,
}) => (
  <div
    className={`${'bg-white flex flex-col h-full rounded-lg p-4'} ${className}`}
  >
    <div className='flex flex-row flex-grow'>
      <div
        className={classnames('', {
          'flex items-center pt-0 lg:pt-0':
            !description && !primaryButtonText && !secondaryButtonText,
          'pt-6 lg:pt-4': description,
          'w-4/5': icon,
        })}
      >
        <div className={classnames('h2')}>
          <span
            onClick={() => primaryButtonOnClick && primaryButtonOnClick()}
            className={classnames({ 'cursor-pointer': primaryButtonOnClick })}
          >
            {title}
          </span>
          {toolTipTitle && <Tooltip title={toolTipTitle} body={toolTipBody} />}
        </div>
        {description && <div className='py-4 text-blue'>{description}</div>}
      </div>
      {icon && (
        <div className='flex flex-1 flex-row lg:justify-end'>
          <img src={icon} alt='Icon' className='h-16 w-16' />
        </div>
      )}
    </div>
    {(primaryButtonText || secondaryButtonText) && (
      <div className='flex flex-col md:flex-row'>
        {primaryButtonText && (
          <Button
            className='btn-primary'
            onClick={() => primaryButtonOnClick && primaryButtonOnClick()}
          >
            {primaryButtonText}
          </Button>
        )}
        {secondaryButtonText && (
          <a
            href={secondaryButtonLink}
            className={classnames(
              'flex items-center font-semibold text-blue cursor-pointer',
              { 'pl-4': primaryButtonText }
            )}
          >
            {secondaryButtonText}
          </a>
        )}
      </div>
    )}
  </div>
);

export default Card;
