import React, { MouseEvent, useLayoutEffect, useRef } from 'react';
import { ReactComponent as MinusIcon } from '../../assets/images/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { useMainLayout } from '../../state/MainLayoutProvider';
import TextBlock from '../flexible/TextBlock';

interface Props {
  title: string;
  content: string;
  isOpen: boolean;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
}

const Accordion: React.FC<Props> = ({ title, content, isOpen, onClick }) => {
  const { refMainLayout } = useMainLayout();

  const divRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!isOpen || !divRef.current) {
      return;
    }

    const { offsetTop } = divRef.current;
    const menuHeight = 96;
    const buffer = 16;

    const t = setTimeout(() => {
      refMainLayout.current?.scrollTo({
        top: offsetTop - menuHeight - buffer,
        behavior: 'smooth',
      });
    }, 50);

    return () => {
      clearTimeout(t);
    };
  }, [isOpen]);

  return (
    <div
      ref={divRef}
      className='bg-white flex flex-col rounded-lg p-4'
      onClick={(e) => onClick(e)}
    >
      <div className='cursor-pointer flex flex-row items-center justify-between w-full'>
        <div className='font-semibold text-blue'>{title}</div>
        <div className='ml-4'>{isOpen ? <MinusIcon /> : <PlusIcon />}</div>
      </div>
      {isOpen && (
        <div className='mt-2'>
          <TextBlock acf_fc_layout='text_block' wysiwyg={content} />
        </div>
      )}
    </div>
  );
};

export default Accordion;
