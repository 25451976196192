import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';
import { UserAddress } from '../types/User';

interface Props {
  correspondenceName: string;
  correspondenceAddress: UserAddress;
}

function updateCorrespondenceDetails(
  correspondenceDetails: Props
): Promise<AxiosResponse> {
  return axios.put(
    endpoints.user.updateUserCorrespondenceDetails,
    correspondenceDetails
  );
}

export default updateCorrespondenceDetails;
