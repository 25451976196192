import React from 'react';
import { MediaCarousel } from '../../../types/FlexibleComponents';
import Carousel from '../../carousel/Carousel';
import ImageCarouselItem from '../../carousel/imageCarouselItem/ImageCarouselItem';
import VideoCarouselItem from '../../carousel/videoCarouselItem/VideoCarouselItem';

const FlexibleCarousel: React.FC<MediaCarousel> = ({ slides }) => {
  return (
    <div className='my-4'>
      <Carousel>
        {slides
          .filter((slide) => slide.image)
          .map((slide) => {
            return slide.video ? (
              <VideoCarouselItem image={slide.image} video={slide.video} />
            ) : (
              <ImageCarouselItem image={slide.image} />
            );
          })}
      </Carousel>
    </div>
  );
};

export default FlexibleCarousel;
