import React from 'react';
import Modal from 'react-responsive-modal';
import { useHistory } from 'react-router';
import Button from '../../button';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const SuccessfulPasswordResetModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
}) => {
  const { push } = useHistory();
  return (
    <Modal
      classNames={{ modal: 'modal' }}
      center={true}
      open={isOpen}
      onClose={() => {}}
      showCloseIcon={false}
    >
      <div className='flex flex-col'>
        <div className='h2 px-4'>
          Your password has been successfully reset. Click the button below to
          return to the login screen.
        </div>
        <div className='flex justify-center'>
          <Button
            onClick={() => {
              setIsOpen(false);
              push('/login');
            }}
            className='btn-primary mb-0'
          >
            Return to login
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessfulPasswordResetModal;
