import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import api from '../../api';
import Button from '../../button';
import PasswordInput from '../../components/input/PasswordInput';
import PasswordStrengthIndicator from '../../components/input/PasswordStrengthIndicator';
import SuccessfulVerifyModal from '../../components/modals/SuccessfulVerifyModal';
import settings from '../../config/settings';
import LoginLayout from '../../layouts/LoginLayout';

interface Props {}

const VerifyUserPage: React.FC<Props> = () => {
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const regex = new RegExp(settings.passwordRegex);

  const resetToken = useLocation()
    .search.split('&')
    .reduce((a: Record<string, string>, b: string) => {
      const [key, val] = b.split('=');
      return {
        ...a,
        [key]: val,
      };
    }, {} as any)['?token'];

  const onSubmit = async () => {
    setIsSubmitting(true);

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords must match');

      setIsSubmitting(false);
      return;
    }

    if (!regex.test(newPassword)) {
      setPasswordError(
        'Passwords must contain between 8 and 25 characters with at least: one uppercase character, one lowercase character, one special character and one number.'
      );

      setIsSubmitting(false);
      return;
    }
    setPasswordError('');

    await api
      .createUserFromVerify(newPassword, resetToken)
      .then(() => {
        setIsSubmitting(false);
        setIsModalOpen(true);
      })
      .catch(() => {
        setFormError(
          'There was an error setting your security details. If this problem persists please get in touch.'
        );
        setIsSubmitting(false);
      });
  };

  if (!resetToken) {
    return <Redirect to='/login' />;
  }

  return (
    <LoginLayout>
      <h1 className='my-4 lg:my-0 h1'>Verify Your Account</h1>
      <p className='my-4 lg:my-0 text-center xl:text-left'>
        We&apos;ve successfully verified your details in our system. Please
        create a password to finish setting up your account.
      </p>
      <div>
        <div className='font-semibold text-blue'>Please create a password:</div>

        <PasswordInput
          className='mb-5'
          label='Enter your new Password'
          value={newPassword}
          onChange={({ target: { value } }) => setNewPassword(value)}
          required
        />
        <PasswordInput
          className='mb-5'
          label='Confirm Password'
          value={confirmPassword}
          onChange={({ target: { value } }) => setConfirmPassword(value)}
          required
          message={
            passwordError ||
            `Passwords must contain between 8 and 25 characters with at least: one uppercase character, one lowercase character, one special character and one number.`
          }
        />
        <div className='inputErrorMessage' />
        <PasswordStrengthIndicator
          password={newPassword}
          isValid={regex.test(newPassword)}
        />
        {formError && <div className='inputErrorMessage pt-4'>{formError}</div>}
        <Button
          onClick={() => onSubmit()}
          className='btn-primary w-full md:w-auto'
        >
          {isSubmitting ? (
            <div className='pt-1'>
              <PulseLoader color='#D1D1D1' size='8px' />
            </div>
          ) : (
            ' Complete account creation'
          )}
        </Button>
      </div>
      <SuccessfulVerifyModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </LoginLayout>
  );
};

export default VerifyUserPage;
