import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';
import LoginActions from '../types/LoginActions';
import User from '../types/User';

interface ResponseData {
  action: LoginActions;
  message: string;
  token: string;
  refreshToken: string;
  contactDetails: User;
  tfaUseEmail?: boolean;
  tfaDismissed: boolean;
}

function login(
  username?: string,
  password?: string,
  rememberMe?: boolean,
  tfaCode?: string,
  tfaUseEmail?: boolean
): Promise<AxiosResponse<ResponseData>> {
  const data = { username, password, rememberMe, tfaCode, tfaUseEmail };
  return axios.post(endpoints.user.authenticate, data, {
    withCredentials: true,
    headers: {
      'Access-Control-Allow-Credentials': 'true',
    },
  });
}

export default login;
