import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import api from '../../../api';
import successIcon from '../../../assets/images/successIcon.svg';
import warningIcon from '../../../assets/images/warningIcon.svg';
import Button from '../../../button';
import IconWithText from '../../../components/flexible/IconWithText';
import CheckboxInput from '../../../components/input/CheckboxInput';
import TextInput from '../../../components/input/TextInput';
import { useAuth } from '../../../state/AuthProvider';
import { usePolyglot } from '../../../state/PolyglotProvider';

const UserDetails: React.FC = () => {
  const { user, setUser } = useAuth();
  const [isUniversalCreditClaimant, setIsUniversalCreditClaimant] = useState(
    user?.isCreditClaimant || false
  );
  const [isSaveShown, setIsSaveShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageIcon, setMessageIcon] = useState(warningIcon);
  const { polyglot } = usePolyglot();

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!user) {
      setIsLoading(false);
      return;
    }

    try {
      await api.updateUniversalCredit(isUniversalCreditClaimant);

      setUser({
        ...user,
        isCreditClaimant: isUniversalCreditClaimant,
      });

      setIsLoading(false);
      setMessage('Details updated successfully.');
      setMessageIcon(successIcon);
    } catch (error) {
      setIsLoading(false);
      setMessage(
        'An unexpected error occured. If this problem persists please get in touch.'
      );
      setMessageIcon(warningIcon);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const hasValuesBeenUpdated =
      isUniversalCreditClaimant !== user.isCreditClaimant;
    if (!isSaveShown) {
      hasValuesBeenUpdated && setIsSaveShown(true);
      return;
    }
    !hasValuesBeenUpdated && setIsSaveShown(false);
  }, [isUniversalCreditClaimant, user]);

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='lg:w-2/3 lg:mr-4'>
        <div className='flex flex-col rounded-lg p-6 bg-white mb-4'>
          <div className='h2'>User Details</div>
          {user && (
            <div className='flex flex-col lg:flex-row lg:flex-wrap'>
              <div className='my-4 lg:w-1/2 lg:pr-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='Forename'
                  placeholder={user.forename}
                  value={''}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pl-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='Middle Name'
                  value={''}
                  placeholder={user.middlename}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pr-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='Surname'
                  value={''}
                  placeholder={user.surname}
                  disabled={true}
                />
              </div>

              <div className='my-4 lg:w-1/2 lg:pl-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='Gender'
                  value={''}
                  placeholder={user.gender}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pr-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='Date Of Birth'
                  value={''}
                  placeholder={new Date(user.dateOfBirth).toDateString()}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pl-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='Property Number or Name'
                  value={''}
                  placeholder={user.address.propertyNumberOrName}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pr-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='Street'
                  value={''}
                  placeholder={user.address.street}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pl-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='Town'
                  value={''}
                  placeholder={user.address.town}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pr-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='District'
                  value={''}
                  placeholder={user.address.district}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pl-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='County'
                  value={''}
                  placeholder={user.address.county}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pr-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='Country'
                  value={''}
                  placeholder={user.address.country}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pl-2'>
                <TextInput
                  inputClassName='bg-grey'
                  label='Postcode'
                  value={''}
                  placeholder={user.address.postcode}
                  disabled={true}
                />
              </div>
              <div className='my-4 lg:w-1/2 lg:pr-2'>
                <CheckboxInput
                  onClick={() => setIsUniversalCreditClaimant((c) => !c)}
                  label='Universal Credit Claimant'
                  value={isUniversalCreditClaimant}
                />
                {isUniversalCreditClaimant && (
                  <p
                    className={`mb-4 textBlock`}
                    dangerouslySetInnerHTML={{
                      __html:
                        polyglot?.t(
                          'pages.update_profile.universal_credit_description'
                        ) || '',
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {message && (
            <div className='flex flex-row pt-2'>
              <img src={messageIcon} alt='' className='mr-4 h-6 self-center' />
              <div className='inputErrorMessage'>{message}</div>
            </div>
          )}
          {isSaveShown && (
            <div>
              <Button onClick={() => handleSubmit()} className='btn-primary'>
                {isLoading ? (
                  <div className='pt-1.5 lg:pt-1'>
                    <PulseLoader color='#D1D1D1' size='8px' />
                  </div>
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className='lg:w-1/3 lg:ml-4 mt-0 mb-4 lg:mt-0 lg:mb-0'>
        {polyglot?.t('pages.update_profile.update_details_card_1_title') && (
          <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
            <div className='h2 text-white'>
              {polyglot?.t('pages.update_profile.update_details_card_1_title')}
            </div>
            <div className='py-4 text-white'>
              {polyglot?.t(
                'pages.update_profile.update_details_card_1_introduction'
              )}
            </div>
            <a
              href={`tel:${polyglot?.t('general.support_telephone_number')}`}
              target='_blank'
              rel='noreferrer'
            >
              <IconWithText
                acf_fc_layout='icon_with_text'
                icon='phone'
                text={`Call: ${polyglot?.t(
                  'general.support_telephone_number'
                )}`}
                color='white'
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
