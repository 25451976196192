import React from 'react';
interface Props {
  className?: string;
}

const TableRow: React.FC<Props> = ({ children, className }) => {
  return (
    <tr
      className={`border border-solid border-grey  border-l-0 border-r-0 py-0 ${className} `}
    >
      {children}
    </tr>
  );
};

export default TableRow;
