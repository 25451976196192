import * as Yup from 'yup';
import { EmailField } from '../../../types/FlexibleForm';
import handleConditionalLogicValidationSchema from './handleConditionalLogicValidationSchema';

function createSchema(field: EmailField) {
  let schema = Yup.string().email('Please enter a valid email address');

  if (field.required) {
    schema = schema.required(
      field.validation_message || 'Please enter a value'
    );
  }

  return schema;
}

export default function createValidationSchemaEmailField(
  field: EmailField
): Yup.StringSchema<any> {
  if (!field.conditional_logic) {
    return createSchema(field);
  }

  const { rules } = field.conditional_logic;
  const ruleFieldNames = rules.map((rule) => rule.fieldName);
  return Yup.string()
    .email('Please enter a valid email address')
    .when(ruleFieldNames, {
      is: (...values: any[]) => {
        const normalizedValues = values.map((value) => value || '');
        return handleConditionalLogicValidationSchema(
          field.conditional_logic,
          normalizedValues
        );
      },
      then: createSchema(field),
    });
}
