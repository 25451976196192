import React from 'react';
import usePagination from '../../../hooks/usePagination';
import { Table as Props } from '../../../types/FlexibleComponents';
import Pagination from '../../pagination';
import TableBody from '../../table/components/TableBody';
import TableHeader from '../../table/components/TableHeader';
import Table from '../../table/Table';

const FlexibleTable: React.FC<Props> = ({ head, body }) => {
  const { setCurrentPage, paginate, currentPage, numberOfPages } =
    usePagination(body, 10);
  return (
    <div className='mb-4 w-full'>
      <Table overflowTable>
        {head && <TableHeader cells={head} />}{' '}
        {body && (
          <TableBody
            showBottomRow={currentPage < numberOfPages}
            rows={paginate(body)}
          />
        )}
      </Table>

      {numberOfPages > 1 && (
        <div className='mt-4'>
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            numberOfPages={numberOfPages}
          />
        </div>
      )}
    </div>
  );
};

export default FlexibleTable;
