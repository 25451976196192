import React from 'react';
import warningIcon from '../../assets/images/warningIcon.svg';
import informationIcon from '../../assets/images/informationIcon.svg';

interface Props {
  className?: string;
  label?: string | null;
  required?: boolean | null;
  errorMessage?: string | null;
  tooltip?: string | null;
}

const Fieldset: React.FC<Props> = ({
  className,
  label,
  required,
  children,
  errorMessage,
  tooltip,
}) => {
  return (
    <fieldset className={className}>
      {label && (
        <label htmlFor={`form-input-${label}`}>
          {label}
          {required ? '*' : ''}
        </label>
      )}

      {children}

      {(errorMessage || tooltip) && (
        <div className='flex flex-row pt-2'>
          <img
            src={errorMessage ? warningIcon : informationIcon}
            alt='!'
            className={'mr-4 h-6 self-center'}
          />
          <div className='inputErrorMessage'>{errorMessage || tooltip}</div>
        </div>
      )}
    </fieldset>
  );
};

export default Fieldset;
