import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';
import VerifyUserActions from '../types/VerifyUserActions';

function createUserFromVerify(
  password: string,
  token: string
): Promise<AxiosResponse<VerifyUserActions>> {
  const data = {
    password,
    token,
  };

  return axios.post(endpoints.user.createUserFromVerify, data);
}

export default createUserFromVerify;
