import React from 'react';
import { LandingPageMarketingPoint } from '../../../state/SettingsProvider';
import { ReactComponent as TooltipCheckSVG } from '../../../assets/images/check-tooltip.svg';

interface MarketingPointsProps {
  items: LandingPageMarketingPoint[];
}

export const MarketingPoints: React.FC<MarketingPointsProps> = ({ items }) => (
  <div className='flex items-center lg:min-h-screen w-full py-12'>
    <div className='w-3/5 mx-10 lg:ml-40 lg:mr-6'>
      {items.map((point, i) => {
        return (
          <div
            className='flex py-4 align-center'
            key={`marketingPoint_register_${i}`}
          >
            <div className='pr-3'>
              <TooltipCheckSVG />
            </div>

            <div>
              <p className='text-lightBlue tk-omnes-pro font-bold text-xl'>
                {point.title}
              </p>
              <p className='text-white text-lg'>{point.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);
