import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

export interface GenderOption {
  codeId: number;
  description: string;
}

function getGenderOptions(): Promise<AxiosResponse<GenderOption[]>> {
  return axios.get(endpoints.user.getGenderOptions);
}

export default getGenderOptions;
