import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../api';
import AtAGlance from '../../components/atAGlance';
import Card from '../../components/card';
import MainLayout from '../../layouts/MainLayout';
import { usePolyglot } from '../../state/PolyglotProvider';

const TenancyPage: React.FC = () => {
  const { polyglot } = usePolyglot();

  const [isCurrentBalanceLoading, setIsCurrentBalanceLoading] = useState(false);
  const [currentBalanceData, setCurrentBalanceData] = useState<any>();
  const { push } = useHistory();

  useEffect(() => {
    let isComponentMounted = true;
    setIsCurrentBalanceLoading(true);
    (async function () {
      try {
        const currentBalanceResponse = await api.getCurrentBalance();

        if (currentBalanceResponse.data.balance) {
          if (isComponentMounted) {
            setCurrentBalanceData(currentBalanceResponse.data);
          }
        }
        if (isComponentMounted) {
          setIsCurrentBalanceLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsCurrentBalanceLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  return (
    <MainLayout>
      <div className='flex flex-col flex-1'>
        <h1 className='h1 mb-4'>Tenancy</h1>
        <div className='flex flex-col-reverse lg:flex-row  my-4 '>
          <div className='flex flex-col lg:w-2/3 w-full lg:mr-4'>
            <div className='mb-4'>
              <Card
                title={
                  polyglot?.t('pages.tenancy.card_end_tenancy.title') ||
                  'Looking to end your tenancy?'
                }
                description={
                  polyglot?.t('pages.tenancy.card_end_tenancy.introduction') ||
                  'We understand that from time to time, there may be reasons why you need to end your tenancy with us. Before this can happen we will need to discuss your notice period and any rent that needs to be settled before your tenancy can end. Please contact our Customer Service Hub on 0345 305 5335, where a member of our team will be able to assist you further.'
                }
                primaryButtonText={
                  polyglot?.t('pages.tenancy.card_end_tenancy.cta_label') ||
                  'Find out more'
                }
                primaryButtonOnClick={() => push('/tenancy/end-tenancy')}
                toolTipTitle={polyglot?.t(
                  'pages.tenancy.card_end_tenancy.tooltip_title'
                )}
                toolTipBody={polyglot?.t(
                  'pages.tenancy.card_end_tenancy.tooltip_body'
                )}
              />
            </div>
          </div>
          <div className='flex flex-col lg:w-1/3 lg:ml-4 mt-0 mb-4 lg:mt-0 lg:mb-0'>
            <AtAGlance
              isCurrentBalanceLoading={isCurrentBalanceLoading}
              currentBalance={currentBalanceData?.balance}
              lastPayment={
                currentBalanceData && {
                  date: currentBalanceData.lastPaymentDate,
                  amount: currentBalanceData.lastPaymentAmount,
                }
              }
              containerClassname={`${'bg-blue flex flex-col   rounded-lg pt-6 px-7 pb-8'}`}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default TenancyPage;
