import React from 'react';

interface Props {
  count: number;
  max: number;
}

const CharacterCountIndicator: React.FC<Props> = ({ count, max }) => {
  return (
    <div className='mt-2 font-medium text-blue'>
      {count} of {max} Characters
    </div>
  );
};

export default CharacterCountIndicator;
