import React from 'react';
import tickIcon from '../../assets/images/tickIcon.svg';

interface CheckboxInputProps {
  label: string;
  value?: boolean;
  onClick: () => void;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  onClick,
  label,
  value,
}) => {
  return (
    <div
      className='flex min-h-6 flex-row mb-2 cursor-pointer'
      onClick={() => onClick()}
    >
      <div className='flex items-center'>
        <div className='bg-white border-2 flex border-blue h-5 items-center justify-center mr-4 rounded-md w-5'>
          {value && <img src={tickIcon} className='h-3 w-3' />}
        </div>
      </div>
      <div className=''>
        <p className='p'>{label}</p>
      </div>
    </div>
  );
};

export default CheckboxInput;
