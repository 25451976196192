import React from 'react';
import Fieldset from '../fieldset/Fieldset';

interface Props {
  errorMessage?: string;
  inputRef?: any;
  label: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value: string;
  pattern?: string;
  maxLength?: number;
  required?: boolean;
  className?: string;
  inputClassName?: string;
  tooltip?: string;
  disabled?: boolean;
  minLength?: number;
  max?: number;
}

const TextInput: React.FC<Props> = ({
  className,
  errorMessage,
  inputRef,
  label,
  name,
  onChange,
  placeholder,
  type,
  value,
  pattern,
  maxLength,
  inputClassName,
  required,
  tooltip,
  disabled,
  minLength,
  max,
}) => {
  return (
    <Fieldset
      className={className}
      label={label}
      required={required}
      errorMessage={errorMessage}
      tooltip={tooltip}
    >
      <input
        maxLength={maxLength}
        id={`form-input-${label}`}
        className={` ${inputClassName} input ${
          errorMessage && 'error'
        } placeholder-placeholder`}
        type={type}
        placeholder={placeholder}
        defaultValue={value}
        onChange={(e) => onChange && onChange(e)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
          }
        }}
        ref={inputRef}
        name={name}
        pattern={pattern}
        value={value}
        disabled={disabled}
        minLength={minLength}
        max={max}
      />
    </Fieldset>
  );
};

export default TextInput;
