import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router';
import CookieBanner from '../components/cookieBanner';
import Menu from '../components/menu';
import Sidebar from '../components/sidebar';
import { useAuth } from '../state/AuthProvider';
import MainLayoutProvider from '../state/MainLayoutProvider';
import { useSettings } from '../state/SettingsProvider';
import { useSidebar } from '../state/SidebarProvider';

// interface SocialLinks {
//   facebook: string;
//   twitter: string;
//   linkedin: string;
//   instagram: string;
// }

const MainLayout: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { isSidebarOpen } = useSidebar();
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const [isCookieSet, setIsCookieSet] = useState(false);

  const { menuItems, socialLinks } = useSettings();

  const refMainLayout = useRef<HTMLDivElement>(null);

  useEffect(
    () => setIsCookieSet(document.cookie.trim() === '' ? false : true),
    []
  );
  if (!isAuthenticated) {
    return <Redirect to='/login' />;
  }
  const onCloseCookie = () => {
    document.cookie =
      'is_allowed=1; path=/; expires=Thu, 30 Dec 2080 12:00:00 UTC;';
    setIsCookieSet(true);
  };

  return (
    <div className='App bg-white  '>
      <Sidebar socialLinks={socialLinks} menuItems={menuItems} />
      <div className='main flex flex-col lg:pl-64'>
        <Menu
          socialLinks={socialLinks}
          isMobileSearchOpen={isMobileSearchOpen}
          setIsMobileSearchOpen={setIsMobileSearchOpen}
        />

        <div
          ref={refMainLayout}
          className={classnames(
            'lg:rounded-tl-xl flex flex-col h-full bg-lightBlue  overflow-y-auto lg:overflow-y-auto mt-24',
            { hidden: isSidebarOpen || isMobileSearchOpen }
          )}
        >
          <div className='flex-grow px-7 py-7 lg:pt-10 lg:px-10'>
            <MainLayoutProvider refMainLayout={refMainLayout}>
              {children}
            </MainLayoutProvider>
          </div>
        </div>
      </div>
      {!isCookieSet && <CookieBanner onCloseCookie={() => onCloseCookie()} />}
    </div>
  );
};

export default MainLayout;
