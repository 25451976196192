import { FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

type Props = {
  setFieldTouched: FormikHelpers<any>['setFieldTouched'];
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  fieldName: string;
  label: string;
  defaultValue?: Date;
};

const FlexibleDateInput: React.FC<Props> = ({
  setFieldTouched,
  setFieldValue,
  fieldName,
  label,
  defaultValue,
}) => {
  const [value, setValue] = useState<any>(defaultValue);

  useEffect(() => {
    if (!value) {
      return;
    }

    setFieldTouched(fieldName);

    const formattedDate = value.toLocaleDateString('en-GB');

    if (formattedDate !== 'Invalid Date') {
      setFieldValue(fieldName, formattedDate);
    }
  }, [value]);

  return (
    <div>
      <DatePicker
        onChange={(date) => setValue(date)}
        id={`form-input-${label}`}
        className='input bg-grey w-full'
        dateFormat='d MMMM yyyy'
        showFullMonthYearPicker={true}
        showYearDropdown={true}
        selected={value}
        maxDate={new Date(9999, 12, 31)}
        wrapperClassName='w-full'
        withPortal={true}
        onFocus={(e) => e.currentTarget.blur()}
      />
    </div>
  );
};

export default FlexibleDateInput;
