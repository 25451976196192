import * as Yup from 'yup';
import { ConsentField } from '../../../types/FlexibleForm';
import handleConditionalLogicValidationSchema from './handleConditionalLogicValidationSchema';

function createSchema(field: ConsentField) {
  let schema = Yup.string();

  if (field.required) {
    schema = schema.required('You must tick this box to continue');
  }

  return schema;
}

export default function createValidationSchemaConsentField(
  field: ConsentField
): Yup.StringSchema<any> {
  if (!field.conditional_logic) {
    return createSchema(field);
  }

  const { rules } = field.conditional_logic;
  const ruleFieldNames = rules.map((rule) => rule.fieldName);
  return Yup.string().when(ruleFieldNames, {
    is: (...values: any[]) => {
      const normalizedValues = values.map((value) => value || '');
      return handleConditionalLogicValidationSchema(
        field.conditional_logic,
        normalizedValues
      );
    },
    then: createSchema(field),
  });
}
