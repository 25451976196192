import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import * as yup from 'yup';
import api from '../../../api';
import successIcon from '../../../assets/images/successIcon.svg';
import warningIcon from '../../../assets/images/warningIcon.svg';
import Button from '../../../button';
import Fieldset from '../../../components/fieldset';
import FlexibleDateInput from '../../../components/flexible/FlexibleForm/components/FlexibleDateInput';
import FlexibleSelectInput from '../../../components/flexible/FlexibleForm/components/FlexibleSelectInput';
import FlexibleTextInput from '../../../components/flexible/FlexibleForm/components/FlexibleTextInput';
import IconWithText from '../../../components/flexible/IconWithText';
import { OptionType } from '../../../components/input/SelectInput';
import { usePolyglot } from '../../../state/PolyglotProvider';
import User from '../../../types/User';
interface Props {
  user?: User;
}

interface IForm {
  homeNumber: string;
  mobileNumber: string;
  gender: string;
  dateOfBirth: any;
}

const HouseholdContactUpdate: React.FC<Props> = ({ user }) => {
  const [message, setMessage] = useState('');
  const [messageIcon, setMessageIcon] = useState(warningIcon);
  const [isLoading, setIsLoading] = useState(false);
  const [genderOptions, setGenderOptions] = useState<OptionType[]>([]);
  const { polyglot } = usePolyglot();

  const validationSchema = yup.object().shape(
    {
      homeNumber: yup.string().when('mobilePhone', {
        is: (mobilePhone: string) => !mobilePhone || mobilePhone.length === 0,
        then: yup.string().required('Please enter a phone or mobile number.'),
        otherwise: yup.string(),
      }),
      mobilePhone: yup.string().when('homeNumber', {
        is: (homeNumber: string) => !homeNumber || homeNumber.length === 0,
        then: yup.string().required('Please enter a phone or mobile number.'),
        otherwise: yup.string(),
      }),
      dateOfBirth: yup.string(),
    },
    [['homeNumber', 'mobilePhone']]
  );

  useEffect(() => {
    let isComponentMounted = true;
    setIsLoading(true);
    (async function () {
      try {
        if (!user) {
          return;
        }
        const contactsResponse = await api.getGenderOptions();
        if (contactsResponse.data.length > 0) {
          if (isComponentMounted) {
            const options = contactsResponse.data.map((a) => {
              if (a.description === 'NotSpecified') {
                return {
                  label: 'Not Specified',
                  value: a.codeId.toString(),
                };
              }
              return {
                label: a.description,
                value: a.codeId.toString(),
              };
            });
            setGenderOptions(options);
          }
        }
        if (isComponentMounted) {
          setIsLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  const handleSubmit = async (
    e: IForm,
    actions: FormikHelpers<{
      homeNumber: string;
      mobileNumber: string;
      dateOfBirth: any;
      gender: string;
    }>
  ) => {
    setMessage('');
    setIsLoading(true);
    if (!user) {
      setIsLoading(false);
      return;
    }
    const splitDate = e.dateOfBirth.split('/');
    var f = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
    try {
      await api.updateHouseholdContactDetails({
        id: user.id,
        phoneNumber: e.homeNumber,
        mobileNumber: e.mobileNumber,
        genderId: parseFloat(e.gender),
        dateOfBirth: f,
      });

      setIsLoading(false);
      setMessage('Details updated successfully.');
      setMessageIcon(successIcon);
      actions.resetForm({ values: e });
    } catch (error) {
      setIsLoading(false);
      setMessage(
        'An unexpected error occured. If this problem persists please get in touch.'
      );
      setMessageIcon(warningIcon);
      console.log(error);
    }
  };

  if (!user) {
    return <div></div>;
  }

  const userAge =
    new Date(
      new Date().getTime() - new Date(user.dateOfBirth).getTime()
    ).getFullYear() - 1970;
  const isUserUnder16 = userAge < 16;

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='lg:w-2/3 lg:mr-4'>
        <div className='flex flex-col  rounded-lg p-6 bg-white mb-4'>
          <div className='h2'>Contact Details</div>
          {user && genderOptions.length > 0 && (
            <Fragment>
              <Formik
                initialValues={{
                  homeNumber: user.phoneNumber,
                  mobileNumber: user.mobileNumber,
                  dateOfBirth: new Date(user.dateOfBirth),
                  gender:
                    genderOptions.find((e) => e.label === user.gender)?.value ||
                    '0',
                }}
                validationSchema={validationSchema}
                onSubmit={(e, actions) => {
                  //@ts-ignore
                  handleSubmit(e, actions);
                }}
              >
                {({
                  dirty,
                  touched,
                  errors,
                  setFieldValue,
                  setFieldTouched,
                  values,
                }) => (
                  <Form>
                    <div className='flex flex-col lg:flex-row lg:flex-wrap'>
                      {!isUserUnder16 && (
                        <Fragment>
                          <div className='my-4 lg:w-1/2 lg:pr-2'>
                            <Field
                              label='Home Phone Number'
                              name='homeNumber'
                              component={FlexibleTextInput}
                              inputClassName='bg-grey'
                            />
                          </div>
                          <div className='my-4 lg:w-1/2 lg:pl-2'>
                            <Field
                              label='Mobile Phone Number'
                              name='mobileNumber'
                              component={FlexibleTextInput}
                              inputClassName='bg-grey'
                            />
                          </div>
                        </Fragment>
                      )}
                      <div className='my-4 lg:w-1/2 lg:pr-2'>
                        <Fieldset
                          label='Date of Birth'
                          errorMessage={
                            touched.dateOfBirth
                              ? //@ts-ignore
                                errors.dateOfBirth?.toString()
                              : undefined
                          }
                        >
                          <FlexibleDateInput
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            label='Date of Birth'
                            fieldName='dateOfBirth'
                            defaultValue={values.dateOfBirth}
                          />
                        </Fieldset>
                      </div>
                      <div className='my-4 lg:w-1/2 lg:pl-2'>
                        <Field
                          label='Gender'
                          name='gender'
                          component={FlexibleSelectInput}
                          inputClassName='bg-grey'
                          optionsClassName='bg-grey'
                          options={genderOptions}
                        />
                      </div>
                    </div>
                    {dirty && (
                      <div>
                        <Button type='submit' className='btn-primary'>
                          {isLoading ? (
                            <div className='pt-1.5 lg:pt-1'>
                              <PulseLoader color='#D1D1D1' size='8px' />
                            </div>
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>

              {message && (
                <div className='flex flex-row pt-2'>
                  <img
                    src={messageIcon}
                    alt=''
                    className='mr-4 h-6 self-center'
                  />
                  <div className='inputErrorMessage'>{message}</div>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
      <div className='lg:w-1/3 lg:ml-4 mt-0 mb-4 lg:mt-0 lg:mb-0'>
        {polyglot?.t(
          'pages.update_profile.update_household_contact_details_card_1_title'
        ) && (
          <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
            <div className='h2 text-white'>
              {polyglot?.t(
                'pages.update_profile.update_household_contact_details_card_1_title'
              )}
            </div>
            <div className='py-4 text-white'>
              {polyglot?.t(
                'pages.update_profile.update_household_contact_details_card_1_introduction'
              )}
            </div>
            <a
              href={`tel:${polyglot?.t('general.support_telephone_number')}`}
              target='_blank'
              rel='noreferrer'
            >
              <IconWithText
                acf_fc_layout='icon_with_text'
                icon='phone'
                text={`Call: ${polyglot?.t(
                  'general.support_telephone_number'
                )}`}
                color='white'
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default HouseholdContactUpdate;
