import classNames from 'classnames';
import React from 'react';
import { FlexibleColumns as Props } from '../../../types/FlexibleComponents';
import FlexibleColumnsContent from './components/FlexibleColumnsContent';

const CardColumns: React.FC<Props> = ({ columns, color, columns_per_row }) => {
  return (
    <div className='flex flex-row flex-wrap mb-4 -mx-2'>
      {columns.map((card, i) => (
        <div
          key={i}
          className={`flex mb-4 px-2 w-full ${classNames({
            'lg:w-1/2': columns_per_row === 2,
            'lg:w-1/3': columns_per_row === 3,
          })}`}
        >
          <FlexibleColumnsContent content={card.content} color={color} />
        </div>
      ))}
    </div>
  );
};

export default CardColumns;
