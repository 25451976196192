import React, { Dispatch, SetStateAction } from 'react';
import PaginationItem from './PaginationItem';
import classnames from 'classnames';

interface Props {
  setCurrentPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  numberOfPages: number;
}

const Pagination: React.FC<Props> = ({
  currentPage,
  numberOfPages,
  setCurrentPage,
}) => {
  const getInnerItems = () => {
    const multiplesOf10 = Math.floor(numberOfPages / 10);
    const emptyArray = [...Array(multiplesOf10)];
    const multipleArray = emptyArray.map((_, i) => {
      return (i + 1) * 10;
    });

    const checkNumbersBehind = (
      array: number[],
      currentIndex: number,
      spacesToCheck: number
    ) => {
      const items1Before = array.filter(
        (i) => i < currentIndex - (spacesToCheck - 1)
      );
      const lastItem1Behind = items1Before[items1Before.length - 1];

      if (currentIndex - spacesToCheck === lastItem1Behind) {
        return lastItem1Behind;
      }

      if (lastItem1Behind >= currentIndex) {
        return lastItem1Behind;
      }
      return null;
    };

    const check2SpacesForward = (array: number[], currentIndex: number) => {
      const itemsBefore = array.filter((i) => i > currentIndex + 1);
      const lastItem = itemsBefore[itemsBefore.length - 1];

      if (currentIndex + 2 === lastItem) {
        return lastItem;
      }
      return null;
    };
    if (numberOfPages <= 5) {
      return Array.from({ length: numberOfPages - 2 }, (v, i) => i + 2);
    }

    if (currentPage <= 3) {
      return [
        2,
        3,
        checkNumbersBehind(multipleArray, currentPage, 2),
        ...multipleArray,
      ];
    }

    if (currentPage >= numberOfPages - 2) {
      return [
        ...multipleArray.filter((i) => i < currentPage - 3),
        checkNumbersBehind(multipleArray, currentPage, currentPage % 10),
        numberOfPages - 2,
        numberOfPages - 1,
      ];
    }

    return [
      ...multipleArray.filter((i) => i < currentPage - 2),
      checkNumbersBehind(multipleArray, currentPage, 2),
      currentPage - 1,
      currentPage,
      currentPage + 1,
      check2SpacesForward(multipleArray, currentPage),
      ...multipleArray.filter((i) => i > currentPage + 2),
    ];
  };

  return (
    <div className='flex justify-center flex-wrap'>
      <div
        className={classnames({
          'cursor-pointer': currentPage > 1,
        })}
        onClick={() => currentPage > 1 && setCurrentPage((p) => p - 1)}
      >
        <PaginationItem isDisabled={currentPage === 1}>PREV</PaginationItem>
      </div>
      <div className='cursor-pointer' onClick={() => setCurrentPage(1)}>
        <PaginationItem isActive={currentPage === 1}>1</PaginationItem>
      </div>
      {getInnerItems().map((item, i) => {
        return item ? (
          <div
            className='cursor-pointer'
            key={i}
            onClick={() => setCurrentPage(item)}
          >
            <PaginationItem isActive={currentPage === item}>
              {item}
            </PaginationItem>
          </div>
        ) : (
          <PaginationItem>&hellip;</PaginationItem>
        );
      })}
      <div
        className='cursor-pointer'
        onClick={() => setCurrentPage(numberOfPages)}
      >
        <PaginationItem isActive={currentPage === numberOfPages}>
          {numberOfPages}
        </PaginationItem>
      </div>
      <div
        className={classnames({
          'cursor-pointer': currentPage < numberOfPages,
        })}
        onClick={() =>
          currentPage < numberOfPages && setCurrentPage((p) => p + 1)
        }
      >
        <PaginationItem isDisabled={currentPage === numberOfPages}>
          NEXT
        </PaginationItem>
      </div>
    </div>
  );
};

export default Pagination;
