import React, { Fragment, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import api from '../../../api';
import successIcon from '../../../assets/images/successIcon.svg';
import warningIcon from '../../../assets/images/warningIcon.svg';
import Button from '../../../button';
import IconWithText from '../../../components/flexible/IconWithText';
import CheckboxInput from '../../../components/input/CheckboxInput';
import TextInput from '../../../components/input/TextInput';
import SecondFactorAuthenticationModal from '../../../components/modals/SecondFactorAuthenticationModal';
import { useAuth } from '../../../state/AuthProvider';
import { usePolyglot } from '../../../state/PolyglotProvider';

const ContactDetails: React.FC = () => {
  const { user, setUser } = useAuth();
  const [
    isSecondFactorAuthenticationModalOpen,
    setIsSecondFactorAuthenticationModalOpen,
  ] = useState(false);
  const [hasTFA, setHasTFA] = useState(user?.hasTFA || false);
  const [phoneNumber, setHomePhoneNumber] = useState(user?.phoneNumber || '');
  const [mobileNumber, setMobilePhoneNumber] = useState(
    user?.mobileNumber || ''
  );
  const [isSaveShown, setIsSaveShown] = useState(false);
  const [message, setMessage] = useState('');
  const [messageIcon, setMessageIcon] = useState(warningIcon);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const [mobileErrorMessage, setMobileErrorMessage] = useState('');
  const [tfaUseEmail, setTfaUseEmail] = useState(false);
  const { polyglot } = usePolyglot();

  const handleSubmit = async () => {
    setMessage('');
    setIsLoading(true);
    if (!user) {
      setIsLoading(false);
      return;
    }

    if (
      (phoneNumber.length > 0 && phoneNumber.length < 10) ||
      phoneNumber.length > 11 ||
      isNaN(Number(phoneNumber))
    ) {
      setPhoneErrorMessage('Please enter a valid phone number');
      setIsLoading(false);
      return;
    }
    setPhoneErrorMessage('');
    if (
      (mobileNumber.length > 0 && mobileNumber.length < 10) ||
      mobileNumber.length > 11 ||
      isNaN(Number(mobileNumber))
    ) {
      setIsLoading(false);
      setMobileErrorMessage('Please enter a valid phone number');
      return;
    }
    setMobileErrorMessage('');
    try {
      await api.updateUserContactDetails({
        ...user,
        phoneNumber,
        mobileNumber,
      });
      setUser({
        ...user,
        phoneNumber,
        mobileNumber,
      });
      setIsLoading(false);
      setMessage('Details updated successfully.');
      setMessageIcon(successIcon);
    } catch (error) {
      setIsLoading(false);
      setMessage(
        'An unexpected error occured. If this problem persists please get in touch.'
      );
      setMessageIcon(warningIcon);
      console.log(error);
    }
  };

  const onCodeSubmitted = async (
    code: string,
    cb: (success: boolean) => void
  ) => {
    try {
      await api.updateTFA(hasTFA, code);
      if (user) {
        setUser({
          ...user,
          hasTFA: hasTFA,
        });
      }
      setIsSecondFactorAuthenticationModalOpen(false);
      cb(true);
      setMessage(
        `Two Step Verification is now ${
          hasTFA ? 'enabled' : 'disabled'
        } on your account.`
      );
      setMessageIcon(successIcon);
    } catch (error) {
      cb(false);
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    const hasValuesBeenUpdated =
      phoneNumber !== user.phoneNumber || mobileNumber !== user.mobileNumber;
    if (!isSaveShown) {
      hasValuesBeenUpdated && setIsSaveShown(true);
      return;
    }
    !hasValuesBeenUpdated && setIsSaveShown(false);
  }, [mobileNumber, phoneNumber, user]);

  return (
    <Fragment>
      <div className='flex flex-col lg:flex-row'>
        <div className='lg:w-2/3 lg:mr-4'>
          <div className='flex flex-col  rounded-lg p-6 bg-white mb-4'>
            <div className='h2'>Contact Details</div>
            {user && (
              <Fragment>
                <div className='flex flex-col lg:flex-row lg:flex-wrap'>
                  <div className='my-4 lg:w-1/2 lg:pr-2'>
                    <TextInput
                      inputClassName='bg-grey'
                      label='Home Phone Number'
                      value={phoneNumber}
                      onChange={(e) => setHomePhoneNumber(e.target.value)}
                      errorMessage={phoneErrorMessage}
                    />
                  </div>
                  <div className='my-4 lg:w-1/2 lg:pl-2'>
                    <TextInput
                      inputClassName='bg-grey'
                      label='Mobile Phone Number'
                      value={!user.hasTFA ? mobileNumber : ''}
                      placeholder={user.hasTFA ? mobileNumber : ''}
                      onChange={(e) => setMobilePhoneNumber(e.target.value)}
                      errorMessage={mobileErrorMessage}
                      disabled={user.hasTFA}
                    />
                  </div>
                  <div className='my-4 lg:w-1/2 lg:pr-2'>
                    <TextInput
                      inputClassName='bg-grey'
                      label='Email'
                      value={''}
                      placeholder={user.email}
                      disabled={true}
                    />
                  </div>
                </div>

                {!mobileNumber?.length ? (
                  <div className='my-4 lg:w-1/2 lg:pr-2'>
                    You must provide a Mobile Phone Number before activating Two
                    Step Verification.
                  </div>
                ) : mobileNumber == user.mobileNumber ? (
                  <div className='my-4 lg:pr-2'>
                    <CheckboxInput
                      onClick={() => {
                        setHasTFA(!hasTFA);
                        setIsSecondFactorAuthenticationModalOpen(true);
                      }}
                      label='Enable Two Step Verification'
                      value={hasTFA}
                    />
                    <p
                      className={`mb-4 textBlock`}
                      dangerouslySetInnerHTML={{
                        __html:
                          polyglot?.t('pages.tfa_prompt.whats_this') || '',
                      }}
                    />
                  </div>
                ) : (
                  <div className='my-4 lg:w-1/2 lg:pr-2'>
                    You must save your updated mobile number before activating
                    Two Step Verification.
                  </div>
                )}
                {message && (
                  <div className='flex flex-row pt-2'>
                    <img
                      src={messageIcon}
                      alt=''
                      className='mr-4 h-6 self-center'
                    />
                    <div className='inputErrorMessage'>{message}</div>
                  </div>
                )}
                {isSaveShown && (
                  <div>
                    <Button
                      onClick={() => handleSubmit()}
                      className='btn-primary'
                    >
                      {isLoading ? (
                        <div className='pt-1.5 lg:pt-1'>
                          <PulseLoader color='#D1D1D1' size='8px' />
                        </div>
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
        <div className='lg:w-1/3 lg:ml-4 mt-0 mb-4 lg:mt-0 lg:mb-0'>
          {polyglot?.t(
            'pages.update_profile.update_contact_details_card_1_title'
          ) && (
            <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
              <div className='h2 text-white'>
                {polyglot?.t(
                  'pages.update_profile.update_contact_details_card_1_title'
                )}
              </div>
              <div className='py-4 text-white'>
                {polyglot?.t(
                  'pages.update_profile.update_contact_details_card_1_introduction'
                )}
              </div>
              <a
                href={`tel:${polyglot?.t('general.support_telephone_number')}`}
                target='_blank'
                rel='noreferrer'
              >
                <IconWithText
                  acf_fc_layout='icon_with_text'
                  icon='phone'
                  text={`Call: ${polyglot?.t(
                    'general.support_telephone_number'
                  )}`}
                  color='white'
                />
              </a>
            </div>
          )}
        </div>
      </div>

      <SecondFactorAuthenticationModal
        isOpen={isSecondFactorAuthenticationModalOpen}
        setIsOpen={setIsSecondFactorAuthenticationModalOpen}
        user={user}
        onCodeSubmitted={onCodeSubmitted}
        onCancel={() => setHasTFA(user?.hasTFA || false)}
        setTfaUseEmail={setTfaUseEmail}
        tfaUseEmail={tfaUseEmail}
        onSelectedTfaMethod={(useEmail) => {
          api.updateTFA(hasTFA, undefined, useEmail);
        }}
      />
    </Fragment>
  );
};

export default ContactDetails;
