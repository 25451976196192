import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

export interface RentStatement {
  id: number;
  description: string;
  paymentMethod: string;
  amount: number;
  balanceAtTransaction: number;
  generatedDate: Date;
  isTransation: boolean;
  isPayment: boolean;
  isCharge: boolean;
  sequence: number;
}

export interface Response {
  accountBalance: number;
  transactions: RentStatement[];
}

interface Props {
  accountId: string;
  to?: string;
  from?: string;
  postingCode?: string;
}

function getRentStatement(data: Props): Promise<AxiosResponse<Response>> {
  return axios.get(
    data.postingCode
      ? endpoints.user.getSubAccountRentStatement(
          data.accountId,
          data.postingCode,
          data.from,
          data.to
        )
      : endpoints.user.getRentStatement(data.accountId, data.from, data.to)
  );
}

export default getRentStatement;
