import React from 'react';
import Button from '../../button';

interface Props {
  onCloseCookie: () => void;
}
const CookieBanner: React.FC<Props> = ({ onCloseCookie }) => {
  return (
    <div className='fixed bottom-0 z-50 bg-white w-full px-10 cookieBoxShadow'>
      <div className='flex lg:justify-between lg:items-center flex-col lg:flex-row'>
        <div className=' py-4 pr-4'>
          We use cookies to offer you a better experience and by continuing to
          use this site you are agreeing to the use of cookies
        </div>
        <Button onClick={() => onCloseCookie()} className='btn-primary'>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default CookieBanner;
