import React, { Dispatch, SetStateAction } from 'react';
import Modal from 'react-responsive-modal';

interface Props {
  isOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  src: string;
}

const ViewVideoModal: React.FC<Props> = ({
  isOpen,
  setIsModalOpen,
  src,
  title,
}) => {
  return (
    <Modal
      classNames={{ modal: 'modal-p-0 w-3/4 md:w-1/2 transparent' }}
      open={isOpen}
      onClose={() => setIsModalOpen(false)}
      center={true}
    >
      <div className='pb-16/9 relative'>
        <iframe
          title={title}
          width='100%'
          height='100%'
          src={src}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen={true}
          className='absolute'
        ></iframe>
      </div>
    </Modal>
  );
};

export default ViewVideoModal;
