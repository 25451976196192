import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import Button from '../../../button';
import generateHallnetLink from '../../../helpers/generateHallnetLink';
import { useAuth } from '../../../state/AuthProvider';
import { Button as Props } from '../../../types/FlexibleComponents';
import ViewVideoModal from '../../modals/ViewVideoModal';
import { ReactComponent as OffsiteLinkBlue } from '../../../assets/images/offsiteLinkBlue.svg';
import { ReactComponent as OffsiteLinkWhite } from '../../../assets/images/offsiteLinkWhite.svg';
import settings from '../../../config/settings';

const FlexibleButton: React.FC<Props> = ({
  label,
  color,
  link_type,
  embedded_content,
  external_link,
  file,
  internal_link,
}) => {
  const [isEmbeddedContentOpen, setIsEmbeddedContentOpen] = useState(false);
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  const handleHallnetLink = async () => {
    setIsLoading(true);
    const url = await generateHallnetLink();
    setIsLoading(false);
    window.open(url, '_blank');
    return;
  };

  const renderButton = () => {
    switch (link_type) {
      case 'download':
        return (
          file && (
            <a
              target='_blank'
              href={file}
              className={`${
                color === 'white' ? 'btn-secondary' : 'btn-primary'
              } h-12 font-semibold inline-block  py-3 ${
                color ? 'border-white text-white' : ''
              }`}
            >
              {label}
            </a>
          )
        );
      case 'embedded_content':
        return (
          embedded_content && (
            <Fragment>
              <ViewVideoModal
                isOpen={isEmbeddedContentOpen}
                setIsModalOpen={setIsEmbeddedContentOpen}
                src={embedded_content}
                title={label}
              />
              <Button
                className={`${
                  color === 'white' ? 'btn-secondary' : 'btn-primary'
                } ${color ? 'border-white text-white' : ''}`}
                onClick={() => setIsEmbeddedContentOpen(true)}
              >
                {label}
              </Button>
            </Fragment>
          )
        );

      case 'external_link':
        return (
          external_link && (
            <a
              target='_blank'
              href={external_link}
              className={`${
                color === 'white' ? 'btn-secondary' : 'btn-primary'
              } h-12  font-semibold inline-block  py-3 ${
                color ? 'border-white text-white' : ''
              } `}
            >
              {isLoading ? (
                <div className='pt-1.5 lg:pt-1'>
                  <PulseLoader color='#D1D1D1' size='8px' />
                </div>
              ) : (
                <div className='flex'>
                  {label}
                  {color === 'white' ? (
                    <OffsiteLinkBlue className='ml-4 h-15 w-15' />
                  ) : (
                    <OffsiteLinkWhite className='ml-4 h-15 w-15' />
                  )}
                </div>
              )}
            </a>
          )
        );
      case 'internal_link':
        return (
          <Button
            onClick={() => internal_link && push(internal_link.url)}
            className={`${
              color === 'white' ? 'btn-secondary' : 'btn-primary'
            } ${color ? 'border-white text-white' : ''}`}
          >
            {label}
          </Button>
        );
      case 'fortem':
        if (user?.isSharedOwner) {
          return null;
        }

        return (
          <Button
            onClick={() => handleHallnetLink()}
            className={`${
              color === 'white' ? 'btn-secondary' : 'btn-primary'
            } ${color ? 'border-white text-white' : ''}`}
          >
            {isLoading ? (
              <div className='pt-1.5 lg:pt-1'>
                <PulseLoader color='#D1D1D1' size='8px' />
              </div>
            ) : (
              <div className='flex'>
                {label}
                {color === 'white' ? (
                  <OffsiteLinkBlue className='ml-4 h-15 w-15' />
                ) : (
                  <OffsiteLinkWhite className='ml-4 h-15 w-15' />
                )}
              </div>
            )}
          </Button>
        );
      case 'allpay':
        return (
          external_link && (
            <a
              target='_blank'
              href={settings.allPayUrl}
              className={`${
                color === 'white' ? 'btn-secondary' : 'btn-primary'
              } h-12  font-semibold inline-block  py-3 ${
                color ? 'border-white text-white' : ''
              } `}
            >
              {isLoading ? (
                <div className='pt-1.5 lg:pt-1'>
                  <PulseLoader color='#D1D1D1' size='8px' />
                </div>
              ) : (
                <div className='flex'>
                  {label}
                  {color === 'white' ? (
                    <OffsiteLinkBlue className='ml-4 h-15 w-15' />
                  ) : (
                    <OffsiteLinkWhite className='ml-4 h-15 w-15' />
                  )}
                </div>
              )}
            </a>
          )
        );
    }
  };
  return <Fragment>{renderButton()}</Fragment>;
};

export default FlexibleButton;
