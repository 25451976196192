import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { PulseLoader } from 'react-spinners';
import * as yup from 'yup';
import api from '../../api';
import Button from '../../button';
import FlexibleTextInput from '../../components/flexible/FlexibleForm/components/FlexibleTextInput';
import CheckboxInput from '../../components/input/CheckboxInput';
import LoginLayout from '../../layouts/LoginLayout';
import { useAuth } from '../../state/AuthProvider';
import { usePolyglot } from '../../state/PolyglotProvider';

const VerifyDetailsPage: React.FC = () => {
  const { user, setUser } = useAuth();
  const { polyglot } = usePolyglot();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { push } = useHistory();
  const validationSchema = yup.object().shape(
    {
      homePhone: yup.string().when('mobilePhone', {
        is: (mobilePhone: string) => !mobilePhone || mobilePhone.length === 0,
        then: yup.string().required('Please enter a phone or mobile number.'),
        otherwise: yup.string(),
      }),
      mobilePhone: yup.string().when('homePhone', {
        is: (homePhone: string) => !homePhone || homePhone.length === 0,
        then: yup.string().required('Please enter a phone or mobile number.'),
        otherwise: yup.string(),
      }),
    },
    [['homePhone', 'mobilePhone']]
  );
  if (!user) {
    return <Redirect to='/login' />;
  }

  const handleSubmit = async (e: any) => {
    setIsSubmitting(true);

    try {
      await api.verifyUserDetails({
        email: e.email,
        nationalInsurance: e.nationalInsurance,
        phoneNumber: e.homePhone,
        mobileNumber: e.mobilePhone,
        ucValue: e.UCValue,
      });
      setUser({
        ...user,
        email: e.email,
        nationalInsurance: e.nationalInsurance,
        phoneNumber: e.homePhone,
        mobileNumber: e.mobilePhone,
        isCreditClaimant: e.UCValue,
      });
      setIsSubmitting(false);
      push('/onboarding');
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <LoginLayout>
      <h1 className='h1  my-4'>{polyglot?.t('pages.register_verify.title')}</h1>
      <p className='my-4 '>
        {polyglot?.t('pages.register_verify.introduction')}
      </p>

      <Formik
        initialValues={{
          homePhone: user?.phoneNumber,
          mobilePhone: user?.mobileNumber,
          email: user?.email,
          UCValue: user?.isCreditClaimant,
          nationalInsurance: user?.nationalInsurance,
        }}
        validationSchema={validationSchema}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className='mb-4'>
              <Field
                name='homePhone'
                label='Home Phone'
                component={FlexibleTextInput}
                inputClassName='bg-grey'
                tooltip={polyglot?.t(
                  'pages.register_verify.tooltip_home_phone'
                )}
              />
            </div>
            <div className='mb-4'>
              <Field
                name='mobilePhone'
                label='Mobile Phone'
                component={FlexibleTextInput}
                inputClassName='bg-grey'
                tooltip={polyglot?.t(
                  'pages.register_verify.tooltip_mobile_phone'
                )}
              />
            </div>
            <div className='mb-4'>
              <Field
                name='email'
                label='Email'
                component={FlexibleTextInput}
                inputClassName='bg-grey'
                tooltip={polyglot?.t(
                  'pages.register_verify.tooltip_email_address'
                )}
              />
            </div>

            <div className='mb-4'>
              <Field
                name='nationalInsurance'
                label='NI Number'
                component={FlexibleTextInput}
                inputClassName='bg-grey'
                tooltip={polyglot?.t('pages.register_verify.tooltip_ni_number')}
              />
            </div>

            <div className='mb-4'>
              <CheckboxInput
                label='Universal Credit Claimant'
                onClick={() => setFieldValue('UCValue', !values.UCValue)}
                value={values.UCValue}
              />
              {values.UCValue && (
                <p
                  className={`mb-4 textBlock`}
                  dangerouslySetInnerHTML={{
                    __html:
                      polyglot?.t(
                        'pages.update_profile.universal_credit_description'
                      ) || '',
                  }}
                />
              )}
            </div>
            <Button
              className='btn-primary px-4 min-w-32 md:mr-2'
              disabled={isSubmitting}
              type='submit'
            >
              {isSubmitting ? (
                <div className='pt-1.5 lg:pt-1'>
                  <PulseLoader color='#D1D1D1' size='8px' />
                </div>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </LoginLayout>
  );
};

export default VerifyDetailsPage;
