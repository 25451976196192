import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { ReactComponent as FileUploadIcon } from '../../assets/images/fileUploadIcon.svg';

interface Props {
  errorMessage?: string;
  inputRef?: any;
  label: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: any;
  required?: boolean;
  className?: string;
  inputClassName?: string;
  tooltip?: string;
  onFileRemove?: (fieldName: string) => void;
  maxFiles?: number;
  id: string;
  fieldNames: string[];
  numberOfUploadedFiles: number;
}

const MultiFileInput: React.FC<Props> = ({
  inputClassName,
  id,
  onFileRemove,
  values,
  onChange,
  fieldNames,
  maxFiles,
  numberOfUploadedFiles,
}) => (
  <>
    <div className=''>
      <div className='w-full'>
        <div
          onClick={(_) => {
            id &&
              (!maxFiles || numberOfUploadedFiles < maxFiles) &&
              document.getElementById(id)?.click();
          }}
          className={`input rounded-lg ${inputClassName} items-center flex pl-4 cursor-pointer`}
        >
          {numberOfUploadedFiles === maxFiles
            ? 'Max number of files reached'
            : 'Click to upload'}
        </div>
        <FileUploadIcon
          onClick={(e) => {
            id &&
              (!maxFiles || numberOfUploadedFiles < maxFiles) &&
              document.getElementById(id)?.click();
          }}
          className='viewPasswordIcon -mt-9'
        />
      </div>

      <input
        className='hidden'
        id={id}
        type='file'
        accept='image/*'
        onChange={(e) => onChange && onChange(e)}
      />
    </div>
    {values &&
      values.map((value: any, i: number) => {
        var fullPath = (value?.path as string) || '';
        var filename = '';

        if (fullPath) {
          var startIndex =
            fullPath.indexOf('\\') >= 0
              ? fullPath.lastIndexOf('\\')
              : fullPath.lastIndexOf('/');
          filename = fullPath.substring(startIndex);
          if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
            filename = filename.substring(1);
          }
        }
        return (
          value && (
            <div
              onClick={() => onFileRemove && onFileRemove(fieldNames[i])}
              className='border-blue border-b my-4'
            >
              <span className='mr-5 font-medium'>{filename}</span>
              <span>
                {value.file.size && (value.file.size / 1024).toFixed(0)} kb
              </span>
              <CloseIcon className='absoulte float-right cursor-pointer' />
            </div>
          )
        );
      })}
  </>
);

export default MultiFileInput;
