import classNames from 'classnames';
import React from 'react';
import IconWithText from '../../../components/flexible/IconWithText';
import Pagination from '../../../components/pagination';
import TableHeader from '../../../components/table/components/TableHeader';
import TableRow from '../../../components/table/components/TableRow';
import Table from '../../../components/table/Table';
import usePagination from '../../../hooks/usePagination';
import { usePolyglot } from '../../../state/PolyglotProvider';
import User from '../../../types/User';

interface HouseholdMembersProps {
  contacts: User[];
}

const HouseholdMembers: React.FC<HouseholdMembersProps> = ({ contacts }) => {
  const headers = ['First Name', 'Middle Name(s)', 'Last Name', 'DOB'];
  const body: (string | null)[][] = contacts.map((contact) => [
    contact.forename,
    contact.middlename,
    contact.surname,
    contact.dateOfBirth.slice(0, 10).split('-').reverse().join('/'),
  ]);

  const { setCurrentPage, paginate, currentPage, numberOfPages } =
    usePagination(body, 10);

  const { polyglot } = usePolyglot();

  const showBottomRow = currentPage < numberOfPages;

  return (
    <div className='flex flex-col lg:flex-row'>
      <div
        className={classNames(
          'flex flex-col lg:w-2/3 lg:mr-4 rounded-lg py-6 px-4 pb-3 sm:p-6  bg-white  mb-4'
        )}
      >
        <div className={classNames('flex justify-between mb-2')}>
          <div className='h2'>Household Members</div>
          <span className='opacity-50 font-semibold'>
            {' '}
            {contacts.length} Total
          </span>
        </div>

        <Table tableWrapperClassName='-mx-4' tableClassName='lg:table-fixed'>
          {headers && (
            <TableHeader cells={headers} className='hidden sm:table-row' />
          )}

          {body && body.length > 0 ? (
            <tbody>
              {paginate(body).map((row, rowIndex) => {
                return (
                  <TableRow
                    key={rowIndex}
                    className={classNames({
                      'border-t-0': rowIndex === 0,
                      'bg-blue-100': rowIndex % 2 === 0,
                      'border-b-0':
                        rowIndex === body.length - 1 && !showBottomRow,
                      'table-row': true,
                    })}
                  >
                    {row.map((cell, i) => {
                      const getCellColor = (cell: string | null) => {
                        if (!cell || cell[0] !== '£' || i !== 4) {
                          return 0;
                        }
                        const value = cell.split('£')[1];

                        return isNaN(Number(value)) ? 0 : Number(value);
                      };

                      return (
                        <td
                          key={i}
                          className={classNames(
                            'px-4 py-2 text-blue text-sm min-w-28 table-row sm:table-cell',
                            {
                              'text-red': getCellColor(cell) > 0,
                              'hidden sm:table-cell':
                                typeof cell === 'string' && !cell.trim(),
                            }
                          )}
                        >
                          <div
                            className={classNames('px-4 py-1 sm:px-0 sm:py-0', {
                              'pb-3 sm:pb-0': i === row.length - 1,
                              'pt-3 sm:pt-0': i === 0,
                            })}
                          >
                            <p className='sm:hidden opacity-50 font-semibold'>
                              {headers[i]}
                            </p>
                            {cell}
                          </div>
                        </td>
                      );
                    })}
                  </TableRow>
                );
              })}
              {showBottomRow && (
                <tr>
                  <td className='py-3'></td>
                </tr>
              )}
            </tbody>
          ) : (
            <TableRow>
              <td
                colSpan={100}
                className={classNames('px-4 py-3 text-blue text-sm min-w-28')}
              >
                {'No data available. Please widen your search term.'}
              </td>
            </TableRow>
          )}
        </Table>

        {numberOfPages > 1 && (
          <div className='mt-4'>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              numberOfPages={numberOfPages}
            />
          </div>
        )}
      </div>
      <div className='lg:w-1/3 lg:ml-4 mt-0 mb-4 lg:mt-0 lg:mb-0'>
        {polyglot?.t(
          'pages.update_profile.update_contact_details_card_1_title'
        ) && (
          <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
            <div className='h2 text-white'>
              {polyglot?.t(
                'pages.update_profile.update_contact_details_card_1_title'
              )}
            </div>
            <div className='py-4 text-white'>
              {polyglot?.t(
                'pages.update_profile.update_contact_details_card_1_introduction'
              )}
            </div>
            <a
              href={`tel:${polyglot?.t('general.support_telephone_number')}`}
              target='_blank'
            >
              <IconWithText
                acf_fc_layout='icon_with_text'
                icon='phone'
                text={`Call: ${polyglot?.t(
                  'general.support_telephone_number'
                )}`}
                color='white'
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default HouseholdMembers;
