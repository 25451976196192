import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import arrow from '../assets/images/arrow.svg';

interface Props {
  icon?: string;
}

const Button: React.FC<
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    Props
> = ({ children, icon, id, ...props }) => (
  <button {...props}>
    {icon === 'arrow' && (
      <img src={arrow} alt='>' className='absolute top-4 left-4 max-h-3' />
    )}
    <span id={id} className='inline-block relative'>
      {children}
    </span>
  </button>
);

export default Button;
