import getHallnetUrl from '../api/getHallnetUrl';
import getRentAccounts from '../api/getRentAccounts';

const generateHallnetLink = async () => {
  const balanceRequest = await getRentAccounts();
  const url = await getHallnetUrl(balanceRequest.data[0].assetId);
  return url.data;
};

export default generateHallnetLink;
