import Polyglot from 'node-polyglot';
import React, { createContext, useContext, useEffect, useState } from 'react';
import LoadingPage from '../pages/LoadingPage';
import { useSettings } from './SettingsProvider';
// TODO: Replace with data from Dan.

interface IPolyglotContext {
  polyglot?: Polyglot;
}

const PolyglotContext = createContext({} as IPolyglotContext);
const usePolyglot = () => useContext(PolyglotContext);

const PolyglotProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { flexibleContent } = useSettings();
  const [polyglot, setPolyglot] = useState<Polyglot>();

  useEffect(() => {
    if (!polyglot) {
      setPolyglot(new Polyglot({ phrases: flexibleContent }));
      setIsLoading(false);
    }
  }, []);

  return (
    <PolyglotContext.Provider value={{ polyglot }}>
      {isLoading ? <LoadingPage /> : children}
    </PolyglotContext.Provider>
  );
};

export { usePolyglot };
export default PolyglotProvider;
