import React from 'react';
import Button from '../../../../button';
import { FlexibleColumnsContent as FlexibleColumnsContentType } from '../../../../types/FlexibleComponents';
import FlexibleCard from '../../FlexibleCard/FlexibleCard';
import IconWithText from '../../IconWithText/IconWithText';
import TextBlock from '../../TextBlock';

interface Props {
  content: FlexibleColumnsContentType;
  color?: 'white';
}

const FlexibleColumnsContent: React.FC<Props> = ({ content, color }) => {
  // If our content only contains a FlexibleCard, we want to structure our
  // HTML differently so that 'flex-grow' works correctly.
  if (content.length === 1 && content[0].acf_fc_layout === 'flexible_card') {
    const component = content[0];
    return <FlexibleCard {...component} />;
  }

  return (
    <div>
      {content.length > 0 && (
        <div className='-mb-4'>
          {content.map((component, i) => {
            switch (component.acf_fc_layout) {
              case 'button':
                return (
                  <div key={i} className='mb-4'>
                    <Button {...component} color={color} />
                  </div>
                );
              case 'flexible_card':
                return (
                  <div key={i} className='mb-4'>
                    <FlexibleCard {...component} />
                  </div>
                );
              case 'icon_with_text':
                return (
                  <div key={i} className='mb-4'>
                    <IconWithText {...component} color={color} />
                  </div>
                );
              case 'text_block':
                return (
                  <div key={i} className='mb-4'>
                    <TextBlock {...component} color={color} />
                  </div>
                );
              default:
                console.log('Undefined flexible component!');
                return null;
            }
          })}
        </div>
      )}
    </div>
  );
};

export default FlexibleColumnsContent;
