import {
  ConditionalLogic,
  ConditionalLogicRule,
} from '../../../types/FlexibleForm';
import handleRule from './handleRule';

function all(rules: ConditionalLogicRule[], values: any) {
  for (const rule of rules) {
    if (!handleRule(rule, values[rule.fieldName])) {
      return false;
    }
  }

  return true;
}

function any(rules: ConditionalLogicRule[], values: any) {
  for (const rule of rules) {
    if (handleRule(rule, values[rule.fieldName])) {
      return true;
    }
  }

  return false;
}

export default function handleConditionalLogicForm(
  conditionalLogic: ConditionalLogic | null,
  values: any
) {
  if (!conditionalLogic) {
    return true;
  }

  const { action, logic, rules } = conditionalLogic;

  if (logic === 'all') {
    if (all(rules, values)) {
      return action === 'show';
    } else {
      return action === 'hide';
    }
  } else {
    if (any(rules, values)) {
      return action === 'show';
    } else {
      return action === 'hide';
    }
  }
}
