import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';
import {
  FlexibleContentMain,
  FlexibleContentSidebar,
} from '../types/FlexibleComponents';

type ResponseData = {
  id: number;
  title: string;
  content_main: FlexibleContentMain;
  content_sidebar: FlexibleContentSidebar;
  date_published: string;
  date_modified: string;
};
function getPageContent(pageId: string): Promise<AxiosResponse<ResponseData>> {
  return axios.get(endpoints.content.getPageContent(pageId));
}

export default getPageContent;
