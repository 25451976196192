import React, { Fragment, useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import api from '../../api';
import { RentAccount } from '../../api/getRentAccounts';
import FlexibleForm from '../../components/flexible/FlexibleForm';
import IconWithText from '../../components/flexible/IconWithText';
import MainLayout from '../../layouts/MainLayout';
import { useAuth } from '../../state/AuthProvider';
import { usePolyglot } from '../../state/PolyglotProvider';

const EndTenancyPage: React.FC = () => {
  const { polyglot } = usePolyglot();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<RentAccount>();
  const { user } = useAuth();
  useEffect(() => {
    let isComponentMounted = true;
    setIsLoading(true);
    (async function () {
      try {
        setIsLoading(true);
        const rentAccountResponse = await api.getRentAccounts();

        if (rentAccountResponse.data.length > 0) {
          if (isComponentMounted) {
            setSelectedAccount(rentAccountResponse.data.find((a) => a.isMain));
          }
        }
        if (isComponentMounted) {
          setIsLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  return (
    <MainLayout>
      <h1 className='h1 mb-4'>
        {polyglot?.t('pages.tenancy_end_tenancy.title')}
      </h1>
      <h2 className='h2 mb-2'>
        {polyglot?.t('pages.tenancy_end_tenancy.subtitle')}
      </h2>
      <p className='p mb-4'>
        {polyglot?.t('pages.tenancy_end_tenancy.introduction')}
      </p>
      <div className='flex lg:flex-row flex-col mb-4'>
        <div className='lg:w-2/3 lg:mr-4 mt-4 lg:mt-0'>
          <div className='lg:flex justify-between  mb-4 bg-white rounded-lg p-6'>
            <div className='flex flex-col md:w-full md:justify-between md:flex-row mb-4 md:mb-0'>
              {isLoading ? (
                <div className=' flex w-full justify-center'>
                  <PulseLoader color='#D1D1D1' size='8px' />
                </div>
              ) : (
                <Fragment>
                  <h2 className='h2 mr-4 lg:text-xl mb-2 lg:mb-0'>
                    Tenancy Reference: <br className='hidden md:block' />
                    {selectedAccount?.accountId}
                  </h2>
                  <div className='h2 lg:text-xl mr-4 mb-2 lg:mb-0'>
                    User: <br className='hidden md:block' /> {user?.forename}{' '}
                    {user?.surname}
                  </div>
                  <div className='h2 lg:text-xl'>
                    Address: <br className='hidden md:block' />{' '}
                    {selectedAccount?.address}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <FlexibleForm
            formId='22'
            title={polyglot?.t('pages.tenancy_end_tenancy.form_title') || ''}
            description={
              polyglot?.t('pages.tenancy_end_tenancy.form_introduction') || ''
            }
            isFullWidth={true}
          />
        </div>
        <div className='lg:w-1/3 lg:ml-4 mt-4 lg:mt-0'>
          {polyglot?.t('pages.tenancy_end_tenancy.sidebar_card_1.title') && (
            <div className='bg-blue flex flex-col  rounded-lg p-4 mb-4'>
              <div className='h2 text-white'>
                {polyglot?.t('pages.tenancy_end_tenancy.sidebar_card_1.title')}
              </div>
              <div className='py-4 text-white'>
                {polyglot?.t(
                  'pages.tenancy_end_tenancy.sidebar_card_1.introduction'
                )}
              </div>
              <a
                href={`tel:${polyglot?.t('general.support_telephone_number')}`}
                target='_blank'
                rel='noreferrer'
              >
                <IconWithText
                  acf_fc_layout='icon_with_text'
                  icon='phone'
                  text={`Call: ${polyglot?.t(
                    'general.support_telephone_number'
                  )}`}
                  color='white'
                />
              </a>
            </div>
          )}
          {polyglot?.t('pages.tenancy_end_tenancy.sidebar_card_2.title') && (
            <div className='bg-white flex flex-col  rounded-lg p-4'>
              <div className='h2'>
                {polyglot?.t('pages.tenancy_end_tenancy.sidebar_card_2.title')}
              </div>
              <div className='py-4 text-blue'>
                {polyglot?.t(
                  'pages.tenancy_end_tenancy.sidebar_card_2.introduction'
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default EndTenancyPage;
