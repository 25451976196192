import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';

export interface Vulnerability {
  vulnerabilityId: number;
  description: string;
  value?: boolean;
  codeId: number;
}

function getAllVulnerabilities(): Promise<AxiosResponse<Vulnerability[]>> {
  return axios.get(endpoints.user.getAllVulnerabilities);
}

export default getAllVulnerabilities;
