import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import api from '../api';
import settings from '../config/settings';
import { axios } from '../config/api';
import { useIdleTimer } from 'react-idle-timer';
import User from '../types/User';

interface AuthProps {
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  logout: () => void;
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
  setUser: (user: User) => void;
}

const AuthContext = React.createContext({} as AuthProps);

const useAuth = () => useContext(AuthContext);

const AuthProvider: FunctionComponent = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>();

  async function logout() {
    try {
      await api.logout();
      sessionStorage.removeItem(settings.bearerTokenKey);
      sessionStorage.removeItem(settings.refreshTokenKey);
      sessionStorage.removeItem(settings.userKey);
    } catch (error) {}
    setIsAuthenticated(false);
  }

  const handleSetUser = (e: User) => {
    setUser(e);
    sessionStorage.setItem(settings.userKey, JSON.stringify(e));
  };

  const handleOnIdle = () => {
    logout();
  };

  useIdleTimer({ timeout: 1000 * 60 * 5, onIdle: handleOnIdle });

  async function checkAuthenticationStatus() {
    setIsLoading(true);
    const bearerToken = sessionStorage.getItem(settings.bearerTokenKey);
    const refreshToken = sessionStorage.getItem(settings.refreshTokenKey);
    const u = sessionStorage.getItem(settings.userKey);

    if (!bearerToken || !refreshToken || !u) {
      setIsAuthenticated(false);
      setIsLoading(false);
      return;
    }

    // For now, we assume that these tokens haven't expired. If they have
    // expired, the user will be returned to /login when a data fetch is
    // attempted (e.g. on the Home page).

    // We could improve on this by detecting if our tokens have expired here
    // and calling setIsAuthenticated accordingly.

    setUser(JSON.parse(u));

    setIsAuthenticated(true);
    setIsLoading(false);
  }

  function createResponseInterceptor() {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response.status !== 401) {
          // If a different error to 401 Unauthorized is returned, continue.
          return Promise.reject(error);
        }

        // Clear the interceptor so we don't fall into an infinite loop if our
        // refresh token request is also rejected.
        axios.interceptors.response.eject(interceptor);

        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            // Returns original error instead of refresh error to component
            if (
              !sessionStorage.getItem(settings.bearerTokenKey) ||
              !sessionStorage.getItem(settings.refreshTokenKey)
            ) {
              logout();
              return Promise.reject(error);
            }
            const response = await api.refresh();
            const { token, refreshToken } = response.data;
            sessionStorage.setItem(settings.bearerTokenKey, token);
            sessionStorage.setItem(settings.refreshTokenKey, refreshToken);
            return axios(originalRequest);
          } catch (e) {
            logout();
            return Promise.reject(error);
          } finally {
            // Once finished with the refresh token flow, re-create the response
            // interceptor to deal with future requests.
            createResponseInterceptor();
          }
        }
      }
    );
  }

  useEffect(() => {
    checkAuthenticationStatus();
    createResponseInterceptor();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        setIsAuthenticated,
        logout,
        isAuthenticated,
        isLoading,
        user,
        setUser: handleSetUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { useAuth };
export default AuthProvider;
