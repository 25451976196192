import React, { useEffect, useState } from 'react';
import TextInput from '../../components/input/TextInput';
import { useAuth } from '../../state/AuthProvider';
import PasswordInput from '../../components/input/PasswordInput';
import { PulseLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom';
import settings from '../../config/settings';
import api from '../../api';
import LoginActions from '../../types/LoginActions';
import ChangePasswordModal from '../../components/modals/ChangePasswordModal';
import TFAPromptModal from '../../components/modals/TFAPromptModal';
import SecondFactorAuthenticationModal from '../../components/modals/SecondFactorAuthenticationModal';
import LoginLayout from '../../layouts/LoginLayout';
import warningIcon from '../../assets/images/warningIcon.svg';
import Button from '../../button';
import User from '../../types/User';
import { usePolyglot } from '../../state/PolyglotProvider';
import RedirectToRegister from '../../components/modals/RedirectToRegister';
import CheckboxInput from '../../components/input/CheckboxInput';
import LoadingPage from '../LoadingPage';
import { useSettings } from '../../state/SettingsProvider';
import { MarketingPoints } from '../RegisterPage/components/MarketingPoints';

const LoginPage: React.FC = () => {
  const { polyglot } = usePolyglot();

  const { flexibleContent } = useSettings();
  const marketingPoints = flexibleContent?.pages?.login?.marketing_points;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isTFAPromptOpen, setIsTFAPromptOpen] = useState(false);
  const [
    isSecondFactorAuthenticationModalOpen,
    setIsSecondFactorAuthenticationModalOpen,
  ] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [redirectToVerifyDetails, setRedirectToVerifyDetails] = useState(false);
  const { setIsAuthenticated, isAuthenticated, setUser } = useAuth();
  const history = useHistory();
  const [tempUser, setTempUser] = useState<User>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAutoLoginLoading, setIsAutoLoginLoading] = useState(true);
  const [tfaUseEmail, setTfaUseEmail] = useState(false);

  async function login(
    username?: string,
    password?: string,
    rememberMe?: boolean,
    tfaCode?: string,
    useEmail?: boolean
  ) {
    try {
      const response = await api.login(
        username,
        password,
        rememberMe,
        tfaCode,
        useEmail
      );

      const { token, refreshToken, action, contactDetails, tfaDismissed } =
        response.data;
      sessionStorage.setItem(settings.bearerTokenKey, token);
      sessionStorage.setItem(settings.refreshTokenKey, refreshToken);

      setTfaUseEmail(response.data.tfaUseEmail == true);

      if (
        LoginActions[action] === LoginActions.PreviousUser ||
        LoginActions[action] === LoginActions.ForcePasswordChange
      ) {
        setIsChangePasswordModalOpen(true);
        setTempUser(response.data.contactDetails);
        return;
      }

      if (LoginActions[action] === LoginActions.TFA) {
        setIsSecondFactorAuthenticationModalOpen(true);
        setTempUser(response.data.contactDetails);
        return;
      }

      if (LoginActions[action] === LoginActions.FirstLogin) {
        setRedirectToVerifyDetails(true);
      }
      setUser(contactDetails);

      if (!tfaDismissed) {
        setIsTFAPromptOpen(true);
        return;
      }

      setIsAuthenticated(true);
    } catch (error: any) {
      if (isAutoLoginLoading) {
        setIsAutoLoginLoading(false);
        return;
      }

      setIsSubmitting(false);
      if (!error.response || !error.response.data) {
        setLoginError(
          'A problem occured, please try again. If this problem persists please contact a system administrator.'
        );
      }

      if (error.response.data.action === LoginActions.LockedOut) {
        setLoginError('');
        setUsername('');
        setPassword('');
        history.push('/requestPasswordReset');
        return;
      }
      if (error.response.data.action === LoginActions.AccountDisabled) {
        setLoginError(
          'Your account has been disabled. Please contact us on 0345 305 5335.'
        );
        return;
      }
      if (error.response.data.action === LoginActions.NoAccount) {
        setUsername('');
        setPassword('');
        setIsModalOpen(true);
        return;
      }
      setLoginError(
        'The username or password you provided is incorrect. Warning, after 5 failed login attempts your account will be locked.'
      );
      return;
      // DEBUG
      // TODO: Add specific error messages based on response codes/messages.
    }
  }

  useEffect(() => {
    // On load, hit the /user/authenticate endpoint. If our HttpOnly cookie has
    // been set, it will be sent automatically with the request.
    (async function () {
      setIsAutoLoginLoading(true);
      await login();
    })();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // If we become authenticated while on the Login Page (either by checking
      // localStorage or submitting a valid login form) then return to the
      // previous page. This is necessary because isAuthenticated is only
      // checked by React Router when a user navigates.
      if (redirectToVerifyDetails) {
        setRedirectToVerifyDetails(false);
        history.push('/verifyDetails');
        return;
      }
      history.push('/');
    }
  }, [isAuthenticated]);

  async function onSubmit() {
    setIsSubmitting(true);

    if (username.trim().length === 0) {
      setLoginError('Please enter a username');
      setIsSubmitting(false);
      return;
    }
    if (password.trim().length === 0) {
      setLoginError('Please enter a password');
      setIsSubmitting(false);
      return;
    }

    await login(username, password, rememberMe);
  }

  const onCancel = () => {
    setIsSubmitting(false);
    setPassword('');
    setLoginError('You did not complete second factor authentication.');
  };

  const onTFAPromptClose = () => {
    setIsAuthenticated(true);
  };

  const pageContents = marketingPoints ? (
    <MarketingPoints items={marketingPoints} />
  ) : undefined;

  return isAutoLoginLoading ? (
    <LoadingPage />
  ) : (
    <LoginLayout rightSideContent={pageContents}>
      <h1 className='h1 xl:w-84 my-4 font-bold'>
        {polyglot?.t('pages.login.title')}
      </h1>
      <p className='my-4 lg:my-2'>{polyglot?.t('pages.login.introduction')}</p>
      <div>
        <form
          onSubmit={(e) => e.preventDefault()}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSubmit();
            }
          }}
        >
          <TextInput
            className='mb-5'
            label='Username'
            value={username}
            onChange={({ target: { value } }) => setUsername(value)}
            required={true}
          />

          <PasswordInput
            label='Password'
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
            required={true}
          />
          <div className='mt-4'>
            <CheckboxInput
              label="Keep me logged in (don't tick if you're using a shared device)"
              value={rememberMe}
              onClick={() => setRememberMe(!rememberMe)}
            />
          </div>
          {loginError && (
            <div className='flex flex-row pt-2'>
              <img src={warningIcon} alt='!' className='pr-4' />
              <div className='inputErrorMessage'>{loginError}</div>
            </div>
          )}
        </form>
        <div className='flex flex-col md:flex-row '>
          <Button
            className='btn-primary px-4 min-w-32 md:mr-2'
            disabled={isSubmitting}
            onClick={() => onSubmit()}
          >
            {isSubmitting ? (
              <div className='pt-1.5 lg:pt-1'>
                <PulseLoader color='#D1D1D1' size='8px' />
              </div>
            ) : (
              'Login'
            )}
          </Button>
          <Button
            onClick={() => history.push('/register')}
            className='btn-secondary px-5'
          >
            Register new account
          </Button>
        </div>
        <div className='flex items-center  w-full flex-wrap'>
          <Button
            className='btn-tertiary my-0 md:my-4 mr-6'
            onClick={() => history.push('/requestPasswordReset')}
          >
            Reset Password
          </Button>
        </div>
      </div>

      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        setIsOpen={setIsChangePasswordModalOpen}
        onSuccessfullyResetPassword={() => {
          if (tempUser) {
            setUser(tempUser);
          }
          setIsAuthenticated(true);
        }}
      />

      <TFAPromptModal
        isOpen={isTFAPromptOpen}
        setIsOpen={setIsTFAPromptOpen}
        onComplete={onTFAPromptClose}
      />

      <SecondFactorAuthenticationModal
        isOpen={isSecondFactorAuthenticationModalOpen}
        setIsOpen={setIsSecondFactorAuthenticationModalOpen}
        user={tempUser}
        onCodeSubmitted={(code, cb) =>
          login(username, password, rememberMe, code, tfaUseEmail).then(() =>
            cb(false)
          )
        }
        onCancel={onCancel}
        setTfaUseEmail={setTfaUseEmail}
        tfaUseEmail={tfaUseEmail}
        onSelectedTfaMethod={(useEmail) => {
          login(username, password, rememberMe, undefined, useEmail);
        }}
      />

      <RedirectToRegister isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </LoginLayout>
  );
};

export default LoginPage;
