import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';
import MenuObject from '../types/MenuObject';
import SocialLinks from '../types/SocialLinks';

interface ResponseData {
  social: SocialLinks;
  menu: MenuObject[];
  pages: any[];
}

function getSettings(): Promise<AxiosResponse<ResponseData>> {
  return axios.get(endpoints.content.getSettings);
}

export default getSettings;
