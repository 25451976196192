import React from 'react';
import { TextBlock as Props } from '../../../types/FlexibleComponents';

const TextBlock: React.FC<Props> = ({ wysiwyg, color }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: wysiwyg }}
      className={`mb-4 textBlock ${
        color === 'white' ? 'textBlock-colorWhite' : ''
      }`}
    />
  );
};

export default TextBlock;
