import React, { useState } from 'react';
import LoginLayout from '../../layouts/LoginLayout';
import RegisterForm from './components/RegisterForm';
import SuccessfulRegisterModal from '../../components/modals/SuccessfulRegisterModal';
import { usePolyglot } from '../../state/PolyglotProvider';
import { useSettings } from '../../state/SettingsProvider';
import { MarketingPoints } from './components/MarketingPoints';

const RegisterPage: React.FC = () => {
  const { polyglot } = usePolyglot();
  const { flexibleContent } = useSettings();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const marketingPoints = flexibleContent?.pages?.login?.marketing_points;

  const pageContents = marketingPoints ? (
    <MarketingPoints items={marketingPoints} />
  ) : undefined;

  return (
    <LoginLayout rightSideContent={pageContents}>
      <h1 className='h1 my-4 text-left font-bold'>
        {polyglot?.t('pages.register.title')}
      </h1>

      <RegisterForm
        onSuccessfullyRegistered={(e) => {
          setIsModalOpen(true);
        }}
      />

      <SuccessfulRegisterModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </LoginLayout>
  );
};

export default RegisterPage;
