import React, { useEffect, useState } from 'react';
import api from '../../api';
import { RentStatement } from '../../api/getRentStatement';
import DateInput from '../../components/input/DateInput';
import SelectInput, { OptionType } from '../../components/input/SelectInput';
import monthNames from '../../helpers/monthNames';
import MainLayout from '../../layouts/MainLayout';
import LoadingPage from '../LoadingPage';
import RentStatementTable from './components/RentStatementTable';

const ViewRentStatementPage: React.FC = () => {
  const [rentStatement, setRentStatement] = useState<RentStatement[]>();
  const [currentBalance, setCurrentBalance] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [sortByFilter, setSortByFilter] = useState<OptionType>();
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(endDate.getFullYear() - 1, endDate.getMonth(), endDate.getDate())
  );

  const [selectedAccount, setSelectedAccount] = useState<OptionType>();
  const [subAccounts, setSubAccounts] = useState<OptionType[]>([]);
  const [selectedSubAccount, setSelectedSubAccount] = useState<OptionType>();
  const [tenantAccounts, setTenantAccounts] = useState<OptionType[]>([]);

  useEffect(() => {
    let isComponentMounted = true;
    setIsLoading(true);
    (async function () {
      try {
        const rentAccountResponse = await api.getRentAccounts();

        if (rentAccountResponse.data.length > 0) {
          if (isComponentMounted) {
            const addresses = rentAccountResponse.data.map((a) => {
              return { label: a.address, value: a.accountId.toString() };
            });
            setTenantAccounts(addresses);
            setSelectedAccount(addresses[0]);
            const options = rentAccountResponse.data[0].subAccounts.map((e) => {
              return { label: e.name, value: e.postingCode || '' };
            });
            setSubAccounts(options);
            setSelectedSubAccount(options[0]);
            setCurrentBalance(
              rentAccountResponse.data[0].subAccounts[0].balance
            );
          }
        }
        if (isComponentMounted) {
          setIsLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  useEffect(() => {
    let isComponentMounted = true;
    setIsLoading(true);
    (async function () {
      try {
        if (!selectedAccount || !selectedSubAccount) {
          return;
        }

        const rentStatementResponse = await api.getRentStatement({
          accountId: selectedAccount.value,
          from: startDate.toISOString(),
          to: endDate.toISOString(),
          postingCode: selectedSubAccount?.value,
        });
        if (isComponentMounted) {
          setRentStatement(rentStatementResponse.data.transactions);
          setCurrentBalance(rentStatementResponse.data.accountBalance);
          setIsLoading(false);
        }
      } catch (error) {
        // TODO: Add error handling.
        setIsLoading(false);
        console.log(error);
      }
    })();

    return () => {
      isComponentMounted = false;
    };
  }, [startDate, endDate, selectedAccount, selectedSubAccount]);

  return (
    <MainLayout>
      <h1 className='h1 mb-4'>View my rent statements</h1>

      {tenantAccounts.length > 1 && (
        <div className='lg:max-w-1/2 mb-4'>
          <h3 className='h3'>Select the property to view:</h3>
          <p>
            Please select from this list the property you would like to view.
          </p>
          <SelectInput
            options={tenantAccounts}
            onChange={(e) => setSelectedAccount(e)}
            label='Address'
            inputClassName='bg-white w-full'
            optionsClassName='bg-white'
            fieldsetClassName='w-full '
            selectedOption={selectedAccount}
          />
        </div>
      )}

      <div className='lg:flex justify-between  mb-4 bg-white rounded-lg p-6'>
        <div className='flex flex-col md:flex-row mb-4 md:mb-0'>
          <div className='h2 font-normal mr-4 lg:text-xl mb-2 lg:mb-0'>
            Tenancy Reference: {selectedAccount?.value}
          </div>
          <div className='h2 lg:text-xl'>
            Current Balance: £
            {currentBalance === 0
              ? currentBalance.toFixed(2)
              : currentBalance < 0
              ? `${(currentBalance * -1).toFixed(2)} in credit`
              : `${currentBalance.toFixed(2)} in arrears`}
          </div>
        </div>
      </div>
      <div className='lg:flex justify-between mb-4 flex-wrap'>
        <div className='mb-4 lg:mb-0 flex flex-col lg:flex-row lg:mr-3 '>
          <SelectInput
            options={subAccounts}
            onChange={(e) => setSelectedSubAccount(e)}
            label='Account Type'
            inputClassName='bg-white'
            optionsClassName='bg-white'
            fieldsetClassName='min-w-64  lg:mr-3  lg:mb-0 mb-4 '
            selectedOption={selectedSubAccount}
          />
          <div className='flex'>
            <DateInput
              label='From'
              value={startDate}
              onChange={(e) => {
                setStartDate(e);
              }}
              className='flex flex-col mr-4 lg:min-w-44 lg:mb-0'
              maxDate={endDate}
            />

            <DateInput
              className='flex flex-col  lg:min-w-44 lg:mb-0'
              label='To'
              value={endDate}
              minDate={startDate}
              maxDate={new Date()}
              onChange={(e) => setEndDate(e)}
            />
          </div>
        </div>
        <SelectInput
          options={[
            {
              label: 'Date (Oldest to Newest)',
              value: 'Date asc',
            },
            {
              label: 'Date (Newest to Oldest)',
              value: 'Date desc',
            },
            {
              label: 'Charges (Lowest to Highest)',
              value: 'Charges asc',
            },
            {
              label: 'Charges (Highest to Lowest)',
              value: 'Charges desc',
            },
          ]}
          onChange={(e) => setSortByFilter(e)}
          label='Sort By'
          inputClassName='bg-white'
          optionsClassName='bg-white'
          fieldsetClassName='w-full lg:w-64'
          selectedOption={sortByFilter}
        />
      </div>

      {isLoading ? (
        <LoadingPage />
      ) : (
        rentStatement && (
          <div>
            <RentStatementTable
              acf_fc_layout='table'
              head={[
                'Date',
                'Description',
                'Payment method',
                'Charges',
                'Balance',
                'Status',
              ]}
              body={rentStatement
                .sort((a, b) => {
                  switch (sortByFilter?.value) {
                    case 'Date asc':
                      return a.sequence > b.sequence ? 1 : -1;
                    case 'Date desc':
                      return a.sequence > b.sequence ? -1 : 1;
                    case 'Charges asc':
                      return a.amount > b.amount ? 1 : -1;
                    case 'Charges desc':
                      return a.amount > b.amount ? -1 : 1;

                    default:
                      return new Date(a.generatedDate) >
                        new Date(b.generatedDate)
                        ? -1
                        : 1;
                  }
                })
                .map((statement) => {
                  return [
                    `${
                      monthNames[new Date(statement.generatedDate).getMonth()]
                    } ${new Date(statement.generatedDate).getDate()} ${new Date(
                      statement.generatedDate
                    ).getFullYear()} `,
                    statement.description,
                    statement.paymentMethod,
                    `£${statement.amount.toFixed(2)}`,
                    `£${statement.balanceAtTransaction.toFixed(2)}`,
                    'Credit',
                  ];
                })}
            />
          </div>
        )
      )}
    </MainLayout>
  );
};

export default ViewRentStatementPage;
