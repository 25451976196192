import { AxiosResponse } from 'axios';
import endpoints, { axios } from '../config/api';
import { Vulnerability } from './getAllVulnerabilities';

function updateUserVulnerabilities(
  vulnerabilities: Vulnerability[]
): Promise<AxiosResponse> {
  return axios.put(endpoints.user.updateUserVulnerabilities, vulnerabilities);
}

export default updateUserVulnerabilities;
