import React, { createContext, MutableRefObject, useContext } from 'react';

interface IMainLayout {
  refMainLayout: MutableRefObject<HTMLDivElement | null>;
}

const MainLayoutContext = createContext({} as IMainLayout);

export const useMainLayout = () => useContext(MainLayoutContext);

type Props = {
  refMainLayout: MutableRefObject<HTMLDivElement | null>;
};

const MainLayoutProvider: React.FC<Props> = ({ children, refMainLayout }) => {
  return (
    <MainLayoutContext.Provider value={{ refMainLayout }}>
      {children}
    </MainLayoutContext.Provider>
  );
};

export default MainLayoutProvider;
